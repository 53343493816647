import { AddCircle, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PageLayout from '../../components/pageLayout/pageLayout';
import AccordionComponent from "../../components/accordionComponent/accordionComponent";
import Template from "./template";
import { v4 as uuidv4 } from 'uuid'

function Templates() {
    const locationId = localStorage.getItem('locationId');
    const [templates, setTemplates] = useState([]);
    const [displayTemplateForm, setDisplayTemplateForm] = useState(false);

    const [newTemplate, setNewTemplate] = useState({
        milestones: []
    })

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getById?containerId=franchises&id=${locationId}`).then(res => {
                if(res.data[0].projectTemplates) {
                    setTemplates(res.data[0].projectTemplates);
                }      
            })
    }, [])

    function saveTemplate(template, newBool) {

        // newBool variable tells function whether template is a new template or if it is a previous template being edited
        let arr;
        if(newBool) {
            // generate a random id for new template
            template.id = uuidv4();

            // add new template to array of existing templates
            arr = [template, ...templates]
        }
        else {
            // only update template that is being edited
            arr = templates.map(item => {
                if(item.id === template.id) {
                    return template
                }
                return item
            })
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=franchises&id=${locationId}&partitionKey=id`, {
            projectTemplates: arr
        }).catch(err => {
            alert('Oops! There was an error. Please try again or submit a support ticket.')
        }).then(res => {
            console.log(res);

            setTemplates(arr);
            setDisplayTemplateForm(false);
        });
    }

    function deleteTemplate(template, newBool) {
        let arr = templates;

        // check if template has not yet been saved to database
        // reset new template and discard changes
        // make no changes to database
        if(newBool) {
            setDisplayTemplateForm(false);
            setNewTemplate({
                milestones: []
            })
        }

        else {
            arr = templates.filter(temp => temp.id !== template.id);

            axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=franchises&id=${locationId}&partitionKey=id`, {
                projectTemplates: arr
            }).catch(err => {
                alert('Oops! There was an error. Please try again or submit a support ticket.')
            }).then(res => {
                setTemplates(arr);
            });
        }
    }

    return(
        <PageLayout
            page='Templates'
        >
            <Paper sx={{p: 3}}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant='h5'>Your Project Templates</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <IconButton
                                onClick={() => setDisplayTemplateForm(true)}
                                sx={{color: 'green'}}
                            >
                                <AddCircle />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {displayTemplateForm &&
                <Paper sx={{p: 3, mt: 2}}>
                    <Template 
                        saveTemplate={saveTemplate}
                        newBool={true}
                        template={newTemplate}
                        deleteTemplate={deleteTemplate} 
                        title={
                            <Typography sx={{color: '#63666a', mb: 3}}>
                                <AddCircle sx={{pt: 1}} /> NEW PROJECT TEMPLATE
                            </Typography>
                        } 
                    />
                </Paper>
            }
            {templates.length > 0 &&
                <Paper sx={{p: 3, mt: 2}}>
                    {templates.map(template => (
                        <AccordionComponent title={template.title} key={template.title}>
                            <Template
                                deleteTemplate={deleteTemplate} 
                                newBool={false} 
                                saveTemplate={saveTemplate}
                                template={template} 
                                title={
                                    <Typography sx={{color: '#63666a', mb: 3}}>
                                        <Edit sx={{pt: 1}} /> {template.title.toUpperCase()}
                                    </Typography>
                                } 
                            />
                        </AccordionComponent>
                    ))}
                </Paper>
            }
        </PageLayout>
    )
}

export default Templates;