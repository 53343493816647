import React, { useContext, useState, useCallback } from "react";
import { Box, Button, Tab, Tabs, Typography, TextField, TableCell, TableRow, Table, Grid, Divider, TableBody, MenuItem, Select, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { ProjectContext } from "../openProject";
import { DataGridPro } from "@mui/x-data-grid-pro";
import './style.css';
import axios from "axios";

const paymentOptions = [
    'By Check',
    'By Cash',
    'In Person',
    'By Credit Card'
];

const billingOptions = [
    'Progress',
    'Per Service',
    'Seasonal',
    'Monthly',
    'Time and Materials'
];

const termsOptions = [
    'Net Due Upon Completion',
    'Next Due 30 Days',
    'Next Due 45 Days',
    'Next Due 60 Days'
];

const retentionOptions = [
    'No Retention',
    '3% Retention',
    '5% Retention',
    '10% Retention',
    '15% Retention',
    '20% Retention',
    '25% Retention'
];
const invoiceOptions = [
    'Mail',
    'Email',
    'Fax',
    'Portal'
];
const frequencies = [
    'Just Once',
    'Daily',
    'Weekly',
    'Bi-Weekly',
    'Monthly',
    'Bi-Monthly',
    'Quarterly',
    'Semi-Annual'
];

const types = [
    'One Time Service',
    'Recurring Service'
];

const reportingTypes = [
    'Photos Included',
    'Paper Trail Weekly Reports',
    'Weekly Reports for Maintenance',
    'Legal Perspective - Document',
    'Problems from Vendors, Back Charges',
    'Phone App',
    'GPS Timestamps',
    'None Required'
];

const reportingFrequencies = [
    'Just Once',
    'Daily',
    'Weekly',
    'Bi-Weekly',
    'Monthly',
    'Bi-Monthly',
    'Quarterly',
    'Semi-Annual'
];

const onboardingProcesses = [
    'On-site',
    'In Office'
];

const pricingMethods = [
    'Unit Costing',
    'Gross Margin Pricing',
    'Price Matching',
    'Other'
]

const SelectComp = ( props ) => {
    const { value, handleChange, arr } = props;

    return (
        <Select
            value={value}
            onChange={handleChange}
            fullWidth
            size='small'
            //id='downpaymentRequired'
        >
            {arr.map(option => {
                return <MenuItem key={option} value={option}>{option}</MenuItem>
            })}
        </Select>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width: '100%', maxHeight: '90vh', overflowY: 'auto'}}
        >
            {value === index && (
                <Box sx={{ px: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
  

function NewHandoff() {
    const [value, setValue] = useState(0);
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [salesToOps, setSalesToOps] = useState(project.salesToOps);
    const [editOn, setEditOn] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const YesNoRadio = ( props ) => {
        const { field, value } = props;
    
        function handleChange(e) {
            setSalesToOps({
                ...salesToOps,
                billingDetails: {
                    ...salesToOps.billingDetails,
                    uniqueDetails: {
                        ...salesToOps.billingDetails.uniqueDetails,
                        [field]: e.target.value
                    }
                }
            })
        }
    
        return (
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange}
                    value={value}
                >
                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                </RadioGroup>
            </FormControl>
        )
    };

    function handleBillingChange(e) {
        setSalesToOps({
            ...salesToOps,
            billingDetails: {
                ...salesToOps.billingDetails,
                [e.target.id]: e.target.value
            }
        })
    }

    function handleHowWeGetPaidChange(field, value) {
        setSalesToOps({
            ...salesToOps,
            billingDetails: {
                ...salesToOps.billingDetails,
                howWeGetpaid: {
                    ...salesToOps.billingDetails.howWeGetpaid,
                    [field]: value
                }
            }
        })
    };
    
    let deadlineCols = [
        {field: 'deadline', headerName: 'List All Project / Maintenance Specific Deadliens', flex: 1},
        {
            field: 'dueDate', 
            headerName: 'Due Date',
            renderCell: (params) => {
                return (
                    <>
                        {editOn 
                            ?   <TextField
                                    value={params.row.dueDate}
                                    size='small'
                                    fullWidth
                                    type='date'
                                    onChange={(e) => updateDueDate(params, e)}
                                /> 
                            :   <>{new Date(params.row.dueDate).toLocaleDateString()}</>
                        }
                    </>
                )
            },
            flex: .5
        }
    ];

    const updateDueDate = useCallback((params, e) => {
        let rows = salesToOps.billingDetails.contractDetails.map(deadline => {
            if(deadline.deadline === params.row.deadline) {
                return {
                    ...deadline,
                    dueDate: e.target.value
                }
            }
            return deadline
        });

        params.row.deadline === 'collect payment'
        ?   setSalesToOps({
            ...salesToOps,
            billingDetails: {
                ...salesToOps.billingDetails,
                contractDetails: rows,
                downpaymentDate: e.target.value
            }
        })
        :   setSalesToOps({
            ...salesToOps,
            billingDetails: {
                ...salesToOps.billingDetails,
                contractDetails: rows
            }
        })

    }, [salesToOps.billingDetails]);

    const handleOutsourcingChange = useCallback((field, value) => {
        setSalesToOps({
            ...salesToOps,
            outsourcingDetails: {
                ...salesToOps.outsourcingDetails,
                [field]: value
            }
        })
    }, [salesToOps.outsourcingDetails]);

    const OutsourcingYesNoRadio = ( props ) => {
        const { field, value } = props;
    
        function handleChange(e) {
            setSalesToOps({
                ...salesToOps,
                outsourcingDetails: {
                    ...salesToOps.outsourcingDetails,
                    [field]: e.target.value
                }
            })
        }
    
        return (
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange}
                    value={value}
                >
                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                </RadioGroup>
            </FormControl>
        )
    };

    const SubYesNoRadio = ( props ) => {
        const { title, value } = props;
    
        return (
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => updateSubRequirements(title, e.target.value)}
                    value={value}
                >
                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                </RadioGroup>
            </FormControl>
        )
    };


    const valueCols = [
        {field: 'title', headerName: 'Title', flex: 1},
        {
            field: 'value', 
            headerName: 'Value', 
            flex: .3,
            align: 'right',
            renderCell: (params) => {
                return (
                    <TextField
                        size='small'
                        fullWidth
                        value={params.row.value}
                        onChange={(e) => updateValue(params, e)}
                        inputProps={{style: {
                            textAlign: 'right'
                        }}}
                    />
                )
            }
        }
    ];

    function updateValue(params, e) {
        let rows = salesToOps.outsourcingDetails.values.map(value => {
            if(value.title === params.row.title) {
                return {
                    ...value,
                    value: e.target.value
                }
            }

            return value
        })
        setSalesToOps({
            ...salesToOps,
            outsourcingDetails: {
                ...salesToOps.outsourcingDetails,
                values: rows
            }
        })
    };

    const columns = [
        {field: 'sub', headerName: 'List Possible Subcontractors', flex: 1, type: 'singleSelect', valueOptions: ['test', 'test2']},
        {field: 'phone', headerName: 'Phone Numbers', flex: .5}
    ];

    function updateSubRequirements(title, value) {
        let arr = salesToOps.outsourcingDetails.subRequirements.map(question => {
            if(question.title === title) {
                return {
                    ...question,
                    value: value
                }
            }
            return question
        });

        setSalesToOps({
            ...salesToOps,
            outsourcingDetails: {
                ...salesToOps.outsourcingDetails,
                subRequirements: arr
            }
        })
    };
    
    const handleFailureChange = useCallback((title, value) => {
        let arr = salesToOps.outsourcingDetails.failureCauses.map(cause => {
            if(cause.title === title) {
                return {
                    ...cause,
                    value: value
                }
            }
            return cause
        });

        setSalesToOps({
            ...salesToOps,
            outsourcingDetails: {
                ...salesToOps.outsourcingDetails,
                failureCauses: arr
            }
        })
    }, [salesToOps.outsourcingDetails.failureCauses]);

    function updateSubRequirements(title, value) {
        let arr = salesToOps.outsourcingDetails.subRequirements.map(question => {
            if(question.title === title) {
                return {
                    ...question,
                    value: value
                }
            }
            return question
        });

        setSalesToOps({
            ...salesToOps,
            outsourcingDetails: {
                ...salesToOps.outsourcingDetails,
                subRequirements: arr
            }
        })
    };

    const saveChanges = () => {
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {salesToOps: salesToOps}).then(res => {
            console.log(res);
            setEditOn(false);
        })
    };
    
    return (
        <>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider', width: '250px'}}
                >
                    <Tab label="billing" {...a11yProps(0)} sx={{alignItems: 'start'}} />
                    <Tab label="outsourcing" {...a11yProps(1)} sx={{alignItems: 'start'}} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Box sx={{display: 'flex', mb: 2}}>
                        <Typography variant='h6' sx={{flexGrow: 1}}>How Do We Get Paid</Typography>
                        {!editOn &&
                            <Button
                                variant='outlined'
                                onClick={() => setEditOn(true)}
                                sx={{mr: 2}}
                            >
                                edit
                            </Button>
                        }
                        {editOn &&
                            <Button
                                variant='outlined'
                                onClick={saveChanges}
                                color='success'
                                sx={{mr: 2}}
                            >
                                save changes
                            </Button>
                        }
                    </Box>

                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{py: 0}}>How are invoices submitted?</TableCell>
                                <TableCell sx={{py: 0}}>
                                    {editOn 
                                        ?   <SelectComp 
                                                value={salesToOps.billingDetails.howWeGetpaid.invoicesSubmitted}
                                                arr={invoiceOptions}
                                                handleChange={(e) => {
                                                handleHowWeGetPaidChange('invoicesSubmitted', e.target.value)
                                                }}
                                            />
                                        :   <>{salesToOps.billingDetails.howWeGetpaid.invoicesSubmitted}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Is a down payment required?</TableCell>
                                <TableCell sx={{py: 0}}>
                                    {editOn
                                        ?    <Select
                                                value={salesToOps.billingDetails.howWeGetpaid.downpaymentRequired}
                                                onChange={(e) => {
                                                handleHowWeGetPaidChange('downpaymentRequired', e.target.value)
                                                }}
                                                fullWidth
                                                size='small'
                                                id='downpaymentRequired'
                                            >
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>
                                            </Select>
                                        :   <>{salesToOps.billingDetails.howWeGetpaid.downpaymentRequired}</>
                                    }

                                </TableCell>
                            </TableRow>
                            {salesToOps.billingDetails.howWeGetpaid.downpaymentRequired &&
                                <>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Down payment due date</TableCell>
                                        <TableCell sx={{py: 0}}>
                                            {editOn
                                                ?   <TextField
                                                        fullWidth
                                                        size='small'
                                                        value={salesToOps.billingDetails.howWeGetpaid.downpaymentDate}
                                                        onChange={(e) => {
                                                            handleHowWeGetPaidChange('downpaymentDate', e.target.value)
                                                        }}
                                                        id='downpaymentDate'
                                                        type='date'
                                                    />
                                                :   <>{salesToOps.billingDetails.howWeGetpaid.downpaymentDate}</>
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>How much is the down payment?</TableCell>
                                        <TableCell sx={{py: 0}}>
                                            {editOn
                                                ?   <TextField
                                                value={salesToOps.billingDetails.howWeGetpaid.downpayment}
                                                onChange={(e) => handleHowWeGetPaidChange('downpayment', e.target.value)}
                                                id='downpayment'
                                                fullWidth
                                                size='small'
                                            />
                                                :   <>{salesToOps.billingDetails.howWeGetpaid.downpayment}</>
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>How will it be received?</TableCell>
                                        <TableCell sx={{py: 0}}>    
                                            {editOn
                                                ?   <SelectComp 
                                                value={salesToOps.billingDetails.howWeGetpaid.downpaymentReceived}
                                                arr={paymentOptions}
                                                handleChange={(e) => {
                                                    handleHowWeGetPaidChange('downpaymentReceived', e.target.value)
                                                }}
                                            />
                                                :   <>{salesToOps.billingDetails.howWeGetpaid.downpaymentReceived}</>
                                            }                                       
                                            
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                            {project.clientType !== 'Residential' && 
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Billing is set up as?</TableCell>
                                    <TableCell sx={{py: 0}} py='none'>
                                        {editOn
                                            ?    <SelectComp 
                                            value={salesToOps.billingDetails.howWeGetpaid.billingSetUp}
                                            arr={billingOptions}
                                            handleChange={(e) => {
                                                handleHowWeGetPaidChange('billingSetUp', e.target.value)
                                            }}
                                        />
                                            :   <>{salesToOps.billingDetails.howWeGetpaid.billingSetUp}</>
                                        }
                                       
                                    </TableCell>
                                </TableRow>
                            }
                            {project.clientType !== 'Residential' && 
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Billing on what day of the month?</TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {editOn
                                            ?   <TextField
                                                    fullWidth
                                                    size='small'
                                                    value={salesToOps.billingDetails.howWeGetpaid.billingDay}
                                                    onChange={(e) => handleHowWeGetPaidChange('billingDay', e.target.value)}
                                                    id='billingDay'
                                                />
                                        :   <>{salesToOps.billingDetails.howWeGetpaid.billingDay}</>
                                    }
                                    </TableCell>
                                </TableRow>
                            }
                            {project.clientType === 'Government' &&
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Terms for payment?</TableCell>
                                    <TableCell sx={{py: 0}}>
                                    {editOn
                                            ?   <SelectComp 
                                            value={salesToOps.billingDetails.howWeGetpaid.paymentTerms}
                                            arr={termsOptions}
                                            handleChange={(e) => {
                                                handleHowWeGetPaidChange('paymentTerms', e.target.value)
                                            }}
                                        />
                                            :   <>{salesToOps.billingDetails.howWeGetpaid.paymentTerms}</>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            }
                            {project.clientType !== 'Residential' &&
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Retention to be held?</TableCell>
                                    <TableCell sx={{py: 0}}>
                                    {editOn
                                            ?   <Select
                                            value={salesToOps.billingDetails.howWeGetpaid.retentionHeld}
                                            onChange={(e) => handleHowWeGetPaidChange('retentionHeld', e.target.value)}
                                            fullWidth
                                            size='small'
                                            id='retentionHeld'
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                            :   <>{salesToOps.billingDetails.howWeGetpaid.retentionHeld}</>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            }
                            {salesToOps.billingDetails.howWeGetpaid.retentionHeld && project.clientType === 'Government' &&
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Retention terms?</TableCell>
                                    <TableCell sx={{py: 0}}>
                                    {editOn
                                            ?   <SelectComp 
                                            value={salesToOps.billingDetails.howWeGetpaid.retentionTerms}
                                            arr={retentionOptions}
                                            handleChange={(e) => {
                                                handleHowWeGetPaidChange('retentionTerms', e.target.value)
                                            }}
                                        />
                                            :   <>{salesToOps.billingDetails.howWeGetpaid.retentionTerms}</>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            }

                            <TableRow>
                                <TableCell sx={{py: 0}}>Other Billing Notes</TableCell>
                                <TableCell sx={{py: 0}}>
                                    {editOn
                                        ?   <TextField
                                                fullWidth
                                                size='small'
                                                value={salesToOps.billingDetails.howWeGetpaid.notes}
                                                onChange={(e) => handleHowWeGetPaidChange('notes', e.target.value)}
                                                id='notes'
                                            />
                                            :   <>{salesToOps.billingDetails.howWeGetpaid.notes}</>
                                        }
                                    
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    {project.clientType !== 'Residential' && 
                        <>    
                            {editOn   
                                ?   <TextField
                                        fullWidth
                                        id='billingSteps'
                                        value={salesToOps.billingDetails.billingSteps}
                                        onChange={handleBillingChange}
                                        multiline
                                        minRows={2}
                                        sx={{my:3}}
                                        label='List the steps for Billing per Contract'
                                    />
                                :   <>  List the steps for Billing per Contract <br />
                                        <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.billingSteps}</Typography>
                                    </>
                            }
                            {editOn ? 
                            <TextField
                                fullWidth
                                id='unusualProcedures'
                                value={salesToOps.billingDetails.unusualProcedures}
                                onChange={handleBillingChange}
                                multiline
                                minRows={2}
                                sx={{mb:3}}
                                label='Any unusual billing Procedures? (certified payroll, prevailing wage, if so list TB and subcontractor intent #s)'
                            /> : <>Any unusual billing Procedures? (certified payroll, prevailing wage, if so list TB and subcontractor intent #s) <br />
                                <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.unusualProcedures}</Typography></>
                            }
                            {editOn ? 
                            <TextField
                                fullWidth
                                id='supervisorSignoff'
                                value={salesToOps.billingDetails.supervisorSignoff}
                                onChange={handleBillingChange}
                                multiline
                                minRows={2}
                                sx={{mb:3}}
                                label='Does the Owner or supervisor need to sign off on invoices or progress payments?'
                            /> : <>Does the Owner or supervisor need to sign off on invoices or progress payments? <br />
                                    <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.supervisorSignoff}</Typography>
                                </>}
                            {editOn ?
                            <TextField
                                fullWidth
                                id='otherDocuments'
                                value={salesToOps.billingDetails.otherDocuments}
                                onChange={handleBillingChange}
                                multiline
                                minRows={2}
                                sx={{mb:3}}
                                label='Other documents required with invoices? (Pictures, Schedule of Values, Work Orders, Inspections)'
                            /> : <>Other documents required with invoices? (Pictures, Schedule of Values, Work Orders, Inspections)' <br />
                                <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.otherDocuments}</Typography></>}
                        </>
                    }
                    {editOn ?
                    <TextField
                        fullWidth
                        id='serviceSoftware'
                        value={salesToOps.billingDetails.serviceSoftware}
                        onChange={handleBillingChange}
                        multiline
                        minRows={2}
                        sx={{mb:3}}
                        label='Is there service reporting software we need to use?  If so list steps and website links or attach'
                    /> : <>Is there service reporting software we need to use?  If so list steps and website links or attach <br />
                    <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.serviceSoftware}</Typography></>}

                    {project.clientType !== 'Residential' && 
                        <>
                        {editOn ?
                        <TextField
                            fullWidth
                            id='terminationProcedure'
                            value={salesToOps.billingDetails.terminationProcedure}
                            onChange={handleBillingChange}
                            multiline
                            minRows={2}
                            sx={{mb:3}}
                            label='What is the Termination Procedure (Dispute Clause, Change Clause)'
                        /> : <>What is the Termination Procedure (Dispute Clause, Change Clause) <br />
                                <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.terminationProcedure}</Typography></>}
                        </>
                    }

                    <Divider sx={{my: 3}} />

                    <Typography variant='h6' sx={{mb: 2}}>Contract Details</Typography>

                    <DataGridPro
                        autoHeight
                        autoPageSize
                        hideFooter
                        columns={deadlineCols}
                        rows={salesToOps.billingDetails.contractDetails || []}
                        getRowId={row => row.deadline}
                        density='compact'
                    />

                    {project.clientType !== 'Residential' && 
                        <>
                            <Typography variant='h6' sx={{mb: 2, mt: 3}}>Unique Contract Details</Typography>

                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is there a dispute resolution clause?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.disputeResolution}
                                                field='disputeResolution'
                                            />
                                        :   <>
                                            {salesToOps.billingDetails.uniqueDetails.disputeResolution}
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is there a change order clause?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.changeOrderClause}
                                                field='changeOrderClause'
                                            />
                                        :   <>
                                            {salesToOps.billingDetails.uniqueDetails.changeOrderClause}
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Are there Penalties if the project is delayed?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.delayedPenalties}
                                                field='delayedPenalties'
                                            />
                                        :   <>
                                            {salesToOps.billingDetails.uniqueDetails.delayedPenalties}
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is a Schedule of Values required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                                <YesNoRadio
                                                    value={salesToOps.billingDetails.uniqueDetails.valuesSchedule}
                                                    field='valuesSchedule'
                                                    />
                                                :   <>
                                                    {salesToOps.billingDetails.uniqueDetails.valuesSchedule}
                                                    </>                                            
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is an As Built required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.asBuiltRequired}
                                                field='asBuiltRequired'
                                            />

                                                :   <>
                                                    {salesToOps.billingDetails.uniqueDetails.asBuiltRequired}
                                                </>                                           
                                             }
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is Irrigation Training required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.irrigationTraining}
                                                field='irrigationTraining'
                                            />

                                                :   <>
                                                    {salesToOps.billingDetails.uniqueDetails.irrigationTraining}
                                                </>                                            
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is a Training Manual required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.trainingManual}
                                                field='trainingManual'
                                            />

                                                :   <>
                                                    {salesToOps.billingDetails.uniqueDetails.trainingManual}
                                                </>                                            
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is a Site Specific Safety Manual required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.siteSafetyManual}
                                                field='siteSafetyManual'

                                            />
                                            :   <>
                                            {salesToOps.billingDetails.uniqueDetails.siteSafetyManual}
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Is a Traffic Control Plan required?</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.trafficControl}
                                                field='trafficControl'

                                            />
                                            :   <>
                                            {salesToOps.billingDetails.uniqueDetails.trafficControl}
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 0}}>Are there manditory meetings? (if so, list below)</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <YesNoRadio
                                                value={salesToOps.billingDetails.uniqueDetails.mandatoryMeetings}
                                                field='mandatoryMeetings'

                                            />
                                            :   <>
                                            {salesToOps.billingDetails.uniqueDetails.mandatoryMeetings}
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </>
                    }


                    {editOn ?

                    <TextField
                        fullWidth
                        value={salesToOps.billingDetails.uniqueDetails.notes}
                        onChange={(e) => setSalesToOps({
                            ...salesToOps,
                            billingDetails: {
                                ...salesToOps.billingDetails,
                                uniqueDetails: {
                                    ...salesToOps.billingDetails.uniqueDetails,
                                    notes: e.target.value
                                }
                            }
                        })}
                        multiline
                        minRows={2}
                        label='Any additional notes'
                        sx={{mt: 3}}
                    />
                    : <> <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.billingDetails.uniqueDetails.notes}</Typography></>
                    }
                
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <>
                    <Box sx={{display: 'flex', mb: 2}}>
                        <Typography variant='h6' sx={{flexGrow: 1}}></Typography>
                        {!editOn &&
                            <Button
                                variant='outlined'
                                onClick={() => setEditOn(true)}
                                sx={{mr: 2}}
                            >
                                edit
                            </Button>
                        }
                        {editOn &&
                            <Button
                                variant='outlined'
                                onClick={saveChanges}
                                color='success'
                                sx={{mr: 2}}
                            >
                                save changes
                            </Button>
                        }
                    </Box>
                    {salesToOps.outsourcingDetails.possibleSubs?.length > 0 &&
                        <>
                            <Typography variant='h6' sx={{mb: 2}}>Possible Subcontractors</Typography>
                            <DataGridPro
                                sx={{mb: 2}}
                                columns={columns}
                                rows={salesToOps.outsourcingDetails.possibleSubs || []}
                                autoHeight
                                autoPageSize
                                density='compact'
                                hideFooter
                            />
                        </>
                    }

                    <Grid container spacing={2} my={2}>
                        {project.clientType !== 'Residential' && 
                            <Grid item xs={12} md={4}>
                                {editOn? 
                                <TextField
                                    value={salesToOps.outsourcingDetails.numSites}
                                    fullWidth
                                    size='small'
                                    onChange={(e) => handleOutsourcingChange('numSites', e.target.value)}
                                    label='Number of Sites'
                                />
                                : <>{salesToOps.outsourcingDetails.numSites}</>
                                }
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            {editOn? 
                            <SelectComp
                                label='Type of Work'
                                arr={types}
                                value={salesToOps.outsourcingDetails.type}
                                handleChange={(e) => handleOutsourcingChange('type', e.target.value)}
                            />
                            : <></>
                            }
                        </Grid>
                        {project.clientType !== 'Residential' && 
                            <Grid item xs={12} md={4}>
                                {editOn? 
                                <SelectComp
                                    label='Frequency'
                                    arr={frequencies}
                                    value={salesToOps.outsourcingDetails.frequency}
                                    handleChange={(e) => handleOutsourcingChange('frequency', e.target.value)}
                                />
                                : <>{salesToOps.outsourcingDetails.type}</>
                                }
                            </Grid>
                        }
                    </Grid>

                    <Table size='small' sx={{mb: 2}}>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Proposed Schedule Attached and Reviewed?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.scheduleAttached}
                                        field='scheduleAttached'
                                    />
                                    : <>{salesToOps.outsourcingDetails.scheduleAttached}</>
                                    }
                                </TableCell>
                            </TableRow>

                            {project.clientType !== 'Residential' && 
                                <TableRow>
                                    <TableCell>How Many Locations Per Partner are Required?</TableCell>
                                    <TableCell>
                                        {editOn ?
                                        <TextField
                                            value={salesToOps.outsourcingDetails.locationsPerPartner}
                                            onChange={(e) => handleOutsourcingChange('locationsPerPartner', e.target.value)}
                                            id='locationsPerPartner'
                                            fullWidth
                                            size='small'
                                        />
                                        : <>{salesToOps.outsourcingDetails.locationsPerPartner}</>
                                        }
                                    </TableCell>
                                </TableRow>

                            }

                            {project.clientType !== 'Residential' && 
                                <TableRow>
                                    <TableCell sx={{py: 0}}>Site List Attached and Reviewed?</TableCell>
                                    <TableCell sx={{py: 0}} align='right'>
                                        {editOn ?
                                        <OutsourcingYesNoRadio
                                            value={salesToOps.outsourcingDetails.siteListAttached}
                                            field='siteListAttached'
                                        />
                                        : <>{salesToOps.outsourcingDetails.siteListAttached}</>
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell sx={{py: 0}}>Toilet On-site?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.toiletOnSite}
                                        field='toiletOnSite'
                                    />
                                    : <>{salesToOps.outsourcingDetails.toiletOnSite}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Permits Required?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.permitsRequired}
                                        field='permitsRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.permitsRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Utilities Located?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.utilitiesLocated}
                                        field='utilitiesLocated'
                                    />
                                    : <>{salesToOps.outsourcingDetails.utilitiesLocated}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Are 3rd Party Engineers Required?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.thirdPartyEngineers}
                                        field='thirdPartyEngineers'
                                    />
                                    : <>{salesToOps.outsourcingDetails.thirdPartyEngineers}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Site Cleanup Requirements?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.siteCleanUpRequirements}
                                        field='siteCleanUpRequirements'
                                    />
                                    : <>{salesToOps.outsourcingDetails.siteCleanUpRequirements}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Sub Agreements Needed?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.msaRequired}
                                        field='msaRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.msaRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Sub Agreements Signed?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.msaSigned}
                                        field='msaSigned'
                                    />
                                    : <>{salesToOps.outsourcingDetails.msaSigned}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Lien Releases Required?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.lienRequired}
                                        field='lienRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.lienRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={editOn ? 4 : 12}>
                            {editOn ?
                            <SelectComp
                                label='Reporting Type'
                                arr={reportingTypes}
                                handleChange={(e) => handleOutsourcingChange('reportingType', e.target.value)}
                                value={salesToOps.outsourcingDetails.reportingType}
                            />
                            : <> Reporting Type <br />
                                <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.reportingType}</Typography></>
                            }
                        </Grid>
                        <Grid item xs={12} md={editOn ? 4 : 12}>
                            {editOn ?
                            <SelectComp
                                label='What is the required reporting frequency?'
                                arr={reportingFrequencies}
                                handleChange={(e) => handleOutsourcingChange('reportingFrequencies', e.target.value)}
                                value={salesToOps.outsourcingDetails.reportingFrequencies}
                            />
                            : <>What is the required reporting frequency?<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.reportingFrequencies}</Typography></>
                            }
                        </Grid>
                        <Grid item xs={12} md={editOn ? 4 : 12}>
                            {editOn ?
                            <TextField
                                value={salesToOps.outsourcingDetails.walkthroughFrequency}
                                onChange={(e) => handleOutsourcingChange('walkthroughFrequency', e.target.value)}
                                size='small'
                                fullWidth
                                label='When are walk throughs?'
                            />
                            : <>When are walk throughs?<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.walkthroughFrequency}</Typography></>
                            }
                        </Grid>
                    </Grid>

                    {editOn ?
                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        label='List any potential pitfalls'
                        value={salesToOps.outsourcingDetails.potentialPitFalls}
                        onChange={(e) => handleOutsourcingChange('potentialPitFalls', e.target.value)}
                        sx={{mb: 3}}
                    />
                    : <>List any potential pitfalls<br />
                        <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}></Typography></>
                    }

                    {editOn ?
                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        label='How should communication be handled with primary contact?'
                        value={salesToOps.outsourcingDetails.communicationExpectations}
                        onChange={(e) => handleOutsourcingChange('communicationExpectations', e.target.value)}
                        sx={{mb: 3}}
                    />
                    : <>How should communication be handled with primary contact?<br />
                        <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}></Typography></>
                    }

                    {editOn ?
                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        label='Who has what roles in communication with all key players?'
                        value={salesToOps.outsourcingDetails.keyPlayerRoles}
                        onChange={(e) => handleOutsourcingChange('keyPlayerRoles', e.target.value)}
                        sx={{mb: 3}}
                    />
                    : <>Who has what roles in communication with all key players?<br />
                        <Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}></Typography></>
                    }

                    <Typography variant='h6' sx={{mb: 2}}>What Requirements do the Subcontractors have reporting?</Typography>
                    <Table size='small' sx={{mb: 3}}>
                        <TableBody>
                            {salesToOps.outsourcingDetails.subRequirements.map(question => {
                                return (
                                    <TableRow key={question.title}>
                                        <TableCell sx={{py: 0}}>{question.title}</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                                <SubYesNoRadio
                                                    value={question.value}
                                                    title={question.title}
                                                />
                                            :   <>{question.value}</>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={editOn ? 4 : 12}>
                            {editOn ?
                                <SelectComp
                                    label='What is the Interview Process for On-Boarding; On-site or In Office'
                                    arr={onboardingProcesses}
                                    value={salesToOps.outsourcingDetails.onboardingProcess}
                                    onChange={(e) => handleOutsourcingChange('onboardingProcess', e.target.value)}
                                />
                            : <>What is the Interview Process for On-Boarding; On-site or In Office <br />
                                    {salesToOps.outsourcingDetails.onboardingProcess}
                                </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {editOn ?
                            <TextField
                                value={salesToOps.outsourcingDetails.onboardingInclusions}
                                onChange={(e) => handleOutsourcingChange('onboardingInclusions')}
                                fullWidth
                                multiline
                                minRows={2}
                                label='What is included in the on-boarding process?  (pics, drawings, scope, submittals, etc)'
                            />
                            : <></>
                            }
                        </Grid>
                    </Grid>

                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Does someone need to be onsite for first visit?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.onsiteRequired}
                                        field='onsiteRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.onsiteRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Is a walk through required with the sub prior to starting work?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.walkthroughRequired}
                                        field='walkthroughRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.walkthroughRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Are work orders required for services?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.workordersRequired}
                                        field='workordersRequired'
                                    />
                                    : <>{salesToOps.outsourcingDetails.workordersRequired}</>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    
                    <Typography variant='h6' sx={{mb: 2, mt: 3}}>Contract Values</Typography>

                    <DataGridPro
                        autoHeight
                        autoPageSize
                        hideFooter
                        columns={valueCols}
                        rows={salesToOps.outsourcingDetails.values || []}
                        getRowId={row => row.title}
                        density='compact'
                        sx={{mb: 3}}
                    />

                    {project.clientType !== 'Residential' && 
                        <>  
                            {editOn ?
                            <TextField
                                fullWidth
                                multiline
                                onChange={(e) => handleOutsourcingChange('growthPlan', e.target.value)}
                                minRows={2}
                                label='Describe the Growth Plan?  Who is involved: What is the timeline and how do we grow this account?'
                                value={salesToOps.outsourcingDetails.growthPlan}
                                sx={{mb: 3}}
                            />
                            : <>Describe the Growth Plan?  Who is involved: What is the timeline and how do we grow this account?<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.growthPlan}</Typography></>}

                            {editOn ?
                            <TextField
                                fullWidth
                                multiline
                                onChange={(e) => handleOutsourcingChange('strategicAccount', e.target.value)}
                                minRows={2}
                                label='Is this a strategic account and if so why?'
                                value={salesToOps.outsourcingDetails.strategicAccount}
                                sx={{mb: 3}}
                            />
                            : <>
                            Is this a strategic account and if so why? <br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.strategicAccount}</Typography></>}
                        </>
                    }

                    {editOn ? 
                    <SelectComp
                        label='How was this priced?'
                        value={salesToOps.outsourcingDetails.pricingMethod}
                        arr={pricingMethods}
                        onChange={(e) => handleOutsourcingChange('pricingMethod', e.target.value)}
                    />
                    : <>How was this priced? <br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.pricingMethod}</Typography></>
                    }

                    {editOn ? 
                    <TextField
                        fullWidth
                        multiline
                        onChange={(e) => handleOutsourcingChange('saleBackground', e.target.value)}
                        minRows={2}
                        label='What is the background on the sale?  How did it happen?'
                        value={salesToOps.outsourcingDetails.saleBackground}
                        sx={{my: 3}}
                    />
                    : <>What is the background on the sale?  How did it happen?<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.saleBackground}</Typography></>
                    }

                    <Table size='small' sx={{mb: 2}}>
                        <TableBody>
                            {salesToOps.outsourcingDetails.failureCauses.map(cause => {
                                return (
                                    <TableRow key={cause.title}>
                                        <TableCell sx={{py: 0}}>{cause.title}</TableCell>
                                        <TableCell sx={{py: 0}} align='right'>
                                            {editOn ?
                                            <TextField
                                                value={cause.value}
                                                onChange={(e) => handleFailureChange(cause.title, e.target.value)}
                                                size='small'
                                                fullWidth
                                            />
                                            :   <>{cause.value}</>}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>

                    <Table size='small' sx={{mb: 2}}>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Are Executed Contracts Added?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.executedContracts}
                                        field='executedContracts'
                                    />
                                    :<>{salesToOps.outsourcingDetails.executedContracts}</>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Addendums Added</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.addendumsAdded}
                                        field='addendumsAdded'
                                    />
                                    :<>{salesToOps.outsourcingDetails.addendumsAdded}</>
                                    }
                                </TableCell>
                            </TableRow>
                            {project.clientType !== 'Residential' && 
                                <TableRow>
                                    <TableCell sx={{py: 0}}>All Forms for Billing , Submittals, Reporting , etc. added?</TableCell>
                                    <TableCell sx={{py: 0}} align='right'>
                                        {editOn ?
                                        <OutsourcingYesNoRadio
                                            value={salesToOps.outsourcingDetails.allFormsAdded}
                                            field='allFormsAdded'
                                        />
                                        :<>{salesToOps.outsourcingDetails.allFormsAdded}</>
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell sx={{py: 0}}>Documented Communication with Customer and Vendors?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.communicationsDocumented}
                                        field='communicationsDocumented'
                                    />
                                    :<></>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Contract Plan and Timelines Added?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.timelinesAdded}
                                        field='timelinesAdded'
                                    />
                                    :<></>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 0}}>Do we expect change orders?</TableCell>
                                <TableCell sx={{py: 0}} align='right'>
                                    {editOn ?
                                    <OutsourcingYesNoRadio
                                        value={salesToOps.outsourcingDetails.changeOrderExpectedBool}
                                        field='changeOrderExpectedBool'
                                    />
                                    :<></>
                                    }
                                </TableCell>
                            </TableRow>
                            {salesToOps.outsourcingDetails.changeOrderExpectedBool &&
                                <TableRow>
                                    <TableCell sx={{py: 0}}>What do we expect change orders on?</TableCell>
                                    <TableCell sx={{py: 0}} align='right'>
                                        {editOn ? 
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={salesToOps.outsourcingDetails.changeOrderExpected}
                                            onChange={(e) => handleOutsourcingChange('changeOrderExpected', e.target.value)}
                                        />
                                        : <>{salesToOps.outsourcingDetails.changeOrderExpected}</>
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    {editOn ?
                    <TextField
                        multiline
                        minRows={2}
                        fullWidth
                        label='Describe the change order process'
                        value={salesToOps.outsourcingDetails.changeOrderProcess}
                        onChange={(e) => handleOutsourcingChange('changeOrderProcess', e.target.value)}
                    />
                    :<> Describe the change order process<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}>{salesToOps.outsourcingDetails.changeOrderProcess}</Typography></>
                    }

                    {/* need to add submittal requirements */}

                    

                    {editOn ?
                    <>
                    <Typography variant='h6' sx={{mb: 2, mt: 3}}>Additional Notes</Typography>
                    <TextField
                        multiline
                        minRows={3}
                        value={salesToOps.outsourcingDetails.notes}
                        id='notes'
                        onChange={(e) => setSalesToOps({
                            ...salesToOps,
                            outsourcingDetails: {
                                ...salesToOps.outsourcingDetails,
                                notes: e.target.value
                            }
                        })}
                        label='Notes'
                        fullWidth
                    /></>
                    :<> Additional Notes<br /><Typography sx={{width: '100%', border: '1px solid #f5f5f5', p: 3, mb: 3}}></Typography></>
                    }
                </>
                </TabPanel>
                
            </Box>
        </>
    )

}

export default NewHandoff;