import React, { useEffect, useState } from "react";
import axios from "axios";
import PageLayout from "../../components/pageLayout/pageLayout";
import { Grid, Paper, Typography } from "@mui/material";
import Chart from "react-google-charts";

function Dashboard() {
    const location = localStorage.getItem('location');
    const [projects, setProjects] = useState([]);

    let goal = 360000;

    const [revenue, setRevenue] = useState([
        ['inProgress', 0],
        ['complete', 0],
        ['goal', 360000]
    ]);

    const [numProjects, setNumProjects] = useState([
        ['Status', '# Projects'],
        ['In Progress', 0],
        ['Complete', 0],
        ['Total', 0]
    ])

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=projects`)
            .then(res => {
                let arr = res.data.filter(project => project.location === location);

                let inProgress = arr.filter(project => !project.complete);
                let progressRevenue = Math.round(inProgress.reduce((accum, item) => accum + item.bidTotal, 0));
                let progressCount = inProgress.length;

                let complete = arr.filter(project => project.complete);
                let completeRevenue = Math.round(complete.reduce((accum, item) => accum + item.bidTotal, 0));
                let completeCount = complete.length;

                let remaining = goal - completeRevenue - progressRevenue;
                let count = progressCount + completeCount;

                setRevenue([
                    ['Revenue', 'Status'],
                    ['In Progress Projects', progressRevenue],
                    ['Complete', completeRevenue],
                    ['Remaining to Reach Goal', remaining]
                ]);

                setNumProjects([
                    ['Status', '# Projects'],
                    ['In Progress', progressCount],
                    ['Complete', completeCount],
                ])

                setProjects(arr);

                //setLoading(false);
            })
    }, []);

    const options = {
        title: "Revenue Report",
        pieHole: 0.4,
        is3D: false,
        slices: {
            0: { color: "#0b56a4" },
            1: { color: "green" },
            2: { color: 'red'}
          },
    };

    const barOptions = {
        title: "Project Status",
        chartArea: { width: "50%" },
        hAxis: {
          title: "# Projects",
          minValue: 0,
        },
        vAxis: {
          title: "Status",
        },
      };

    return (
        <PageLayout
            page='Dashboard'
        >
            <Paper sx={{p: 3, mb: 2}}>
                {location.toUpperCase()} DASHBOARD
            </Paper>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={4}>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h5' sx={{mb: 2, textAlign: 'center'}}>$360,000 Goal Check In</Typography>
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={revenue}
                            options={options}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h5' sx={{mb: 2, textAlign: 'center'}}>Project Status</Typography>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="300px"
                            data={numProjects}
                            options={barOptions}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </PageLayout>
    )

}

export default Dashboard;