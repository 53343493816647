import {  Autocomplete, Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonBox from "../../components/buttonBox/buttonBox";
import style from "../../styles/modal";
import days from "./days";
import { v4 as uuidv4 } from 'uuid';
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Delete, Edit, Reorder } from "@mui/icons-material";


function Template(props) {
    const { title, template, saveTemplate, newBool, deleteTemplate } = props;

    //const [templates, setTemplates] = useState([]);
    const [newTemplate, setNewTemplate] = useState(template);

    const [newMilestone, setNewMilestone] = useState({
        duration: 1
    });

    const [newDependency, setNewDependency] = useState({
        duration: 1
    });

    const [rows, setRows] = useState(newTemplate.milestones);

    const [milestoneOpen, setMilestoneOpen] = useState(false);
    const [dependencyOpen, setDependencyOpen] = useState(false);
    const [reorderOpen, setReorderOpen] = useState(false);

    const [newRowOrder, setNewRowOrder] = useState([])

    function addMilestone() {
        let milestones;
        if(!newMilestone.id) {
            newMilestone.id = uuidv4();
            milestones = [...newTemplate.milestones, newMilestone];
        }

        else {
            milestones = newTemplate.milestones.map(milestone => {
                if(newMilestone.id === milestone.id) {
                    return newMilestone
                }
                return milestone
            })
        }

        setNewTemplate({
            ...newTemplate,
            milestones: milestones
        });

        setMilestoneOpen(false);
        setNewMilestone({
            duration: 1
        })
    }

    function handleEditBtn(params) {
        console.log(params);
        if(params.row.dependency) {
            setDependencyOpen(true);
            setNewDependency(params.row);
        }
        else {
            setMilestoneOpen(true);
            setNewMilestone(params.row)
        }
    }

    function handleDelete(params) {
        let arr;

        if(params.row.dependency) {
            arr = newTemplate.milestones.map(milestone => {
                if(milestone.id === params.row.dependency) {
                    let dependencies = milestone.dependencies.filter(dependency => dependency.id !== params.row.id)
                    return {
                        ...milestone,
                        dependencies: dependencies
                    }
                }

                return milestone
            })
        }

        else {
            arr = newTemplate.milestones.filter(milestone => milestone.id !== params.row.id);
        }

        setNewTemplate({
            ...newTemplate,
            milestones: arr
        })
    }

    const columns = [
        {
            field: 'title', 
            headerName: 'Title', 
            flex: 1,
            renderCell: (params) => {
                if(params.row.dependency) {
                    return(
                        <Box sx={{pl: 3, display: 'flex'}}>
                            ⤷ {params.row.title}
                        </Box>
                    )
                }

                return params.row.title
            }
        },
        {
            field: 'duration', 
            headerName: 'Duration', 
            flex: .25,
            valueGetter: (params) => `${params.row.duration} days`
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem icon={<Edit />} onClick={() => handleEditBtn(params)}  label='Edit' />,
                <GridActionsCellItem icon={<Delete />} onClick={() => handleDelete(params)} label='Delete' />
            ]
        }
    ]

    function addDependency() {
        let arr;

        if(!newDependency.id) {
            newDependency.id = uuidv4();

            arr = newTemplate.milestones.map(milestone => {
                if(milestone.id === newDependency.dependency) {
                    let dependencies = milestone.dependencies || [];
                    return {
                        ...milestone,
                        dependencies: [...dependencies, newDependency]
                    }
                }
                return milestone
            });
        }

        else {
            arr = newTemplate.milestones.map(milestone => {
                if(milestone.id === newDependency.dependency) {
                    let dependencies = milestone.dependencies.map(dependency => {
                        if(dependency.id === newDependency.id) {
                            return newDependency
                        }
                        return dependency
                    })
                    return {
                        ...milestone,
                        dependencies: dependencies
                    }
                }
                return milestone
            })
        }

        setNewTemplate({
            ...newTemplate,
            milestones: arr
        });

        setNewDependency({
            duration: 1
        });

        setDependencyOpen(false);
    }

    function renderRows(milestones) {
        let arr = [];
        milestones.forEach(milestone => {

            if(milestone.dependencies) {
                arr.push({
                    ...milestone,
                    category: milestone.title
                });
                arr = [
                    ...arr, 
                    ...milestone.dependencies.map(dependency => {
                        return {
                            ...dependency,
                            category: milestone.title
                        }
                    })
                ]
            }

            else arr.push(milestone);
        });
        console.log(arr)
        return arr
    }

    useEffect(() => {
        let arr = renderRows(newTemplate.milestones);
        setRows(arr);
    }, [newTemplate.milestones])

    function selectMilestone(milestone) {
        setNewDependency({
            ...newDependency,
            dependency: milestone.id
        })
    }

    function saveReorder() {
        let arr = [];
        newRowOrder.forEach(id => {
            let obj = newTemplate.milestones.filter(milestone => milestone.id === id )[0];
            arr.push(obj);
        })

        saveTemplate({
            ...newTemplate,
            milestones: arr
        }, false)

        setNewTemplate({
            ...newTemplate,
            milestones: arr
        })

        setReorderOpen(false);
    }

    function handleRowOrderChange(details) {
        // get the new order of milestone rows after user reorders them
        setNewRowOrder(details.api.getAllRowIds())
    }

    return (
        <>
            <Modal
                open={milestoneOpen}
                onClose={() => setMilestoneOpen(false)}
            >
                <Box sx={{...style, p: 3, maxWidth: '500px', width: '90%'}}>
                    <Typography variant='h5' sx={{mb: 2}}>Add a Milestone</Typography>
                    <Grid container spacing={1}>
                        <Grid item md={8} xs={12}>
                            <TextField
                                value={newMilestone.title || ''}
                                fullWidth
                                label='Milestone Title'
                                size='small'
                                onChange={(e) => setNewMilestone({
                                    ...newMilestone,
                                    title: e.target.value
                                })}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Autocomplete
                                value={newMilestone.duration}
                                options={days}
                                getOptionLabel={(option) => `${option} days`}
                                sx={{width: {xs: '100%', md: '120px'}, mt: {xs: 2}}}
                                renderInput={(params) => <TextField {...params} label="Duration" size='small' />}
                                onChange={(e, newValue) => setNewMilestone({
                                    ...newMilestone,
                                    duration: newValue
                                })} 
                            />
                        </Grid>
                    </Grid>

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px', mr: 1}}
                            variant='contained'
                            onClick={() => setMilestoneOpen(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={addMilestone}
                            color='success'
                        >
                            add milestone
                        </Button>
                    </ButtonBox>
                    <Typography sx={{fontWeight: 'bold'}}>Note:</Typography>
                    <Typography>You must still click "Save Template" button when this modal closes to apply changes</Typography>
                </Box>
            </Modal>

            <Modal
                open={dependencyOpen}
                onClose={() => setDependencyOpen(false)}
            >
                <Box sx={{...style, p: 3, maxWidth: '500px', width: '90%'}}>
                    <Typography variant='h5' sx={{mb: 2}}>Add a Dependency</Typography>

                    <Autocomplete
                        value={newTemplate.milestones.filter(milestone => milestone.id === newDependency.dependency)[0]}
                        options={newTemplate.milestones}
                        getOptionLabel={(option) => option.title}
                        sx={{mb: 2}}
                        renderInput={(params) => <TextField {...params} label="Select Mileston" size='small' fullWidth />}
                        onChange={(e, newValue) => selectMilestone(newValue)}
                        isOptionEqualToValue={(option, value) => {
                            return option.title === value.title
                        }}
                    />

                    <Grid container spacing={1}>
                        <Grid item md={8}>
                            <TextField
                                value={newDependency.title || ''}
                                fullWidth
                                label='Dependency Title'
                                size='small'
                                onChange={(e) => setNewDependency({
                                    ...newDependency,
                                    title: e.target.value
                                })}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                value={newDependency.duration}
                                options={days}
                                getOptionLabel={(option) => `${option} days`}
                                renderInput={(params) => <TextField {...params} label="Duration" size='small' fullWidth />}
                                onChange={(e, newValue) => setNewDependency({
                                    ...newDependency,
                                    duration: newValue
                                })}
                            />
                        </Grid>
                    </Grid>

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px', mr: 1}}
                            variant='contained'
                            onClick={() => setDependencyOpen(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={addDependency}
                            color='success'
                        >
                            add dependency
                        </Button>
                    </ButtonBox>

                    <Typography sx={{fontWeight: 'bold'}}>Note:</Typography>
                    <Typography>You must still click "Save Template" button when this modal closes to apply changes</Typography>
                </Box>
            </Modal>

            <Modal
                open={reorderOpen}
                onClose={() => setReorderOpen(false)}
            >
                 <Box sx={{...style, p: 3, maxWidth: '500px', width: '90%', maxHeight: '90%', overflowY: 'auto'}}>
                    <Typography>Reorder {template.title} milestones</Typography>

                    <div style={{marginTop: '20px'}}>
                        <DataGridPro
                            rows={newTemplate.milestones}
                            columns={columns}
                            disableSelectionOnClick
                            density="compact"
                            onRowOrderChange={(params, event, details) => handleRowOrderChange(details)}
                            rowReordering
                            autoHeight
                            autoPageSize
                        />
                    </div>

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px', mr: 1}}
                            variant='contained'
                            onClick={() => setReorderOpen(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={saveReorder}
                            color='success'
                        >
                            save
                        </Button>
                    </ButtonBox>

                    <Typography sx={{fontWeight: 'bold'}}>Note:</Typography>
                    <Typography>You must still click "Save Template" button when this modal closes to apply changes</Typography>
                 </Box>
            </Modal>

            <Grid container>
                <Grid item md={6} xs={12}>
                    { title }
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            sx={{fontSize: '10px', mr: 1}}
                            color='error'
                            onClick={() => deleteTemplate(newTemplate, newBool)}
                            variant='contained'
                        >
                            discard
                        </Button>
                        <Button
                            sx={{fontSize: '10px'}}
                            color='success'
                            onClick={() => saveTemplate(newTemplate, newBool)}
                            variant='contained'
                        >
                            save template
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <TextField
                fullWidth
                value={newTemplate.title || ''}
                onChange={(e) => setNewTemplate({
                    ...newTemplate,
                    title: e.target.value
                })}
                label='Template Title'
                size='small'
                sx={{mt: {xs: 3}}}
            />

            {rows.length > 0 &&
                <>
                    <Button
                        sx={{fontSize: '10px', mb: '10px', mt: '20px'}}
                        startIcon={<Reorder />}
                        onClick={() => setReorderOpen(true)}
                    >
                        reorder milestones
                    </Button>
                    <DataGridPro
                        columns={columns}
                        rows={rows}
                        disableSelectionOnClick
                        density="compact"
                        autoHeight
                        autoPageSize
                    />
                </>
            }

            <Button
                sx={{fontSize: '10px', mt: 2, mr: 1}}
                variant='contained'
                onClick={() => setMilestoneOpen(true)}
            >
                Add milestone
            </Button>
            <Button
                sx={{fontSize: '10px', mt: 2}}
                variant='contained'
                onClick={() => setDependencyOpen(true)}
                disabled={newTemplate.milestones.length === 0}
            >
                Add dependency
            </Button>
        </>
    )
}

export default Template;