const data = {
    statuses: [
        'All Statuses',
        'Estimate in Progress',
        'Bid Scrub Review and Approval',
        'Customer Proposal Phase',
        'Estimate Lost',
        'Version Not Used',
        'Estimate on Hold',
        'Contract Signed',
        'Looking for Subcontractor',
        'Work in Progress',
        'Work Complete',
        'Billing Complete',
        'Final Payment Collected',
        'Down Payment Collected'
    ],
    divisions: [
        'All Divisions',
        'Asphalt / Parking Lot Construction',
        'Capital Projects',
        'Concrete Projects',
        'Deck / Fencing / Outdoor Structures',
        'Electric Vehicles',
        'Enhancement',
        'Janitorial Services',
        'Landscape Maintenance',
        'Lot Sweeping',
        'Other Services',
        'Outdoor Living Area Construction',
        'Snow / Ice Removal',
        'Swimming Pools'
    ],
    states: [
        'Select State',
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersy',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ],
    types: [
        'Lead',
        'Client',
        'Subcontractor',
        'Vendor'
    ],
    referrals: [
        'ANGI',
        'Bark',
        'BBB',
        'Buildingconnected',
        'BuildZoom',
        'BXWA',
        'CAI',
        'Call in / Walk in',
        'Cold Calling',
        'Commercial',
        'Costar Mining',
        'CraftJack',
        'Donuts',
        'Existing Client',
        'Google',
        'Government',
        'Home Guide',
        'Homeadvisor',
        'Houzz',
        'Jobsite Sign',
        'Mailer',
        'Networx',
        'Next Door',
        'Office Sign',
        'Porch',
        'Referral',
        'Smith',
        'ThumbTack',
        'Trade Show',
        'Truck',
        'Website',
        'Yelp'
    ],
    costcodes: [
        '41100 Asphalt / Parking Lot Construction',
        '41400 Concrete Projects',
        '41500 Janitorial Services',
        '41550 Landscape Maintenance',
        '41600 Lot Sweeping',
        '41800 Other Services',
        '41750 Snow / Ice Removal',
        '41700 Swimming Pools',
        '42010 Residential Sales',
        '42050 Commercial Sales',
        '42100 Government Sales',
        '42250 Green Sales',
        '42750 Snow Sales',
        '42800 Other Services',
        '<Unassigned>'
    ]
}

export default data;