import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-google-charts";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import ButtonBox from "../../components/buttonBox/buttonBox";
import { useNavigate } from "react-router-dom";

function GanttView() {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [rows, setRows] = useState([]);
    const [subcontractors, setSubcontractors] = useState([]);
    const [sub, setSub] = useState('Filter by Sub');
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=projects').then(res => {
            setProjects(res.data);

            // filter projects down to only the projects with start date and end date selected
            // construct array of rows for Gantt Chart from filtered projects
            setRows(res.data.filter(project => project.salesToOps.projectDetails?.startDate && project.salesToOps.projectDetails?.endDate).map(project => {
                const { salesToOps, id, percentComplete } = project;
                const { projectDetails } = salesToOps;
                const { name, startDate, endDate } = projectDetails;

                console.log(startDate);

                return [
                    id,
                    name,
                    null,
                    new Date(startDate),
                    new Date(endDate),
                    null,
                    percentComplete || 0,
                    null
                ]
            }))

            let subArray = res.data.filter(project => project.subcontractor).map(project => project.subcontractor);
            let uniqueSubs = subArray.filter((elem, index) => subArray.findIndex(obj => obj.id === elem.id) === index);         

            setSubcontractors(uniqueSubs);
        })
    }, []);

    const columns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ]

    useEffect(() => {
        setData([columns, ...rows])
    }, [rows])

    const options = {
        height: 400,
        gantt: {
          trackHeight: 40,
        },
    };

    function filter(e) {
        setSub(e.target.value);
        // setFilteredProjects(projects.filter(project => project.subcontractor.name === e.target.value));

        let filtered = projects.filter(project => project.subcontractor.name === e.target.value);

        setRows(filtered.filter(project => project.salesToOps.projectDetails?.startDate && project.salesToOps.projectDetails?.endDate).map(project => {
            return [
                project.id,
                project.projectDetails.name,
                null,
                new Date(project.salesToOps.projectDetails.startDate),
                new Date(project.salesToOps.projectDetails.endDate),
                null,
                project.percentComplete || 0,
                null
            ]
        }));
    };

    return(
        <>
            <ButtonBox>
                <FormControl
                    size='small'
                    sx={{width: '200px'}}
                >
                    <InputLabel>Filter by Sub</InputLabel>
                    <Select
                        value={sub || ''}
                        onChange={filter}
                        sx={{mb: 3}}
                        label='Filter by Sub'
                    >
                        {subcontractors.map(sub => (
                            <MenuItem key={sub.id} value={sub.name}>{sub.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ButtonBox>
            {projects.length > 0 &&
                <Chart
                    chartType='Gantt'
                    width='100%'
                    height='100%'
                    data={data}
                    options={options}
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const dataTable = chartWrapper.getDataTable();
                                const selected = chart.getSelection();
                                let row = selected[0].row;
                                //console.log(dataTable.getValue(row, 0))
                                let id = dataTable.getValue(row, 0);
                                navigate(`/projects/${id}`)
                            }
                        }
                    ]}
                />
            }
        </>
            
    )
}

export default GanttView;