import { PanoramaVerticalSelect } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonBox from "../../components/buttonBox/buttonBox";
import PageLayout from "../../components/pageLayout/pageLayout";

function Subcontractors() {
    const [subs, setSubs] = useState([]);
    const location = localStorage.getItem('location');

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getAll?containerId=contacts`).then(res => {
            if(res.data !== 'No items found') {
                setSubs(res.data.filter(contact => contact.type === 'Subcontractor' && contact.location === location))
            }
        })
    }, [location])

    const columns = [
        {field: 'name', headerName: 'Subcontractor', flex: 1},
        {field: 'company', headerName: 'Company', valueGetter: (params) => params.row.company || '', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'phone', headerName: 'Phone Number', flex: 1},
        {field: 'tags', headerName: 'Tags', valueGetter: (params) => params.row.tags.toString().replaceAll(',', ', '), flex: 1}
    ]

    return(
        <PageLayout
            page='Subcontractors'
        >
            <ButtonBox>
                <Button
                    sx={{fontSize: '10px', my: 1}}
                    variant='contained'
                >
                    new
                </Button>
            </ButtonBox>
            <DataGridPro
                sx={{bgcolor: 'white'}}
                density="compact"
                columns={columns}
                rows={subs}
                autoPageSize
                autoHeight
                pageSize={50}
            />
        </PageLayout>
    )
}

export default Subcontractors;