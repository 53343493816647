import { Avatar, Box, Step, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./openProject";
import './style.css';

function Progress() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    
    let milestones = [...project.milestones];
    milestones = milestones.sort((a, b) => {
        let milestoneA = new Date(a.startDate).getTime();
        let milestoneB = new Date(b.startDate).getTime();
        if(milestoneA < milestoneB) return -1;
        if(milestoneA > milestoneB) return 1;
        return 0;
    });

    const steps = milestones.filter(milestone => !milestone.preProject).map(milestone => milestone.title);
    const [activeStep, setActiveStep] = useState(0);
    //const [milestones, setMilestones] = useState(project.milestones || []);

    useEffect(() => {
        const incompleteArr = milestones.filter(milestone => !milestone.complete && !milestone.preProject);
        console.log(incompleteArr);
        if(incompleteArr.length > 0) {
            let incomplete = incompleteArr[0].title;
            setActiveStep(steps.indexOf(incomplete));
        }

        else {
            setActiveStep(milestones.length - 1);
        }
        
    }, [])

    return(
        <Box sx={{width: '100%', px: 3, pt: 4, mb: 2}}>
            <svg width={0} height={0}>
                <linearGradient id="linearBlue" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor='rgb(72, 177, 234)' />
                    <stop offset={.5} stopColor="rgb(26, 138, 211)" />
                    <stop offset={1} stopColor="rgb(21, 74, 189)" />
                </linearGradient>
            </svg>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    return(
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={() => 
                                    <Avatar 
                                        alt={(index + 1).toString()}
                                        src='/'
                                        sx={{background: 'linear-gradient(rgb(72, 177, 234) 0%, rgb(26,38,211) 50%, rgb(21,74,189) 100%)', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}} 
                                    >
                                        
                                    </Avatar>
                                }
                            >{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    )
}

export default Progress;