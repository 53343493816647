import React, { useContext, useState } from "react";
import {ProjectContext} from './openProject';
import { Button, Grid, Typography, Divider, Box, Modal } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '80%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24
};


const Row = (props) => {
    const { children } = props;
    return(
        <>
            <Grid container>
                <Grid item xs={3} my='auto'>
                    <Typography variant='body2'>
                        { children[0] }
                    </Typography>
                </Grid>
                <Grid item xs={6} mb={1} my='auto'>
                    { children[1] }
                </Grid>
                <Grid item xs={3} my='auto'>
                    { children[2] }
                </Grid>
            </Grid>
            <Divider sx={{my: 1}} />
        </>
    )
}

function Documents() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const { documents } = project;
    const [open, setOpen] = useState(false);
    const [previewLink, setPreviewLink] = useState('')
    const handleOpen = (image) => {
        setPreviewLink(image);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setPreviewLink('');
    };

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, p: 3}}>
                    <img src={previewLink} style={{width: '100%'}} />
                </Box>
            </Modal>
            {documents.length > 0 &&
                documents.map(type => (
                    <Row key={type.type}>
                        <>{type.type}</>
                        <>
                            {type.url.map((link, index) => {
                                let name = link.name || new Date(parseInt(link.replace('https://tbconnectstorage.blob.core.windows.net/documents/', '').replace('.pdf', ''))).toLocaleString();

                                return (
                                    <div key={name}>
                                        <Button
                                            sx={{fontSize: '10px', py: 0}}
                                            onClick={() => handleOpen(link.reader || link.url || link)}
                                        > 
                                            {name}
                                        </Button>
                                        <br />
                                    </div>
                                )
                            })}
                        </>
                    </Row>
                ))
            }
        </>
    )
}

export default Documents;