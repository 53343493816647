import axios from "axios";
import React, { useEffect, useState } from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useNavigate } from 'react-router-dom';
import { Paper } from "@mui/material";


function CalendarView() {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);

    let colors = ['#5e97f6', '#db4437', '#f2a600', '#0f9d58', '#ab47bc', '#00acc1', '#ff7043', '#dc143c', '4b0082', '#fa8072']

    useEffect(() => {
        axios.get('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=projects').then(res => {
            console.log(res.data)
            setProjects(res.data.map((event, index) => {
                return {
                    title: event.name,
                    start: event.salesToOps.projectDetails.startDate,
                    end: event.salesToOps.projectDetails.endDate,
                    projectId: event.id,
                    backgroundColor: colors[index],
                    borderColor: colors[index]
                }
            }));
        })
    }, [])
    
    function redirect(eventInfo) {
        const { projectId } = eventInfo.event._def.extendedProps;
        navigate(`/projects/${projectId}`);
    }

    return(
        projects.length > 0 && 
            <Paper
                sx={{p: 3}}
            >
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    events={projects}
                    eventClick={(eventInfo) => redirect(eventInfo)}
                />
            </Paper>
        
    )
}

export default CalendarView;