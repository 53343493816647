import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ProjectContext } from "./openProject";
import { Typography, Button, Modal, Box, Divider, Table,  MenuItem } from "@mui/material";
import TableRow from "../../components/tableRow/tableRow";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback } from "react";
import style from "../../styles/modal";
import ButtonBox from "../../components/buttonBox/buttonBox";

function Subcontractor() {
    // const locationId = 'fc9c4963-2570-468c-81bc-b143b17c0f90'
    const location = localStorage.getItem('location');
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const [subcontractors, setSubcontractors] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setDocumentErrors([]);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);
    const [documentErrors, setDocumentErrors] = useState([]);
    const [subId, setSubId] = useState('');

    const getSubs = useCallback(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getByLocation?containerId=contacts&location=${location}`).then(res => {
            console.log(res.data.filter(contact => contact.type === 'Subcontractor'))
            // console.log(res.data.filter(contact => contact.type === 'Subcontractor'))
            setSubcontractors(res.data.filter(contact => contact.type === 'Subcontractor'))
        });
    }, [])

    useEffect(() => {
        getSubs();
    }, []);

    function selectSub(sub) {
        setSearchResults([]);
        setHasSearched(false);
        setDocumentErrors([]);
        setSubId(sub.id);
        let errors = checkExpirationDate(sub);
        console.log(errors);

        if(errors.length === 0) {
            setProject({
                ...project,
                subcontractor: sub
            })
        }

        else {
            setDocumentErrors(errors);
        }
    }

    function save() {
        console.log(project.subcontractor)
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            subcontractor: project.subcontractor
        }).catch(err => {
            alert('Oops! There was an error in saving subcontractor. Please try again.')
        }).then(res => {
            handleClose();
        });
    };

    function checkExpirationDate(newValue) {
        let errors = [];

        Object.keys(newValue.documents).forEach(doc => {
            let document = newValue.documents[doc];

            if(document.url.length === 0) {
                errors.push(`Subcontractor is missing ${document.name}`)
            }
            else if(document.name !== 'W9' && document.name !== 'Sub Agreement') {
                if(new Date().getTime() > new Date(document.exp).getTime()) {
                    errors.push(`Subcontractor's ${document.name} expired on ${new Date(document.exp).toLocaleDateString()}`)
                }
            }
        })

        return errors
    }

    const search = () => {
        setDocumentErrors([]);
        console.log(subcontractors.filter(obj => Object.keys(obj).some(key => obj[key] && obj[key]?.toString()?.toLowerCase().trim()?.includes(searchTerm.toLowerCase().trim()))))
        setHasSearched(true);
        // search all contacts in database for search value
        // update state
        setSearchResults(subcontractors.filter(obj => Object.keys(obj).some(key => obj[key] && obj[key]?.toString()?.toLowerCase().trim()?.includes(searchTerm.toLowerCase().trim()))));
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if(e.target.value.length === 0) {
            setDocumentErrors([]);
            setSearchResults([]);
            setHasSearched(false);
        } else {
            setHasSearched(false);
        }
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, maxWidth: '500px', width: '90%', p: 3}}>
                    <Typography variant='h5' mb={4}>Select a Subcontractor</Typography>

                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <InputBase
                            type='search'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                if(e.code === 'Enter') {
                                    search();
                                }
                            }}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search your subcontractors"
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>

                    {searchResults.length > 0 &&
                        <Box sx={{maxHeight: '500px', overflowY: 'auto', mt: 3}}>
                            {searchResults.map(sub => {
                                return (
                                    <MenuItem 
                                        onClick={() => selectSub(sub)}
                                        display='flex' 
                                        key={sub.id} 
                                        value={sub}
                                    >
                                        <Box sx={{flexGrow: 1}}>{sub.name}</Box>
                                        <i><small style={{marginLeft: '10px'}}>{sub.company || sub.organization.parentCompany || sub.organization}</small></i>
                                    </MenuItem>
                                )
                            })}
                        </Box>
                    }

                    {searchResults.length === 0 && hasSearched &&
                        <Typography variant='body2' sx={{textAlign: 'center', mt: 3}}>Oops! No results for this search, please try another search term</Typography>
                    }

                    {documentErrors.map(error => (
                        <Typography color='error' variant='body2' key={error}>{error}</Typography>
                    ))}

                    {documentErrors.length > 0 &&
                        <>
                            <Divider sx={{my: 2}} />
                            <Typography variant='body2'>Please select a different subcontractor or update Subcontractor documents in Transblue Connect here:</Typography>
                            <Typography 
                                mt={2}
                                variant='body2'
                                component='a'
                                href={`https://transblueconnect.com/contacts/${subId}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                https://transblueconnect.com/contacts/{subId}
                            </Typography>

                            <Typography mt={2} variant='body2'>
                                After updating documents, you can refresh this page and select this subcontractor
                            </Typography>
                        </>
                    }

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px'}}
                            onClick={handleClose}
                            color='error'
                            variant='contained'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            onClick={save}
                            color='success'
                            variant='contained'
                            disabled={documentErrors.length > 0 || !project.subcontractor}
                        >
                            save sub
                        </Button>
                    </ButtonBox>
                </Box>
            </Modal>
            {project.subcontractor
                ?   <>
                        <Paper sx={{width: '100%', height: '100%', borderRadius: '0px', p: 3}} elevation={0}>
                            <Typography variant='body2' sx={{color: '#767676', pb: 1}}>SUBCONTRACTOR INFO</Typography>
                                <Box sx={{border: '1px solid rgba(0,0,0, .12)', borderRadius: '5px', p: 1, mb: 2}}>
                                    <Typography variant='body2'>
                                        {project.subcontractor?.name}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {project.subcontractor?.email}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {project.subcontractor?.phone}
                                    </Typography>
                                </Box>
                                <Box sx={{height: '100px'}}>
                                    {Object.keys(project.subcontractor.documents).map(document => {
                                        let doc = project.subcontractor.documents[document];

                                        return (
                                            <>
                                            <a 
                                                href={doc.url} target='_blank'
                                                rel='noreferrer'
                                            >
                                                {doc.name}
                                            </a>
                                            <br />
                                            </>
                                        )
                                    })}
                                </Box>
                            <Button
                                sx={{fontSize: '10px', my: 2}}
                                variant='outlined'
                                fullWidth
                            >
                                send project agreement
                            </Button>
                            <Button
                                sx={{fontSize: '10px'}}
                                fullWidth
                                onClick={() => setOpen(true)}
                                variant='contained'
                            >
                                change sub
                            </Button>
                        </Paper>
                        
                    </>
                :   <Paper sx={{width: '100%', height: '100%', borderRadius: '0px', p: 3, height: '300px'}} elevation={0}>
                        <Typography variant='body2' sx={{color: '#767676', pb: 3}}>SUBCONTRACTOR INFO</Typography>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='outlined'
                            fullWidth
                            onClick={handleOpen}
                        >
                            assign sub
                        </Button>
                    </Paper>
                }
        </>
    )
}

export default Subcontractor;