import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import AppBar from '@mui/material/AppBar';
import { ChatBubbleOutline, Logout } from '@mui/icons-material';

import PageLayoutLogic from './pageLayoutLogic';
import logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';

export default function PageLayout(props) {
    const navigate = useNavigate()
    const { page } = props;
    const [location, setLocation] = React.useState(localStorage.getItem('location'));
    const [open, setOpen] = useState(false);

    const {
        DrawerHeader,
        navLinks,
        logout
    } = PageLayoutLogic();

    const { children } = props;

    useEffect(() => {
        let account = localStorage.getItem('account');
        if(!account) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        // force user to relogin after 24 hours
        let lastLoginTime = localStorage.getItem('lastLoginTime');
        let currentTime = new Date().getTime();
        if(currentTime - lastLoginTime > 86400000 || !lastLoginTime) {
            navigate('/')
        }
    }, []);

    function toggleLocation() {
        if(location === 'Bellevue') {
            localStorage.setItem('locationId', 'db1a045c-7d60-4c2a-95fc-4e616bcb94a5');
            localStorage.setItem('location', 'Ballard');
            localStorage.setItem('costguideId', 'd156fffe-35f6-4fe2-891d-7185954a24dd');
            setLocation('Ballard')
        }

        else {
            localStorage.setItem('locationId', '3b3cdf06-950a-4e66-8343-60eacec1a7cc');
            localStorage.setItem('costguideId', 'f82d833b-fcdd-4a44-acf3-aa7fb90edeb7');
            localStorage.setItem('location', 'Bellevue');
            setLocation('Bellevue')
        }
    }

    const ListComp = () => {
        return (
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <List>
                    {navLinks.map((link, index) => (
                        <ListItemButton
                            component='a'
                            href={link.href}
                            key={link.text}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5
                            }}
                        >
                            <ListItemIcon
                                sx={page === link.text ? {
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#ed6a22'
                                } : {
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {link.icon}
                            </ListItemIcon>
                            <ListItemText primary={link.text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        )
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          }
      
        setOpen(open);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <SwipeableDrawer
                anchor='left'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <DrawerHeader>
                    <img src={logo} alt='' style={{display: 'block', margin: '0 auto', width: '50%'}} />
                </DrawerHeader>
                <ListComp />
            </SwipeableDrawer>
            <AppBar position="fixed" sx={{bgcolor: '#ed6a22'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}
                        sx={{
                            marginRight: {xs: 1, lg: 5, md: 3}
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Projects Alpha v1
                    </Typography>

                    {(location === 'Bellevue' || location === 'Ballard') && 
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={toggleLocation}
                        >
                            Switch to {location === 'Bellevue' ? 'ballard' : 'bellevue'}
                        </Button>
                    }

                    <Button
                        sx={{bgcolor: 'white', color: 'red', display: {xs: 'none', md: 'block'}}} 
                        variant='contained'
                        component='a'
                        target='_blank'
                        href='https://app.smartsheet.com/b/form/f5fbb266e09141e2a6b35dbf89dee746'
                    >
                        FEEDBACK!!!
                    </Button>
                    <IconButton
                        component='a'
                        target='_blank'
                        href='https://app.smartsheet.com/b/form/f5fbb266e09141e2a6b35dbf89dee746'
                        sx={{color: 'white', display: {xs: 'block', md: 'none'}}}
                    >
                        <ChatBubbleOutline />
                    </IconButton>
                    <Button 
                        color="inherit" 
                        onClick={logout} 
                        sx={{display: {xs: 'none', md: 'block'}}}
                    >
                        Logout
                    </Button>
                    <IconButton
                        onClick={logout}
                        sx={{display: {xs: 'block', md: 'none'}, color: 'white'}}
                    >
                        <Logout />
                    </IconButton>
                </Toolbar>
            </AppBar>            
            <Box component="main" sx={{ flexGrow: 1, p: 2, bgcolor: '#f5f5f5', minHeight: '100vh', borderLeft: '1px solid #e4e5e7' }}>
                <DrawerHeader />
                { children }
            </Box>
        </Box>
    );
}