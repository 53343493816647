import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from './openProject';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { Box, Modal, Typography, Grid, TextField, ToggleButton, ToggleButtonGroup, Select, FormControl, InputLabel, MenuItem, Autocomplete, Tooltip } from '@mui/material';
import style from '../../styles/modal';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import ButtonBox from '../../components/buttonBox/buttonBox';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Chart from 'react-google-charts';

import days from '../templates/days';
import { Check, CheckCircle, Edit } from '@mui/icons-material';

function NewMilestones() {
    const locationId = localStorage.getItem('locationId');
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const { salesToOps } = project;
    const [ganttRows, setGanttRows] = useState([]);
    const [projectTemplates, setProjectTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('No Template');
    const [originalMilestones, setOriginalMilestones] = useState(project.milestones || []);
    const [editOn, setEditOn] = useState(false);
    const [activeMilestone, setActiveMilestone] = useState({});
    const handleEditOpen = (params) => {
        setEditOn(true);
        setActiveMilestone(params.row);
    }

    let newTaskRow = {
        title: 'New Task'
    }

    const [view, setView] = useState('grid');
    
    const [milestones, setMilestones] = useState(project.milestones || []);
    const [rows, setRows] = useState([]);

    const [taskOpen, setTaskOpen] = useState(false);
    const [newMilestone, setNewMilestone] = useState({
        startDate: new Date(),
        duration: 1,
        endDate: new Date()
    });

    const [dependencyOpen, setDependencyOpen] = useState(false);
    const handleOpenDependency = (milestone) => {
        setNewDependency({
            ...newDependency,
            dependency: milestone.dependency
        });

        setDependencyOpen(true);
    }
    const [newDependency, setNewDependency] = useState({
        startDate: new Date(),
        duration: 1,
        endDate: new Date()
    });

    useEffect(() => {
        let arr = [];
        milestones.forEach(milestone => {
            arr.push(milestone);

            // only add a dependency row if previous milestone is not a pre project milestone from the sales to ops form
            if(!milestone.preProject) {
                let id = uuidv4();
                let newDependencyRow = {
                    title: 'New Dependency',
                    dependency: milestone.title,
                    id: id
                }

                if(milestone.dependencies) {
                    arr = [...arr, ...milestone.dependencies.map(dep => {
                        return {
                            ...dep,
                            resource: milestone.title
                        }
                    }), newDependencyRow]
                    
                }

                else {
                    arr = [...arr, newDependencyRow]
                }
           }
        });

        newTaskRow.id = uuidv4();
        arr.push(newTaskRow);

        setRows(arr);
    }, [milestones]);

    const columns = [
        {
            field: 'title', 
            headerName: 'Title', 
            flex: 1, 
            renderCell: (params) => {
                console.log(params);
            if(params.row.title === 'New Task') {
                return(
                    <Button
                        sx={{fontSize: '10px'}}
                        startIcon={
                            <Add sx={{fontSize: '10px'}} />
                        }
                        onClick={() => setTaskOpen(true)}
                    >
                        New milestone
                    </Button>
                )
            }

            else if(params.row.title === 'New Dependency') {
                return(
                    <Button
                        sx={{fontSize: '10px', ml: 3}}
                        startIcon={
                            <Add sx={{fontSize: '10px'}} />
                        }
                        onClick={() => handleOpenDependency(params.row)}
                    >
                        New dependency
                    </Button>
                )
            }

            else if(!params.row.dependency) {
                return <b>{params.row.title}</b>
            }

            else {
                return (
                    <Typography variant='body2' sx={{pl: 3}}>
                        ⤷ {params.row.title}
                    </Typography>
                )
            }
        }},
        {
            field: 'duration', 
            headerName: 'Duration', 
            flex: .5,
            valueGetter: (params) => params.row.duration ? `${params.row.duration} days` : ''
        },
        {
            field: 'startDate', 
            headerName: 'Start Date', 
            flex: .5, 
            valueGetter: (params) =>  params.row.startDate ? new Date(params.row.startDate).toLocaleDateString() : ''
        },
        {field: 'endDate', headerName: 'End', flex: .5, valueGetter: (params) => params.row.endDate ? new Date(params.row.endDate).toLocaleDateString() : ''},
        {
            field: 'actions',
            renderCell: (params) => (
                params.row.title === 'New Dependency' || params.row.title === 'New Task'
                    ?   ''
                    :   <><GridActionsCellItem icon={<Edit />} onClick={() => handleEditOpen(params)} />
                    <GridActionsCellItem 
                        icon={params.row.complete ? <CheckCircle color='success' /> : <Tooltip title='Mark milestone complete'><Check /></Tooltip> } onClick={() => markComplete(params)} /></>
            )
        }
    ];

    function markComplete(params) {
        let arr = milestones.map(milestone => {
            if(milestone.id === params.row.id) {
                return {
                    ...milestone,
                    complete: true
                }
            }
            else if(milestone.dependencies) {
                let depArr = milestone.dependencies.map(dependency => {
                    if(dependency.id === params.row.id) {
                        return {
                            ...dependency,
                            complete: true
                        }
                    }
                    return dependency
                })

                return {
                    ...milestone,
                    dependencies: depArr
                }
            }

            return milestone
        })

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            milestones: arr
        }).then(res => {
            if(res.statusText === 'OK') {
                setProject({
                    ...project,
                    milestones: [...arr]
                })  
                setMilestones(arr) 
            }
            else {
                alert('Oops! Something went wrong, please try again or submit a feedback ticket')
            }
        })
    }

    const options = {
        height: 400,
        gantt: {
            trackHeight: 30,
            percentStyle: { fill: "#000000" },
            percentEnabled: true,
            criticalPathEnabled: true,
            criticalPathStyle: {
                stroke: "#e64a19",
                strokeWidth: 5,
            },
        }
    };

    const ganttColumns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];

    useEffect(() => {
        let arr = rows.filter(row => row.title !== 'New Dependency' && row.title !== 'New Milestone' && row.title !== 'New Task' && !row.preProject && row.startDate && row.endDate).map(row => {
            if(row.dependency) {
                return [
                    row.id,
                    row.title,
                    row.title,
                    new Date(row.startDate),
                    new Date(row.endDate),
                    null,
                    row.percentComplete || 0,
                    row.dependency
                ]
            }

            return [
                row.id,
                row.title,
                row.title,
                new Date(row.startDate),
                new Date(row.endDate),
                null,
                row.percentComplete || 0,
                null
            ]
        });

        setGanttRows(arr);
    }, [rows])

    function handleDurationChange(numDays) {
        let end = addWeekDays(newMilestone.startDate, numDays);

        setNewMilestone({
            ...newMilestone,
            duration: numDays,
            endDate: end
        })
    }

    function handleDependencyDuration(numDays) {
        let end = addWeekDays(newDependency.startDate, numDays);

        setNewDependency({
            ...newDependency,
            duration: numDays,
            endDate: end
        })
    }

    function handleDateChange(newValue, period, field) {
        console.log(newValue)
        if(field === 'milestone') {
            period === 'start'
            ? setNewMilestone({
                ...newMilestone,
                startDate: newValue
            })
            : setNewMilestone({
                ...newMilestone,
                endDate: newValue
            })
        }

        else {
            period === 'start'
            ? setNewDependency({
                ...newDependency,
                startDate: newValue
            })
            : setNewDependency({
                ...newDependency,
                endDate: newValue
            })
        }
    }

    function saveMilestone() {
        console.log(newMilestone)
        let uuid = uuidv4();
        newMilestone.id = uuid;
        newMilestone.created = new Date().getTime();

        let arr = [...milestones, newMilestone];
        arr = arr.sort((a, b) => {
            let milestoneA = new Date(a.startDate).getTime();
            let milestoneB = new Date(b.startDate).getTime();
            if(milestoneA < milestoneB) return -1;
            if(milestoneA > milestoneB) return 1;
            return 0;
        });

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            milestones: arr
        }).catch(err => {
            alert('Oops! There was an error. Please try again')
        }).then(res => {
            setProject({
                ...project,
                milestones: arr
            });
            setTaskOpen(false);
            setMilestones(arr);
        })
    }

    function saveDependency() {
        console.log(newDependency);
        newDependency.id = uuidv4();

        let arr = milestones.map(milestone => {
            if(milestone.title === newDependency.dependency) {
                newDependency.dependency = milestone.id;

                let dependencies;

                if(milestone.dependencies) {
                    dependencies = [newDependency, ...milestone.dependencies]
                
                    dependencies = dependencies.sort((a, b) => {
                        let milestoneA = new Date(a.startDate).getTime();
                        let milestoneB = new Date(b.startDate).getTime();
                        if(milestoneA < milestoneB) return -1;
                        if(milestoneA > milestoneB) return 1;
                        return 0;
                    })
                }

                else {
                    dependencies = [newDependency]
                }

                return {
                    ...milestone,
                    dependencies: dependencies
                }
            }

            return milestone
        });

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            milestones: arr
        }).catch(err => {
            alert('Oops! There was an error. Please try again')
        }).then(res => {
            setProject({
                ...project,
                milestones: arr
            });
            setDependencyOpen(false);
            setMilestones(arr);
        })
    }

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getById?containerId=franchises&id=${locationId}`).then(res => {
            if(res.data[0].projectTemplates) {
                let arr = res.data[0].projectTemplates;
                setProjectTemplates(arr);

                if(project.selectedTemplate) {
                    let templateTitle = arr.filter(template => template.id === project.selectedTemplate)[0].title;
                    setSelectedTemplate(templateTitle)
                }
            }
        })
    }, []);

    const addWeekDays = (startDate, count) => {
        startDate = new Date(startDate)
        return Array.from({ length: count }).reduce(date => {
            date = new Date(date.setDate(date.getDate() + 1));
            if (date.getDay() % 6 === 0)
            date = new Date(date.setDate(date.getDate() + (date.getDay() / 6 + 1)));
            return date;
        }, startDate);
    }

    function useTemplate(e) {
        let templateId = 'No Template';  
        
        let milestoneArr = [];

        if(e.target.value === 'No Template') {
            milestoneArr = originalMilestones;
            setSelectedTemplate('No Template');            
        }

        else {
            templateId = projectTemplates.filter(template => template.title === e.target.value)[0].id;
            setSelectedTemplate(e.target.value);

            let numDays = 0;

            let arr = projectTemplates.filter(template => template.title === e.target.value)[0].milestones;

            arr.forEach(milestone => {
                let obj = {
                    title: milestone.title,
                    id: milestone.id,
                    startDate: addWeekDays(project.startDate, numDays),
                    duration: milestone.duration
                };

                // add milestone duration of days to beginning of project
                numDays += milestone.duration;
                obj.endDate = addWeekDays(project.startDate, numDays);

                // get start dates and end dates of milestone's dependencies relative to start of project
                if(milestone.dependencies) {
                    let depArr = [];

                    milestone.dependencies.forEach(dependency => {
                        let depObj = {
                            title: dependency.title,
                            id: dependency.id,
                            dependency: dependency.dependency,
                            startDate: addWeekDays(project.startDate, numDays),
                            duration: dependency.duration
                        }

                        numDays += dependency.duration;
                        depObj.endDate = addWeekDays(project.startDate, numDays)
                        depArr.push(depObj);
                    })

                    obj.dependencies = depArr;
                }


                // add milestone with new start and end days to milestones array
                milestoneArr.push(obj);
            });
        }

        setMilestones(milestoneArr);
        setProject({
            ...project,
            milestones: milestoneArr,
            selectedTemplate: templateId
        })

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            selectedTemplate: templateId,
            milestones: milestoneArr
        }).then(res => console.log(res))
    }

    function handleEditDate(newValue, inputField) {
        if(inputField === 'start') {
            let endDate = addWeekDays(newValue, activeMilestone.duration);
            console.log(endDate)

            setActiveMilestone({
                ...activeMilestone,
                startDate: newValue,
                endDate: endDate
            })            
        }

        else {
            setActiveMilestone({
                ...activeMilestone,
                endDate: newValue
            })
        }
    }

    function handleEditDuration(newValue) {

    }

    function updateMilestone() {
        let arr = milestones.map(milestone => {
            if(milestone.id === activeMilestone.id) {
                return activeMilestone
            }
            return milestone
        });

        setMilestones(arr);

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            milestones: arr
        }).then(res => {
            setEditOn(false);
        }).catch(err => {
            alert('Oops! There was an error updating milestone. Please try again')
        })
    };

    return(
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Modal
                open={dependencyOpen}
                onClose={() => setDependencyOpen(false)}
            >
                <Box sx={{...style, maxWidth: '600px', p: 3}}>
                    <Typography variant='h5' sx={{ mb: 3}}>Add a Dependency</Typography>
                    {/* <Typography variant='body1' sx={{mb: 2}}>{newDependency.dependency}</Typography> */}
                    <Grid container>
                        <Grid item xs={12} md={4} pr={1}>
                            <DesktopDatePicker
                                label="Select Start Date"
                                inputFormat="MM/dd/yyyy"
                                value={newDependency.startDate}
                                onChange={(newValue) => handleDateChange(newValue, 'start', 'dependency')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} pr={1}>
                            <Autocomplete
                                value={newDependency.duration}
                                options={days}
                                getOptionLabel={(option) => `${option} days`}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Duration" 
                                        size='small'
                                        helperText='Excludes weekends' 
                                        fullWidth />
                                    )}
                                onChange={(e, newValue) => handleDependencyDuration(newValue)} 
                                
                            />
                        </Grid>
                        <Grid item xs={12} md={4} pr={1}>
                            <DesktopDatePicker
                                label="Select End Date"
                                inputFormat="MM/dd/yyyy"
                                value={newDependency.endDate}
                                onChange={(newValue) => handleDateChange(newValue, 'end', 'dependency')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        value={newDependency.title}
                        onChange={(e) => setNewDependency({
                            ...newDependency,
                            title: e.target.value
                        })}
                        id='title'
                        fullWidth
                        size='small'
                        sx={{mt: 2}}
                        label='Dependency Title'
                    />
                    <ButtonBox>
                        <Button 
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={() => setDependencyOpen(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={saveDependency}
                            color='success'
                        >
                            save dependency
                        </Button>
                    </ButtonBox>
                    
                </Box>
            </Modal>
            <Modal
                open={taskOpen}
                onClose={() => setTaskOpen(false)}
            >
                <Box sx={{...style, maxWidth: '600px', p: 3}}>
                    <Typography variant='h5' sx={{ mb: 3}}>Add a Milestone</Typography>
                    <Grid container>
                        <Grid item xs={12} md={4} pr={1}>
                            <DesktopDatePicker
                                label="Select Start Date"
                                inputFormat="MM/dd/yyyy"
                                value={newMilestone.startDate}
                                onChange={(newValue) => handleDateChange(newValue, 'start', 'milestone')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} pr={1}>
                            <Autocomplete
                                value={newMilestone.duration}
                                options={days}
                                getOptionLabel={(option) => `${option} days`}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Duration" 
                                        size='small' 
                                        fullWidth 
                                        helperText='Excludes weekends'
                                    />
                                )}
                                onChange={(e, newValue) => handleDurationChange(newValue)} 
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DesktopDatePicker
                                label="Select End Date"
                                inputFormat="MM/dd/yyyy"
                                value={newMilestone.endDate}
                                onChange={(newValue) => handleDateChange(newValue, 'end', 'milestone')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        value={newMilestone.title}
                        onChange={(e) => setNewMilestone({
                            ...newMilestone,
                            title: e.target.value
                        })}
                        id='title'
                        fullWidth
                        size='small'
                        sx={{mt: 2}}
                        label='Milestone Title'
                    />
                    <ButtonBox>
                        <Button 
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={() => setTaskOpen(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={saveMilestone}
                            color='success'
                            
                        >
                            save milestone
                        </Button>
                    </ButtonBox>
                    
                </Box>
            </Modal>
            <Modal
                open={editOn}
                onClose={() => setEditOn(false)}
            >
                <Box sx={{...style, maxWidth: '500px', p: 3}}>
                    <Typography variant='h5' sx={{ mb: 3}}>Edit {activeMilestone.title}</Typography>
                    <Grid container>
                        <Grid item xs={12} md={4} pr={1}>
                            <DesktopDatePicker
                                label="Select Start Date"
                                inputFormat="MM/dd/yyyy"
                                value={activeMilestone.startDate}
                                onChange={(newValue) => handleEditDate(newValue, 'start', 'milestone')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} pr={1}>
                            <Autocomplete
                                value={activeMilestone.duration}
                                options={days}
                                getOptionLabel={(option) => `${option} days`}
                                renderInput={(params) => <TextField {...params} label="Duration" size='small' fullWidth />}
                                onChange={(e, newValue) => handleEditDuration(newValue)} 
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DesktopDatePicker
                                label="Select End Date"
                                inputFormat="MM/dd/yyyy"
                                value={activeMilestone.endDate}
                                onChange={(newValue) => handleEditDate(newValue, 'end', 'milestone')}
                                renderInput={(params) => <TextField {...params} size='small'  fullWidth />}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        value={activeMilestone.title}
                        onChange={(e) => setActiveMilestone({
                            ...activeMilestone,
                            title: e.target.value
                        })}
                        id='title'
                        fullWidth
                        size='small'
                        sx={{mt: 2}}
                        label='Milestone Title'
                    />
                    <ButtonBox>
                        <Button 
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            onClick={() => setEditOn(false)}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={updateMilestone}
                            color='success'
                            
                        >
                            save milestone
                        </Button>
                    </ButtonBox>
                    
                </Box>
            </Modal>

            
            <Grid container>
                <Grid item md={6}>
                    {projectTemplates.length > 0 &&
                        <FormControl fullWidth size='small'>
                            <InputLabel id="template-select-label">Select Project Template</InputLabel>
                            <Select
                                labelId="template-select-label"
                                id="template-select"
                                value={selectedTemplate}
                                label="Select Project Template"
                                onChange={useTemplate}
                            >
                                <MenuItem value='No Template' id='No Template'>No Template</MenuItem>
                                {projectTemplates.map(template => {
                                    return (
                                        <MenuItem 
                                            value={template.title} 
                                            key={template.title}
                                        >
                                            {template.title}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    }
                </Grid>
                <Grid item md={6}>
                    <Box sx={{width: '100%', textAlign: 'right'}}>
                        <ToggleButtonGroup
                            size='small'
                            value={view}
                            onChange={(e) => setView(e.target.value)}
                        >
                            <ToggleButton
                                value='gantt'
                            >
                                Gantt
                            </ToggleButton>
                            <ToggleButton
                                value='grid'
                            >
                                list
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
            </Grid>

            {view === 'grid' &&
                <div style={{marginTop: '20px', width: '100%'}}>
                    <DataGridPro
                        disableSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        density='compact'
                        autoHeight
                        autoPageSize
                    />
                </div>
            }
            {view === 'gantt' &&
                <Box>
                    {ganttRows.length > 0 && 
                        <div style={{height: 'calc(100vh - 100px)', marginTop: '20px'}}>
                            <Chart
                                chartType='Gantt'
                                width='100%'
                                height='100%'
                                data={[ganttColumns, ...ganttRows]}
                                options={options}
                                chartEvents={[
                                    {
                                        eventName: 'select',
                                        callback: ({ chartWrapper }) => {
                                            const chart = chartWrapper.getChart();
                                            const dataTable = chartWrapper.getDataTable();
                                            const selected = chart.getSelection();
                                            let row = selected[0].row;
                                            //handleOpen(milestones[row]);
                                        }
                                    }
                                ]}
                            />
                        </div>
                    }
                </Box>
            }
        </LocalizationProvider>
        </>
    )
}

export default NewMilestones;