import { Grid, Typography } from "@mui/material";
import { memo } from "react";

// const areEqual = (prevProps, nextProps) => {
//     console.log(prevProps.children[1]);
//     console.log(nextProps.children[1]);
//     // prevent gridrow from rerendering if children don't change
//     return prevProps.children[1].props.children === nextProps.children[1].props.children || prevProps.children[1].props.value === nextProps.children[1].props.value
// };

function GridRow({ children }) {
    return(
        <Grid container>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 'bold', letterSpacing: '.03em', fontSize: '13px'}}>
                    {children[0]}
                </Typography>
            </Grid>
            <Grid item xs={8} mb={1}>
                { children[1] }
            </Grid>
        </Grid>
    )
};

export default GridRow;