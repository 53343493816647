import React, { useEffect, useState } from "react";
import axios from "axios";
import PageLayout from "../../components/pageLayout/pageLayout";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal, Paper, Typography, Grid } from "@mui/material";
import style from "../../styles/modal";
import NewProjectForm from "../../forms/newProject";
import { AcUnit, AddRoad, CleaningServices, Fence, Deck, ElectricCar, Construction, AutoAwesome, Pool, Grass, CalendarMonth, Person, AttachMoney, AccessTime } from "@mui/icons-material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#3DBD32' : '#3DBD32',
  },
}));

function Projects() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const location = localStorage.getItem('location');
    let options = { style: 'currency', currency: 'USD' };

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    let iconStyle = {
        color: 'white',
        fontSize: '80px',
        borderRadius: '10px',
        padding: '10px',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: '-60px'
    }

    const icons = {
        deck: <Deck sx={{...iconStyle, bgcolor: '#D99B6A', boxShadow: '0px 5px 15px #D99B6A99'}} />,
        fence: <Fence sx={{...iconStyle, bgcolor: '#D99B6A', boxShadow: '0px 5px 15px #D99B6A99'}} />   ,
        asphalt: <AddRoad sx={{...iconStyle, bgcolor: '#444444', boxShadow: '0px 5px 15px rgba(0,0,0,0.6)'}} />,
        janitorial: <CleaningServices sx={{...iconStyle, bgcolor: '#ECB100', boxShadow: '0px 5px 15px #ECB10099'}} />,
        sweep: <CleaningServices sx={{...iconStyle, bgcolor: '#7B4EF3', boxShadow: '0px 5px 15px #7B4EF399'}} />,
        snow: <AcUnit sx={{...iconStyle, bgcolor: '#5FABDD', boxShadow: '0px 5px 15px #5FABDD99'}} />,
        electric: <ElectricCar sx={{...iconStyle, bgcolor: '#21CC62', boxShadow: '0px 5px 15px #21CC6299'}} />,
        green: <ElectricCar sx={{...iconStyle, bgcolor: '#21CC62', boxShadow: '0px 5px 15px #21CC6299'}} />,
        ev: <ElectricCar sx={{...iconStyle, bgcolor: '#21CC62', boxShadow: '0px 5px 15px #21CC6299'}} />,
        enhancement: <AutoAwesome sx={{...iconStyle, bgcolor: '#DC6466', boxShadow: '0px 5px 15px #DC646699'}} />,
        pool: <Pool sx={{...iconStyle, bgcolor: '#1E90FF', boxShadow: '0px 5px 15px #1E90FF99'}} />,
        landscape: <Grass sx={{...iconStyle, bgcolor: '#008000', boxShadow: '0px 5px 15px #00800099'}} />,
        landscaping: <Grass sx={{...iconStyle, bgcolor: '#008000', boxShadow: '0px 5px 15px #00800099'}} />
    };

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getbylocation?containerId=projects&location=${location}`)
            .then(res => {
                setProjects(res.data);
                setLoading(false);
            })
    }, []);

    const columns = [
        {field: 'name', headerName: 'Project Name', flex: 1},
        {
            field: 'projectManager', 
            headerName: 'Project Manager', 
            flex: .25, 
            valueGetter: (params) => params.row.salesToOps.projectManager?.name
        },
        {
            field: 'subcontractor', 
            headerName: 'Subcontractor', 
            valueGetter: (params) => (
                params.row.subcontractor
                    ? params.row.subcontractor.name
                    : 'Unassigned'
            ), 
            flex: .25
        },
        {
            field: 'startDate', 
            headerName: 'Start Date', 
            flex: .25,
            valueGetter: (params) => new Date(params.row.salesToOps.projectDetails.startDate).toLocaleDateString()
        },
        {
            field: 'endDate',
            headerName: 'End Date', 
            flex: .25,
            valueGetter: (params) => new Date(params.row.salesToOps.projectDetails.endDate).toLocaleDateString()
        },
        {
            field: 'bidTotal', 
            headerName: 'Project Value', 
            flex: .25, 
            valueGetter: (params) => {
                if(params.row.bidTotal) {
                    return new Intl.NumberFormat('en-US', options).format(params.row.bidTotal)
                }

                else {
                    let project = params.row;
                    if(Object.keys(project).length > 0) {
                        if(project.estimates.length === 1) {
                            let total = project.estimates[0]?.workareas?.reduce((accum, item) => accum + item.price, 0);
                            return new Intl.NumberFormat('en-US', options).format(total)
                        }
                        else if(project.estimates.length > 1) {
                            let { estimates } = project;
                            if(estimates.filter(est => est.selected).length !== 1) {
                                //bidTotal = 'Select an estimate'
                                return 0
                            }
                            else {
                                let est = estimates.filter(est => est.selected)[0];
                                let total = est?.workareas?.reduce((accum, item) => accum + item.price, 0);
                                return new Intl.NumberFormat('en-US', options).format(total);
                            }
                        }
                    }
                }
            }
        },
        {
            field: 'percentComplete', 
            headerName: '% Complete', 
            flex: .25,
            valueGetter: (params) => params.row.percentComplete ? `${params.row.percentComplete} %` : '0 %'
        }
    ]

    const redirect = (id) => {
        navigate(`/projects/${id}`)
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, width: '90%', maxWidth: '600px', p: 3}}>
                   <NewProjectForm handleClose={handleClose} />
                </Box>
            </Modal>
            <PageLayout
                page='Projects'
            >
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant='contained'
                        sx={{fontSize: '10px'}}
                        onClick={handleOpen}
                    >
                        create project
                    </Button>
                </Box>
                <DataGridPro
                    sx={{bgcolor: 'white', mt: 2, display: {xs: 'none', md: 'block'}}}
                    columns={columns}
                    rows={projects}
                    loading={loading}
                    disableSelectionOnClick
                    onRowClick={(params) => redirect(params.row.id)}
                    density='compact'
                    pagination
                    pageSize={50}
                    autoHeight
                    autoPageSize
                />
                <Box sx={{display: {sx: 'block', md: 'none'}}}>
                    {projects.map(project => {
                        let icon = <Construction />
                        let arr = Object.keys(icons);
                        arr.forEach((item) => {
                            if(project.division.toLowerCase().indexOf(item) > -1) {
                                icon = icons[item]
                            }
                        })

                        let total = new Date(project.salesToOps.projectDetails.endDate).getTime() - new Date(project.salesToOps.projectDetails.startDate).getTime();

                        let remaining = (new Date(project.salesToOps.projectDetails.endDate).getTime() - new Date().getTime());

                        let status = 'Not Started';

                        let completed = new Date().getTime() - new Date(project.salesToOps.projectDetails.startDate).getTime();

                        let progress = Math.floor((completed / total) * 100);
                    
                        if(progress > 100) {
                            progress = 100;
                            status = 'Completed'
                        }

                        else if(progress < 0) {
                            progress = 0
                        }

                        else {
                            remaining = Math.floor(remaining / 1000 / 60 / 60 / 24);
                            status = `${remaining} Days Left`
                        }

                        return (
                            <Paper 
                                sx={{pt: 3, px: 4, my: 6, borderRadius: '10px'}}
                                key={project.id}
                            >
                                
                                <Box 
                                    sx={{position: 'relative', border: '0px', bgcolor: 'transparent', pb: 3}}
                                    component='button'
                                    onClick={() => redirect(project.id)}
                                >
                                    {icon}
                                    <Typography variant='body2' sx={{fontSize: '20px', width: '100%', textAlign: 'center', fontWeight: 'bold', mt: 6}}>{project.name}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '13px', width: '100%', textAlign: 'center', color: '#7a7a7a', mt: 1}}>{project.division}</Typography>

                                    <Box sx={{mt: 4}}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography variant='body1' fontWeight='bold' mb={1} sx={{textAlign: 'left'}}>Progress</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={{textAlign: 'right', width: '100%'}}>
                                                    <Typography variant='body2' fontWeight='bold'>
                                                        {progress} %
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <BorderLinearProgress variant="determinate" value={progress} />
                                    </Box>
                                    
                                    <Box sx={{justifyContent: 'flex-end', display: 'flex', width: '100%', mt: 3}}>
                                        {status === 'Not Started' && 
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #ADADAD', 
                                                    color: '#ADADAD', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#ADADAD21'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }

                                        {status === 'Completed' && 
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #27E218', 
                                                    color: '#27E218', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#27e21821'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }
                                        {status !== 'Completed' && status !== 'Not Started' &&
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #E2B502', 
                                                    color: '#E2B502', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#E2B50221'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Paper>
                        )
                    })}
                </Box>
            </PageLayout>
        </>
    )
}

export default Projects;