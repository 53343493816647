import { Button, ImageList, ImageListItem, ImageListItemBar, TextField, IconButton, Modal, Box, Typography, Grid, ListItem, ListItemText, ListItemAvatar, Avatar, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import {ProjectContext} from './openProject';
import CKEditor from "ckeditor4-react";
import ButtonBox from "../../components/buttonBox/buttonBox";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { DataGridPro } from "@mui/x-data-grid-pro";
import toArrayBuffer from 'to-array-buffer';
import { Clear, Note, Notes, Send } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import style from "../../styles/modal";
import checkToken from "../../utils/checkToken";

const { BlobServiceClient } = require('@azure/storage-blob');

function DailyLog() {
    const user = localStorage.getItem('user');
    const blobSasUrl = 'https://tbconnectstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-01-24T01:54:35Z&st=2023-01-23T17:54:35Z&spr=https&sig=LPkdkk9v3%2Bt8P01xUPfYVHFirO7A6wUWVTdiKK1N%2F4Y%3D';
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const [logEntries, setLogEntries] = useState(project.logEntries || []);
    const [displayForm, setDisplayForm] = useState(false);
    const [currentEntry, setCurrentEntry] = useState({
        date: new Date(),
        notes: '',
        images: [],
        title: '',
        user: user
    });
    const [activeEntry, setActiveEntry] = useState({
        notes: '',
        title: '',
        date: '',
        images: []
    });
    const [open, setOpen] = useState(false);
    const handleOpen = (entry) => {
        setActiveEntry(entry);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setActiveEntry({
            notes: '',
            title: '',
            date: '',
            images: []
        });
    }

    const [displayAddlNotes, setDisplayAddlNotes] = useState(false);
    const [addlNote, setAddlNote] = useState('');

    function saveEntry() {
        // save images to azure blob storage
        // update images in current entry to just their url
        if(currentEntry.images.length > 0) {
            const containerName = 'projectimages';
            const containerClient = blobServiceClient.getContainerClient(containerName);
            let uuid = uuidv4();
           
            let urlArr = currentEntry.images.map(file => {
                if(file.name) {
                    return `https://tbconnectstorage.blob.core.windows.net/projectimages/${uuid}${file.name}`
                }
                return file
            });

            currentEntry.images.forEach(file => {
                if(file.name) {
                    const blockBlobClient = containerClient.getBlockBlobClient(`${uuid}${file.name}`);
                    blockBlobClient.uploadBrowserData(file.buffer);
                }
            })

            currentEntry.images = urlArr;
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            logEntries: [currentEntry, ...logEntries]
        }).catch(err => {
            alert('Oops! There was an error saving daily log, please try again')
        }).then(res => {
            // update list of log entries locally
            setLogEntries([currentEntry, ...logEntries]);

            // reset form values
            setCurrentEntry({
                date: new Date(),
                notes: 'Add notes...',
                title: '',
                images: []
            })

            // hide the form
            setDisplayForm(false);

            // update project context
            setProject({
                ...project,
                logEntries: [currentEntry, ...logEntries]
            });
        })
    }

    function uploadFiles(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);
        let tempArr = [];

        if(currentEntry.images) {
            tempArr = [...currentEntry.images]
        }

        files.forEach((file, index) => {
            let reader = new FileReader();

            reader.onload = async () => {
                var arrBuff = await toArrayBuffer(reader.result);
                tempArr.push({name: file.name, buffer: arrBuff, src: reader.result})

                if(index === files.length - 1) {
                    setCurrentEntry({
                        ...currentEntry,
                        images: tempArr
                    })
                }
            }

            reader.readAsDataURL(file);
        })
    }

    const columns = [
        {field: 'date', headerName: 'Date', flex: .25, valueGetter: (params) => new Date(params.row.date).toLocaleDateString()},
        {field: 'title', headerName: 'Title', flex: 1},
        {field: 'sentToClient', headerName: 'Sent to Client', valueGetter: (params) => params.row.sentToClient ? 'Yes' : 'No', flex: .25}
    ]

    function removeImage(index) {
        let tempArr = currentEntry.images;
        tempArr.splice(index, 1);
        setCurrentEntry({
            ...currentEntry,
            images: tempArr
        })
    }

    function updateEntry() {
        let addlNotes = activeEntry.addlNotes || [];
        let arr = logEntries.map(entry => {
            if(entry.date === activeEntry.date) {
                return {
                    ...activeEntry,
                    addlNotes: [{
                        note: addlNote,
                        date: new Date(),
                        user: localStorage.getItem('user')
                    }, ...addlNotes]
                }
            }
            return entry
        })

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            logEntries: arr
        }).catch(err => {
            alert('Oops! There was an error saving daily log, please try again')
        }).then(res => {
            setLogEntries(arr);
            setProject({
                ...project,
                logEntries: arr
            });
            setDisplayAddlNotes(false);
            setActiveEntry({
                ...activeEntry,
                addlNotes:  [{
                    note: addlNote,
                    date: new Date(),
                    user: localStorage.getItem('user')
                }, ...addlNotes]
            })
            //handleClose();
        })
    }

    async function sendToClient(entry, source) {
        const token = await checkToken();

        let images = ``;

        if(entry.images.length > 0) {
            entry.images.forEach(image => {
                images += `<img src=${image.src || image} style="width: 200px" />`
            });
        }

        let email = {
            "message": {
                "subject": entry.title,
                "body": {
                    "contentType": "HTML",
                    "content": `${entry.notes} <br /> ${images}`
                },
                "toRecipients": [
                    {
                        "emailAddress": {
                        "address": project.contact.email
                        //"address": "carters@transblue.org"
                        }
                    }
                ]
            }
        }

        axios.post('https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/me/sendMail', email, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).catch(err => {
            alert(err)
        }).then(() => {
            handleClose();
            alert('Daily log successfully sent to client!');
            if(source === 'currentEntry') {
                saveEntry();
            }

            axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
                sentToClient: true
            })
        })
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, width: '90%', maxWidth: '500px', p: 3, maxHeight: '90vh', overflowY: 'auto', bgcolor: '#fafafa'}}>

                    <Box sx={{bgcolor: 'white', p: 3}}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant='h5'>{activeEntry.title}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body2' sx={{textAlign: 'right'}}>
                                {new Date(activeEntry.date).toLocaleDateString()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{width: '100%', mt: 2, border: '1px solid #e0e0e0', borderRadius: '5px', p: 1, px: 2}}>
                            <Typography variant='body2' dangerouslySetInnerHTML={{__html: activeEntry.notes}}>
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{bgcolor: 'white', px: 3, py: 1, mt: 2}}>
                        {activeEntry.images && activeEntry.images.length > 0 &&
                            <ImageList sx={{width: '100%'}} cols={4} rowHeight={164}>
                                {activeEntry.images.map((image, index) => (
                                    <ImageListItem
                                        key={image.name}
                                    >
                                        <img 
                                            src={image.src || image} 
                                            srcSet={image.src || image}
                                            loading='lazy'
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        }
                    </Box>

                    <ButtonBox>
                        <Tooltip title='Add internal notes. These will not be sent to client'>
                            <Button
                                sx={{fontSize: '10px', border: '1px solid #e0e0e0'}}
                                endIcon={<Notes /> }
                                onClick={() => setDisplayAddlNotes(true)}
                            >
                                add notes
                            </Button>
                        </Tooltip>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={() => sendToClient(activeEntry)}
                            endIcon={<Send /> }
                        >
                            send to client
                        </Button>
                    </ButtonBox>
                
                    <Box sx={{bgcolor: 'white', mt: 2}}>
                        {displayAddlNotes &&
                            <>
                                <TextField
                                    sx={{mt: 2}}
                                    value={addlNote}
                                    onChange={(e) => setAddlNote(e.target.value)}
                                    fullWidth
                                    multiline
                                    label='Additional Notes'
                                />
                                <ButtonBox>
                                    <Button
                                        sx={{fontSize: '10px'}}
                                        onClick={() => setDisplayAddlNotes(false)}
                                        color='error'
                                        variant='contained'
                                    >
                                        cancel
                                    </Button>
                                    <Button
                                        sx={{fontSize: '10px', ml: 1}}
                                        onClick={updateEntry}
                                        color='success'
                                        variant='contained'
                                    >
                                        save note
                                    </Button>
                                </ButtonBox>
                            </>
                        
                        }
                        {activeEntry.addlNotes &&
                            activeEntry.addlNotes.map(note => (
                                <ListItem alignItems='flex-start' key={note.date}>
                                    <ListItemAvatar>
                                        <Avatar alt={note.user} src='../../images' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={new Date(note.date).toLocaleString()}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {note.user}
                                                </Typography>
                                                {` — ${note.note}`}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))
                        }
                    </Box>
                </Box>
            </Modal>

            {!displayForm &&
                <Button 
                    sx={{fontSize: '10px'}}
                    variant='contained'
                    onClick={() => setDisplayForm(true)}
                >
                    add entry
                </Button>
            }
            {displayForm &&
                <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            label='Date'
                            sx={{mb: 3}}
                            value={currentEntry.date}
                            renderInput={(params) => <TextField {...params} size='small'  fullWidth sx={{mb:2}} />}
                            onChange={(e) => setCurrentEntry({
                                ...currentEntry,
                                date: e.target.value
                            })}
                        />
                    </LocalizationProvider>

                    <TextField
                        fullWidth
                        sx={{mb: 2}}
                        label='Title'
                        size='small'
                        value={currentEntry.title || ''}
                        onChange={(e) => setCurrentEntry({
                            ...currentEntry,
                            title: e.target.value
                        })}
                    />

                    <TextField
                        value={currentEntry.notes}
                        onChange={(e) => setCurrentEntry({
                            ...currentEntry,
                            notes: e.target.value
                        })}
                        fullWidth
                        multiline
                        minRows={4}
                        label='Add Notes'
                    />

                    <Button
                        sx={{fontSize: '10px', mt: 2}}
                        variant='contained'
                        component='label'
                    >
                        upload images
                        <input type='file' hidden multiple onChange={uploadFiles} />
                    </Button>
                    
                    {currentEntry.images.length > 0 &&
                        <ImageList sx={{width: '100%'}} cols={4} rowHeight={164}>
                            {currentEntry.images.map((image, index) => (
                                <ImageListItem
                                    key={image.name}
                                >
                                    <img 
                                        src={image.src} 
                                        srcSet={image.src}
                                        loading='lazy'
                                    />
                                    <ImageListItemBar
                                        sx={{
                                            background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                        }}
                                        position="top"
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'white' }}
                                                onClick={() => removeImage(index, 'prev images')}
                                                >
                                                <Clear />
                                            </IconButton>
                                        }
                                        actionPosition="right"
                                    />

                                </ImageListItem>
                            ))}
                        </ImageList>
                    }

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            color='error'
                            onClick={() => setDisplayForm(false)}
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            color='success'
                            onClick={saveEntry}
                        >
                            save
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={() => sendToClient(currentEntry, 'currentEntry')}
                        >
                            send to client
                        </Button>
                    </ButtonBox>
                </>
            }
            <div style={{height: 'calc(100vh - 300px)', width: '100%', marginTop: '20px'}}>
                <DataGridPro
                    autoHeight
                    autoPageSize
                    columns={columns}
                    rows={logEntries}
                    disableSelectionOnClick
                    density="compact"
                    pageSize={50}
                    getRowId={(row) => row.date}
                    onRowClick={(params) => handleOpen(params.row)}
                />
            </div>
        </>
    )
}

export default DailyLog