import React, { useEffect, useState } from "react";
import axios from "axios";
import PageLayout from "../../components/pageLayout/pageLayout";
import { Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CalendarView from "./calendarView";
import GanttView from "./ganttView";

function Calendar() {
    const [view, setView] = useState('calendar');

    function toggleView(e) {
        setView(e.target.value);
    }

    const selectedStyle = {
        bgcolor: '#0b56a4 !important',
        color: 'white !important',
        fontSize: '11px'
    }

    const defaultStyle = {
        bgcolor: 'white !important',
        color: '#0b56a4 !important',
        fontSize: '11px'
    }

    return(
        <PageLayout
            page='Calendar'
        >
            <ToggleButtonGroup
                color='primary'
                value={view}
                exclusive
                onChange={toggleView}
                sx={{mb: 2}}
            >
                <ToggleButton
                    value='calendar'
                    size='small'
                    selected={view === 'calendar'}
                    sx={view === 'calendar' ? selectedStyle : defaultStyle}
                >
                    Calendar View
                </ToggleButton>
                <ToggleButton
                    value='gantt'
                    size='small'
                    selected={view === 'gantt'}
                    sx={view === 'gantt' ? selectedStyle : defaultStyle}
                >
                    Gantt View
                </ToggleButton>
            </ToggleButtonGroup>
            <Paper
                sx={{p: 3}}
            >
                {view === 'calendar'
                    ?   <CalendarView />
                    :   <GanttView />
                }
            </Paper>
        </PageLayout>
    )
}

export default Calendar;