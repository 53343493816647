import { CalendarMonth, Mail, Person, Phone, SignalCellularAlt } from "@mui/icons-material";
import { Avatar, Button, CardHeader, Grid, Step, StepLabel, Stepper, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import React, { createContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../components/pageLayout/pageLayout";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DailyLog from "./dailyLog";
import Documents from "./documents";
import CheckList from "./checklist";
import Progress from "./progress";
import Images from "./images";
import NewChangeOrder from "./changeOrder";
import ChecklistProgress from "./checklistProgress";
import NewHandoff from "./handoff/newHandoff";
import 'react-calendar/dist/Calendar.css';
import NewMilestones from "./newMilestones";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Subcontractor from "./subcontractor";
const options = { style: 'currency', currency: 'USD' };

export const ProjectContext = createContext({
    project: {},
    setProject: () => {},
    location: {},
    materialsArr: []
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{width: '95%'}}
    >
      {value === index && (
        <Box sx={{ px: 3, flexGrow: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function OpenProject() {
    const [project, setProject] = useState({});
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const [location, setLocation] = useState({});
    const [materialsArr, setMaterialsArr] = useState([]);
    const costguideId = localStorage.getItem('costguideId');
    const [bidTotal, setBidTotal] = useState(0);

    const getProject = useCallback(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getById?containerId=projects&id=${id}`)
            .then(res => {
                console.log(res.data)
                setProject(res.data[0]);

                // get location information from database
                axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getlocation?containerId=franchises&location=${res.data[0].location}`)
                    .then(res => {
                        console.log(res.data)
                        if(res.data !== 'No items found') {
                            setLocation(res.data[0])
                        }
                    })
            })
    }, []);

    useEffect(() => {
        if(Object.keys(project).length > 0) {
            if(project.estimates.length === 1) {
                let total = project.estimates[0]?.workareas?.reduce((accum, item) => accum + item.price, 0);
                setBidTotal(new Intl.NumberFormat('en-US', options).format(total))
            }
            else if(project.estimates.length > 1) {
                let { estimates } = project;
                if(estimates.filter(est => est.selected).length !== 1) {
                    setBidTotal(0)
                }
                else {
                    let est = estimates.filter(est => est.selected)[0];
                    let total = est?.workareas?.reduce((accum, item) => accum + item.price, 0);
                    setBidTotal(new Intl.NumberFormat('en-US', options).format(total));
                    
                }
            }
        }
    }, [project])

    useEffect(() => {
        getProject();
    }, [])

    useEffect(() => {
        // create first checklist if opens checklist tab after project has started and there are no checklists yet
        if(project.checklist && project.checklist.length === 0 && new Date().getTime() >= new Date(project.startDate).getTime()) {
            let newList = {
                date: new Date().toLocaleDateString(),
                onSite: false,
                client: false,
                sub: false,
                pictures: false,
                dailyLog: false,
            }

            axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
                checklist: [newList]
            })
        }
    }, []);

    const getCostguide = useCallback(() => {
        // get cost guide associated with location user belongs to
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getbyid?containerId=costguide&id=${costguideId}`)
            .then(res => {
                setMaterialsArr(res.data[0].materials);
                //setMargin(res.data[0].margin)
            });
    }, [costguideId]);

    useEffect(() => {
        getCostguide();
    }, [])

    // const obj = {
    //     startDate: '',
    //     endDate: '',
    //     milestones: '',
    //     notes: [],
    //     images: [],
    //     checklist: [],
    //     mediaRequests: [],
    //     subcontractor: '',
    //     payments: [
    //         {
    //             downPayment: {
    //                 invoiced: '',
    //                 received: '',
    //                 percent: project.salesToOps.downPayment
    //             }
    //         },
    //         {
    //             halfway: {
    //                 invoiced: '',
    //                 received: '',
    //                 percent: project.salesToOps.halfway
    //             }
    //         },
    //         {
    //             final: {
    //                 invoiced: '',
    //                 received: '',
    //                 percent: project.salesToOps.final
    //             }
    //         }
    //     ],
    //     statuses: [],
    //     currentStatus: '',
    //     percentCompleted: ''
    // }

    function handleChange(e, newValue) {
        setValue(newValue)
    }

    const componentList = [
        {
            title: 'Job Handoff',
            component: <NewHandoff value={0} />
            // icon: <Article />
        }, 
        {
            title: 'Milestones',
            component: <NewMilestones value={1} />
            // icon: <BarChart />
        },
        {
            title: 'Documents',
            component: <Documents value={2} />
            // icon: <Article />
        },
        {
            title: 'Daily Log',
            component: <DailyLog value={3} />
            // icon: <Article />
        },
        {
            title: 'Checklist',
            component: <CheckList value={4} />
            // icon: <CheckBox />
        },
        {
            title: 'Images',
            component: <Images value={5} />
            // icon: <Collections />
        },
        {
            title: 'Change Order',
            component: <NewChangeOrder value={6} />
            // icon: <PriceChange />
        }
    ]

    function changeComponents(title) {
        let index = componentList.map(comp => comp.title).indexOf(title);
        setValue(index)
    }

    function changeStartDate(newValue) {
        let obj = {
            ...project.salesToOps,
            projectDetails: {
                ...project.salesToOps.projectDetails,
                startDate: newValue
            }
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            salesToOps: obj
        }).then(res => {
            console.log(res)
            setProject({
                ...project,
                salesToOps: obj
            })
        }).catch(() => alert('Oops! There was an error updating project start date. Please try again or submit a feedback'))

    }

    function changeEndDate(newValue) {
        let obj = {
            ...project.salesToOps,
            projectDetails: {
                ...project.salesToOps.projectDetails,
                endDate: newValue
            }
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            salesToOps: obj
        }).then(res => {
            console.log(res)
            setProject({
                ...project,
                salesToOps: obj
            })
        }).catch(() => alert('Oops! There was an error updating project end date. Please try again or submit a feedback'))
    }

    return(
        <ProjectContext.Provider value={{
            project,
            setProject,
            location,
            materialsArr
        }}>
            <PageLayout
                page='Projects'
            >
                {Object.keys(project).length > 0 &&
                    <Box>
                        {/* <PageHeader
                            title={project.name}
                            icon={<Article /> }
                        /> */}

                        <Box sx={{display: 'flex'}}>
                            <Typography variant='h5' sx={{flexGrow: 1, mb: 2, color: '#555555', letterSpacing: '.03em'}}>{project.name}</Typography>
                            <Box sx={{display: 'flex'}}>
                                <Box>
                                    <Typography variant='subtitle2' sx={{color: '#767676', letterSpacing: '.03em'}}>PROJECT VALUE</Typography>
                                    <Typography variant='subtitle1' sx={{color: '#ed6a22', letterSpacing: '.03em', fontWeight: '500'}}>{bidTotal}</Typography>
                                </Box>
                                <SignalCellularAlt sx={{fontSize: '50px', fill: 'url(#linearColors)'}} />
                            </Box>
                        </Box>

                        {project.milestones && project.milestones.length > 0 &&
                            <Progress />
                        }

                        <Grid container spacing={4} my={3} alignItems='stretch' direction='row' mb={5}>
                            <Grid item xs={12} lg={3} xl={3} my='auto'>
                                <Box sx={{bgcolor: '#ed6a22', width: '100%', height: '320px', borderRadius: '150px 0', position: 'relative'}}>
                                    <svg width={0} height={0}>
                                        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                                            <stop offset={0} stopColor="#ffb000" />
                                            <stop offset={.5} stopColor="#ed6a22" />
                                            <stop offset={1} stopColor='#fd6f01' />
                                        </linearGradient>
                                    </svg>

                                    <Box sx={{position: 'absolute', top: '50%', transform: 'translate(0%, -50%)', maxWidth: '100%', pl: 5}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2} my='auto'>
                                                <Avatar sx={{background: 'white', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                                                    <Person sx={{fill: 'url(#linearColors)'}} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10} my='auto'>
                                                <Typography variant='h6' sx={{color: 'white', letterSpacing: '.03em', width: '70%', wordWrap: 'break-word'}}>{project.contact.name}</Typography>
                                            </Grid>
                                            <Grid item xs={2} my='auto'>
                                                <Avatar sx={{background: 'white', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                                                    <Phone sx={{fill: 'url(#linearColors)'}} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10} my='auto'>
                                                <Typography variant='h6' sx={{color: 'white', letterSpacing: '.05em', width: '100%'}}>{project.contact.phone}</Typography>                                            
                                            </Grid>
                                            <Grid item xs={2} my='auto'>
                                                <Avatar sx={{background: 'white', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                                                    <Mail sx={{fill: 'url(#linearColors)'}} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10} my='auto'>
                                                <Typography variant='h6' sx={{color: 'white', letterSpacing: '.03em', width: '90%', overflowWrap: 'break-word'}}>{project.contact.email}</Typography>                                            
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} my='auto'>
                                <ChecklistProgress />
                            </Grid>

                            <Grid item xs={12} md={3} my='auto'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Typography variant='h6' sx={{color: '#767676', mb: 3}}>Click on a date below to change it</Typography>
                                    <Stepper
                                        orientation="vertical"
                                    >
                                        <Step>
                                            <StepLabel
                                                StepIconComponent={() => 
                                                    <Avatar sx={{background: 'linear-gradient(#ffb000 0%, #ed6a22 50%, #fd6f01 100%)', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                                                        <CalendarMonth sx={{color: 'white'}} />
                                                    </Avatar>
                                                }
                                            >
                                                <Typography variant='body2' sx={{color: '#767676', letterSpacing: '.03em', ml: 1}}>START PROJECT</Typography>

                                                <Tooltip title='Change project start date'>
                                                    <MobileDatePicker
                                                        onChange={changeStartDate}
                                                        renderInput={(params) => 
                                                            <TextField 
                                                                {...params} 
                                                                size='small' 
                                                                variant='standard'
                                                                InputProps={{
                                                                    disableUnderline: true
                                                                }}
                                                                sx={{ml: 1, input: {
                                                                    color: '#ed6a22',
                                                                    fontSize: '25px',
                                                                    letterSpacing: '.03em'
                                                                }}}
                                                            />
                                                        }
                                                        
                                                        value={project.salesToOps.projectDetails?.startDate}
                                                    />         
                                                </Tooltip>                                     
                                            </StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel
                                                StepIconComponent={() => 
                                                    <Avatar sx={{background: 'linear-gradient(#ffb000 0%, #ed6a22 50%, #fd6f01 100%)', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                                                        <CalendarMonth sx={{color: 'white'}} />
                                                    </Avatar>
                                                }
                                            >
                                                <Typography variant='body2' sx={{color: '#767676', letterSpacing: '.03em', ml: 1}}>END PROJECT</Typography>

                                                <Tooltip title='Change project end date'>
                                                    <MobileDatePicker
                                                        onChange={changeEndDate}
                                                        renderInput={(params) => 
                                                            <TextField 
                                                                {...params} 
                                                                size='small' 
                                                                variant='standard'
                                                                InputProps={{
                                                                    disableUnderline: true
                                                                }}
                                                                sx={{ml: 1, input: {
                                                                    color: '#ed6a22',
                                                                    fontSize: '25px',
                                                                    letterSpacing: '.03em'
                                                                }}}
                                                            />
                                                        }
                                                        
                                                        value={project.salesToOps.projectDetails?.endDate}
                                                    /> 
                                                </Tooltip>
                                            </StepLabel>
                                        </Step>
                                    </Stepper>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3} my='auto'>
                                <Subcontractor />
                            </Grid>
                        </Grid>

                        <Paper>
                            <Box sx={{px: 2, pt: 3, display: 'flex'}}>
                                <Tabs 
                                    value={value} 
                                    onChange={handleChange} 
                                    aria-label="basic tabs example"
                                    variant='scrollable'
                                    scrollButtons='auto'
                                    orientation='vertical'
                                    sx={{borderRight: 1, borderColor: 'divider', width: '220px'}}
                                >
                                    {componentList.map((comp, index) => (
                                        <Tab label={comp.title} key={comp.title} icon={comp.icon} sx={{alignItems: 'start'}} iconPosition='start' />
                                    ))}
                                </Tabs>
                                {componentList.map((comp, index) => (
                                    <TabPanel value={value} index={index}>
                                        {comp.component}
                                    </TabPanel>
                                ))}
                            </Box>
                        </Paper>
                    </Box>
                }
            </PageLayout>
        </ProjectContext.Provider>
    )
}

export default OpenProject;