import React from "react";
import { Box, Button } from "@mui/material";

function ModalButtons(props) {
    const { handleClose, submit } = props;

    return(
        <Box sx={{textAlign: 'right', p: 2}}>
            <Button 
                variant='contained' 
                sx={{fontSize: '10px'}}
                onClick={handleClose}
                color='error'
            >
                cancel
            </Button>
            <Button 
                variant='contained' 
                sx={{fontSize: '10px', ml: 1}}
                onClick={submit}
                color='success'
            >
                ok
            </Button>
        </Box>
    )

}

export default ModalButtons;