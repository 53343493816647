import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Grid, Box, Button, Checkbox, FormControlLabel, IconButton, Modal, Tab, Tabs, TextField, Tooltip, Typography, InputAdornment } from '@mui/material';
import { Add, Delete, Edit, Search } from '@mui/icons-material';
import { useState } from 'react';
import style from '../../styles/modal';
import axios from 'axios';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ModalButtons from '../../components/modalButtons/modalButtons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
} 

function ChangeOrderWorkarea(props) {
    const costguideId = localStorage.getItem('costguideId');
    const { index, options, newMargin, id, saveNewMaterialOrder, project, materialsArr, changeOrder, setChangeOrder } = props;
    console.log(newMargin);
    const [value, setValue] = useState(0);
    const [workarea, setWorkarea] = useState(props.workarea);
    const [activeMaterial, setActiveMaterial] = useState(null);
    const open = Boolean(activeMaterial);
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [materialOpen, setMaterialOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(materialsArr.slice(0, 25));
    const [hasSearched, setHasSearched] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [updateCostguide, setUpdateCostguide] = useState(false);

    const handleMaterialOpen = (workarea) => {
        setMaterialOpen(true);
        setWorkarea(workarea)
    };

    const handleMaterialClose = () => {
        setMaterialOpen(false);
    };

    function handleEditOpen(material) {
        let cost = material.Cost * material.qty * 100 / (100 - newMargin);

        setActiveMaterial({
            ...material,
            Cost: (material.Cost * 100) / 100,
            totalCost: material.totalCost || cost
        });
        setEditOpen(true);
    };

    function editCost(e) {
        let value = e.target.value;
        if(value === '' || value < 0) {
            value = 0
        }

        else {
            value = Number(value.replaceAll(',', ''));

            let cost = value * 100 / (100 - newMargin);
            let totalCost = activeMaterial.qty * cost;

            setActiveMaterial({
                ...activeMaterial,
                Cost: value,
                totalCost: totalCost,
                unitPrice: value * 100 / (100 - newMargin)
            })
        }
    }

    function editTotalCost(e) {
        let value = e.target.value;
        if(value === '' || value < 0) {
            value = 0
        }

        else {
            value = Number(value.replaceAll(',', ''));

            setActiveMaterial({
                ...activeMaterial,
                unitPrice: value,
                totalCost: value * activeMaterial.qty
            })
        }
    }

    function overrideTotalCost(e) {
        let value = e.target.value;
        if(value === '' || value < 0) {
            value = 0
        }

        else {
            value = Number(value.replaceAll(',', ''));

            setActiveMaterial({
                ...activeMaterial,
                totalCost: value
            })
        }
    }

    // useEffect(() => {
    //     setMaterials([
    //         ...props.workarea?.materials
    //     ])
    // }, [props.workarea, workarea])

    // useEffect(() => {
    //     console.log(workarea);
    // }, [workarea])

    useEffect(() => {
        // add a row for totals if a row for totals doesn't exist
        if(workarea.materials?.filter(material => material.Name === 'TOTALS').length === 0 && workarea.materials?.length > 0) {
            console.log(props.workarea)
            setWorkarea(workarea => ({
                ...workarea,
                materials: [...workarea.materials, {
                    Name: 'TOTALS',
                    Units: '',
                    Cost: new Intl.NumberFormat('en-US', options).format(workarea.materials?.reduce((accum, item) => accum + item.Cost, 0)),
                    unitPrice: new Intl.NumberFormat('en-US', options).format(workarea.materials?.reduce((accum, item) => accum + item.Cost, 0) * 100 / (100 - Number(newMargin))),
                    totalCost: new Intl.NumberFormat('en-US', options).format(workarea.materials?.reduce((accum, item) => accum + (item.Cost * item.qty), 0)),
                    //clientPrice: new Intl.NumberFormat('en-US', options).format(workarea.materials?.reduce((accum, item) => accum + item.totalCost, 0)),
                    ID: 1
                }]
            }))
        }

        else {
            let obj = changeOrder.workareas?.filter(item => item.id === workarea.id)[0];
            console.log(props.workarea.materials);
            if(obj) {
                let materialArr = [...obj.materials]
                setWorkarea(() => ({
                    ...obj,
                    materials: materialArr
                }))
            }
        }

    }, [props.workarea]);

    const descriptionColumns = [
        {
            field: 'Name',
            headerName: 'Project Item',
            flex: .5,
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{params.row.Name}</b>
                }
                return (
                    <Typography variant='body2'>
                        {params.row.Name} 
                    </Typography>
                )
            }
        },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => {
                return(
                    <Tooltip title={params.row?.Description}>
                        <span>{params.row.Description}</span>
                    </Tooltip>

                )
            }
        },
        {
            field: 'edit',
            headerName: ' ',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return ''
                }
                return (
                    <Tooltip title='Edit material description'>
                        <IconButton
                            onClick={() => {
                                setActiveMaterial(params.row);
                                setDescriptionOpen(true);
                            }}
                        >
                            <Edit sx={{fontSize: '20px'}} />
                        </IconButton>
                    </Tooltip>
                )      
            },
            flex: .2
        }
    ];

    function editUpTo(material, workarea, ind) {
        let workareas = changeOrder.workareas.map((estWorkarea) => {
            if(estWorkarea.date === workarea.date) {
                let materials = estWorkarea.materials.map((estMaterial, index) => {
                    if(material.tempID && material.tempID === estMaterial.tempID) {
                        return {
                            ...material,
                            upTo: material.upTo ? false : true
                        }
                    }
                    else if(!material.tempID && index === ind) {
                        return {
                            ...material,
                            upTo: material.upTo ? false : true
                        }
                    }
                    else return estMaterial
                })

                return {
                    ...workarea,
                    materials: materials
                }
            }
            else return estWorkarea
        })

        setChangeOrder({
            ...changeOrder,
            workareas: workareas
        })

        setWorkarea({
            ...workarea,
            materials: [...workarea.materials.map((estMaterial, index) => {
                if(material.Name === 'TOTALS') {
                    return {
                        Name: 'TOTALS',
                        Units: '',
                        Cost: new Intl.NumberFormat('en-US', options).format(workarea.filter(estMaterial => estMaterial.Name !== 'TOTALS').materials?.reduce((accum, item) => accum + item.Cost, 0)),
                        unitPrice: new Intl.NumberFormat('en-US', options).format(workarea.filter(estMaterial => estMaterial.Name !== 'TOTALS').materials?.reduce((accum, item) => accum + item.Cost, 0) * 100 / (100 - Number(newMargin))),
                        totalCost: new Intl.NumberFormat('en-US', options).format(workarea.filter(estMaterial => estMaterial.Name !== 'TOTALS').materials?.reduce((accum, item) => accum + (item.Cost * item.qty), 0)),
                        clientPrice: new Intl.NumberFormat('en-US', options).format(workarea.filter(estMaterial => estMaterial.Name !== 'TOTALS').materials?.reduce((accum, item) => accum + item.totalCost, 0)),
                        ID: 1
                    }
                }
                else if(material.tempID && material.tempID === estMaterial.tempID) {
                    return {
                        ...material,
                        upTo: material.upTo ? false : true
                    }
                }
                else if(!material.tempID && index === ind) {
                    return {
                        ...material,
                        upTo: material.upTo ? false : true
                    }
                }
                else return estMaterial
            })]
        })

        /// update selected estimate in open project
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=projects&id=${project.id}`, {
            changeOrder: {
                ...changeOrder,
                workareas: workareas
            }
        }).then(res => console.log(res))
    };

    const materialColumns = [
        {
            field: 'Name', 
            headerName: 'Project Item', 
            flex: 1,
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{params.row.Name}</b>
                }
                return (
                    <Typography variant='body2'>
                        {params.row.Name}
                    </Typography>
                )
            }
        },
        {
            field: 'edit',
            headerName: ' ',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return ''
                }
                return (
                    <Tooltip title='Edit material'>
                        <IconButton onClick={() => handleEditOpen(params.row, workarea)} sx={{verticalAlign: 'middle'}}>
                            <Edit sx={{fontSize: '20px'}} />
                        </IconButton>
                    </Tooltip>
                )
            },
            flex: .2
        },
        {
            field: 'up to',
            headerName: 'Up To',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return ''
                }
                return (
                    <Tooltip title='Add "Up To" to this material quantity in proposal'>
                        <Checkbox
                            checked={params.row.upTo ? true : false}
                            onChange={() => editUpTo(params.row, workarea)}
                            size='small'
                        />
                    </Tooltip>
                )
            },
            flex: .2
        },
        {
            field: 'qty', 
            headerName: 'Qty', 
            flex: .3,
            //editable: true,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            disableClickEventBubbling: true,
            editable: (params) => params.row.Name === 'TOTALS' ? false : true
        },
        {
            field: 'Units',
            headerName: 'Units',
            flex: .2
        },
        {
            field: 'Cost',
            headerName: 'Unit Cost',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{new Intl.NumberFormat('en-US', options).format(workarea.materials.filter(material => material.Name !== 'TOTALS').reduce((accum, item) => accum + item.Cost, 0))}</b>
                    // return <b>{params.row.Cost}</b>
                }
                return new Intl.NumberFormat('en-US', options).format(params.row.Cost)
            },
            flex: .3
        },
        {
            field: 'unitPrice',
            headerName: 'Unit Price',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{new Intl.NumberFormat('en-US', options).format(workarea.materials.filter(material => material.Name !== 'TOTALS').reduce((accum, item) => accum + item.unitPrice || (item.Cost * 100) / ( 100 - Number(newMargin)), 0))}</b>
                }
                else {
                    let value;
                    params.row.unitPrice
                    ?    value = new Intl.NumberFormat('en-US', options).format(params.row.unitPrice)
                    :    value = new Intl.NumberFormat('en-US', options).format((params.row.Cost * 100) / ( 100 - Number(newMargin)))

                    return value
                }
            },
            flex: .3
        },
        {
            field: 'totalcost',
            headerName: 'Total Cost',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{new Intl.NumberFormat('en-US', options).format(workarea.materials.filter(material => material.Name !== 'TOTALS').reduce((accum, item) => accum + (item.Cost * item.qty), 0))}</b>
                }
                else {
                    return new Intl.NumberFormat('en-US', options).format(params.row.Cost * params.row.qty)
                }
            },
            flex: .5
        },
        {
            field: 'totalCost',
            headerName: 'Client Price',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <b>{
                        new Intl.NumberFormat('en-US', options)
                            .format(workarea.materials?.filter(material => 
                                material.Name !== 'TOTALS')
                                    .reduce((accum, item) => 
                                        accum + item.totalCost 
                                            || Number((item.Cost * 100 * item.qty) / ( 100 - Number(newMargin))), 0))
                                }</b>
                }

                else {
                    let value;

                    params.row.totalCost
                        ? value = new Intl.NumberFormat('en-US', options).format(params.row.totalCost)
                        : value = new Intl.NumberFormat('en-US', options).format((params.row.Cost * 100 * params.row.qty) / ( 100 - Number(newMargin)))
                    //return new Intl.NumberFormat('en-US', options).format((params.row.Cost * 100) / ( 100 - Number(newMargin)))

                    return value;
                }
            },
            flex: .5
        },
        {
            field: 'actions',
            headerName: ' ',
            renderCell: (params) => {
                if(params.row.Name === 'TOTALS') {
                    return <></>
                }
                return (
                    <Tooltip title='Delete material'>
                        <IconButton
                            onClick={() => removeMaterial(params.row.tempID, params.row.ID, workarea, index)}
                        >
                            <Delete color='error' sx={{fontSize: '20px'}} />
                        </IconButton>
                    </Tooltip>
                )
            }
        }
    ];

    function save() {
        let workareas = editMaterial(activeMaterial);

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=projects&id=${id}`, {
            changeOrder: {
                ...changeOrder,
                workareas: workareas
            }
        }).then(res => {
            console.log(res)
            setActiveMaterial(null);
            setDescriptionOpen(false);
        }).catch(err => {
            alert('Oops! There was an error updating description. Please try again or submit feedback')
        })
    };

    function updateOrder(params) {
        const { oldIndex, targetIndex } = params;

        return new Promise((resolve) => {
            setTimeout(() => {
                const rowsClone = [...workarea.materials];
                const row = rowsClone.splice(oldIndex, 1)[0];
                rowsClone.splice(targetIndex, 0, row);
                setWorkarea({
                    ...workarea,
                    materials: rowsClone
                })
                saveNewMaterialOrder(rowsClone);
                resolve(rowsClone);

            }, Math.random() * 500 + 100); // simulate network latency
        });
    };

    function handleSearchChange(value) {
        setSearchTerm(value);
        if(hasSearched) {
            setHasSearched(false)
        }
    }

    function search() {
        setHasSearched(true);

        // search all materials in database for search value
        // update state
        let searchArr = searchTerm.split(' ');
        let arr = materialsArr;

        searchArr.forEach(term => {
            arr = arr.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().trim().includes(term.toLowerCase().trim())))
        })

        setSearchResults(arr);
    }

    // function updateProps() {
    //     let arr = [...new Set([...workarea.materials, ...props.workarea.materials])];
    //     console.log(arr);
    //     setWorkarea({
    //         ...workarea,
    //         materials: arr
    //     });
    // };

    // useEffect(() => {
    //     updateProps()
    // }, [props.workarea])

    function addMaterial(material) {
        if(material.alert) {
            alert(material.alert)
        }

        if(material.Name === 'Blank - edit on the fly') {
            material.ID = uuidv4();
            material.tempID = uuidv4();
        }

        material.tempID = uuidv4();

        let workareas = changeOrder.workareas.map(estWorkarea => {
            if(estWorkarea.date === workarea.date ) {
                return {
                    ...estWorkarea,
                    materials: [...estWorkarea.materials, {...material, qty: 0, totalCost: 0}]
                }
            }
            return estWorkarea
        })
    
        setChangeOrder({
            ...changeOrder,
            workareas: workareas
        });

        setWorkarea({
            ...workarea,
            materials: [...workarea.materials, {...material, qty: 0, totalCost: 0}]
        })

        //setSearchResults(searchResults.filter(item => item.ID !== material.ID))
    }

    function removeMaterial(tempID, id, workarea, ind) {
        let materials = workarea.materials.filter((material, index) => material.tempID !== tempID && material.ID);

        let workareas = changeOrder.workareas.map(estWorkarea => {
            if(estWorkarea.date === workarea.date) {
                let price = materials.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + (item.totalCost * 100) / 100, 0);
                return {
                    ...workarea,
                    materials: materials,
                    price: price
                }
            }
            return estWorkarea
        })

        setChangeOrder({
            ...changeOrder,
            workareas: workareas
        })

        setWorkarea({
            ...workarea,
            materials: materials.map(estMaterial => {
                if(estMaterial.Name === 'TOTALS') {
                    return {
                        Name: 'TOTALS',
                        Units: '',
                        Cost: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + item.Cost, 0)),
                        unitPrice: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + item.Cost, 0) * 100 / (100 - Number(newMargin))),
                        totalCost: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + (item.Cost * item.qty), 0)),
                        clientPrice: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + item.totalCost, 0)),
                        ID: 1
                    }
                }
                return estMaterial
            })
        })
    }

    function updateQuantity(e, material, workarea, ind) {
        material = updateMaterial(e, material);

        let materials = workarea.materials.map((estMaterial, index) => {
            if(material.tempID && material.tempID === estMaterial.tempID) {
                console.log(material)
                return material
            }
            else if(!material.tempID && index === ind) {
                console.log(material)
                return material
            }
            return estMaterial
        });

        let workareas = changeOrder.workareas.map((estWorkarea) => {
            if(estWorkarea.date === workarea.date) {
                let workareaPrice = materials.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + Number(item.totalCost), 0);
                // let workareaPrice = new Intl.NumberFormat('en-US', options).format(num);

                return {
                    ...workarea,
                    materials: materials,
                    price: workareaPrice
                }
            }
            else return estWorkarea
        })

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=projects&id=${id}`, {
            changeOrder: {
                ...changeOrder,
                workareas: workareas
            }
        })

        setChangeOrder({
            ...changeOrder,
            workareas: workareas
        })

        setWorkarea({
            ...workarea,
            materials: materials.map((estMaterial, index) => {
                if(estMaterial.Name === 'TOTALS') {
                    return {
                        Name: 'TOTALS',
                        Units: '',
                        Cost: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + Number(item.Cost), 0)),
                        unitPrice: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + Number(item.Cost), 0) * 100 / (100 - Number(newMargin))),
                        totalCost: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + Number(item.Cost * item.qty), 0)),
                        clientPrice: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + Number(item.totalCost), 0)),
                        ID: 1
                    }
                }

                return estMaterial
            })
        })
    }

    function updateMaterial(e, material) {
        let value = '';

        console.log(e.target.value)

        if(e.target.value === '' || Number(e.target.value) === 0 || !Number(e.target.value)) {
            material = {
                ...material,
                totalCost: 0,
                qty: 0
            }
        }

        else {
            if(Number(e.target.value) > 0) {
                value = Number(e.target.value)
            }
    
            if(material.overrideCost && !material.unitPrice) {
                let totalCost;
                let cost = Number(material.overrideCost) * Number(value);

                totalCost = Number(Math.round(cost * 100) / 100);
    
                material = {
                    ...material,
                    qty: Number(value),
                    totalCost: totalCost
                }
            }
    
            else {
                let estMargin = newMargin;
                //console.log(newMargin)
                let totalCost;
                let cost;

                if(material.unitPrice) {
                    cost = Number(material.unitPrice) * Number(value);
                }

                else {
                    cost = Number(material.Cost) * Number(value) * 100 / (100 - estMargin);
                }

                totalCost = cost;
    
                material = {
                    ...material,
                    qty: Number(value),
                    totalCost: totalCost
                }
            }
        }

        return material
    }

    function editMaterial(material, ind) {
        let materials = workarea.materials.map((estMaterial, index) => {
            if(material.tempID && material.tempID === estMaterial.tempID) {
                return material
            }
            else if(!material.tempID && index === ind) {
                return material
            }
            else return estMaterial
        })

        let workareas = changeOrder.workareas.map((estWorkarea) => {
            if(estWorkarea.date === workarea.date) {
                // get the new cost of workarea
                let workareaPrice = materials.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + item.totalCost, 0);

                return {
                    ...workarea,
                    materials: materials,
                    price: workareaPrice
                }
            }
            else return estWorkarea
        })


        setChangeOrder({
            ...changeOrder,
            workareas: workareas
        })

        setWorkarea({
            ...workarea,
            materials: materials.filter(estMaterial => estMaterial.Name !== 'TOTALS').map((estMaterial, index) => {
                if(estMaterial.Name === 'TOTALS') {
                    return {
                        Name: 'TOTALS',
                        Units: '',
                        Cost: new Intl.NumberFormat('en-US', options).format(materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS')?.reduce((accum, item) => accum + item.Cost, 0)),
                        unitPrice: new Intl.NumberFormat('en-US', options).format(materials.filter(estMaterial => estMaterial.Name !== 'TOTALS')?.reduce((accum, item) => accum + item.Cost, 0) * 100 / (100 - Number(newMargin))),
                        totalCost: new Intl.NumberFormat('en-US', options).format(materials.filter(estMaterial => estMaterial.Name !== 'TOTALS')?.reduce((accum, item) => accum + (item.Cost * item.qty), 0)),
                        clientPrice: new Intl.NumberFormat('en-US', options).format(materials.filter(estMaterial => estMaterial.Name !== 'TOTALS')?.reduce((accum, item) => accum + item.totalCost, 0)),
                        ID: 1
                    }
                }
                else return estMaterial
            })
        });

        if(updateCostguide) {
            let arr = materialsArr.map(item => {
                if(item.ID === material.ID) {
                    return {
                        ...material,
                        Cost: material.Cost,
                        totalCost: material.totalCost
                    }
                }
                return item
            })

            // update materials in database
            axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=costguide&id=${costguideId}`, {
                materials: arr
            })
        }

        setActiveMaterial({});
        setEditOpen(false);

        return workareas
    }


    return(
        <>
            <Modal
                open={editOpen}
                onClose={() => setEditOpen(false)}
            >
                <Box sx={{...style, maxWidth: '500px', p: 3, maxHeight: '90vh', overflowY: 'auto'}}>
                    <Typography variant='h5' sx={{mb: 3}}>
                        EDIT MATERIAL
                    </Typography>
                    <Box>
                        <TextField
                            size='small'
                            fullWidth
                            label='Name'
                            sx={{mb: 2}}
                            value={activeMaterial?.Name}
                            onChange={(e) => setActiveMaterial({
                                ...activeMaterial,
                                Name: e.target.value
                            })}
                            style={{marginTop: '30px', marginBottom: '25px'}}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            label='Units'
                            sx={{mb: 2}}
                            value={activeMaterial?.Units}
                            onChange={(e) => setActiveMaterial({
                                ...activeMaterial,
                                Units: e.target.value
                            })}
                            style={{marginBottom: '25px'}}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            label='Quantity'
                            type='number'
                            sx={{mb: 2}}
                            value={activeMaterial?.qty}
                            onChange={(e) => {
                                let tempMaterial = updateMaterial(e, activeMaterial);
                                setActiveMaterial(tempMaterial)
                            }}
                            style={{marginBottom: '25px'}}
                        />
                        <CurrencyTextField
                            size='small'
                            fullWidth
                            label='Cost per Unit'
                            style={{marginBottom: '25px'}}
                            value={activeMaterial?.Cost}
                            variant='outlined'
                            onChange={(e) => editCost(e)}
                        />
                        <CurrencyTextField
                            size='small'
                            fullWidth
                            label='Client Price per Unit'
                            sx={{mb: 2}}
                            value={activeMaterial?.unitPrice || activeMaterial?.totalCost}
                            onChange={editTotalCost}
                            variant='outlined'
                            style={{marginBottom: '25px'}}
                        />
                        <CurrencyTextField
                            size='small'
                            fullWidth
                            label='Override client price for line item'
                            sx={{mb: 2}}
                            value={activeMaterial?.totalCost}
                            onChange={overrideTotalCost}
                            variant='outlined'
                            style={{marginBottom: '25px'}}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            label='Item Description'
                            sx={{mb: 3}}
                            multiline
                            minRows={3}
                            value={activeMaterial?.Description || ''}
                            onChange={(e) => setActiveMaterial({
                                ...activeMaterial,
                                Description: e.target.value
                            })}
                            style={{marginBottom: '25px'}}
                        />

                        <Grid container>
                            <Grid item xs={8} my='auto'>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={updateCostguide}
                                        onChange={() => setUpdateCostguide(!updateCostguide)}
                                    />}
                                    label='Apply changes to item cost guide'
                                />
                            </Grid>
                            <Grid item xs={4} my='auto'>
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        sx={{fontSize: '10px'}}
                                        onClick={() => setEditOpen(false)}
                                        color='error'
                                        variant='contained'
                                    >
                                        cancel
                                    </Button>
                                    <Button
                                        sx={{fontSize: '10px', ml: 1}}
                                        color='success'
                                        variant='contained'
                                        onClick={() => editMaterial(activeMaterial)}
                                    >
                                        apply
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Modal>
            <Modal
                open={materialOpen}
                onClose={handleMaterialClose}
            >
                <Box sx={{...style, maxWidth: '800px'}}>
                    <Typography variant='h6' sx={{bgcolor: '#f5f5f5', p: 2}}>
                        ADD PROJECT ITEM
                    </Typography>
                    <Grid container p={2}>
                        <Grid item xs={6}>
                            <TextField
                                type='search'
                                value={searchTerm}
                                size='small'
                                fullWidth
                                placeholder='Search Materials'
                                onChange={(e) => handleSearchChange(e.target.value)}
                                onKeyDown={(event) => {
                                    if(event.code === 'Enter') {
                                        search()
                                    }
                                }}
                                InputProps={{
                                    endAdornment: 
                                    <InputAdornment position='end'>
                                        <Button
                                            onClick={search}
                                        >
                                            <Search />
                                        </Button>
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{width: '100%', height: '300px', overflowY: 'auto', border: '1px solid #ddd', mt: 2}}>
                                {searchResults.length === 0 && hasSearched &&
                                    <Box sx={{width: '100%', textAlign: 'center', p: 5}}>No results found. Please try another search</Box>
                                }
                                
                                {searchResults.length > 0 &&
                                    searchResults.map(material => {
                                        return(
                                            <Box key={material.ID} sx={{borderBottom: '1px solid #ddd', p: 1}}>
                                                <Grid container>
                                                    <Grid item xs={11} pr={2} >
                                                        <Typography sx={{width: '100%', fontSize: '14px', fontWeight: 'bold'}}>
                                                            {`${material.Name} - ${material.ID}`}
                                                        </Typography>
                                                        <Typography variant='body2' sx={{width: '100%'}}>
                                                            {material.Description ? material.Description.toString() : ''}
                                                        </Typography>
                                                        {material.url &&
                                                            <Typography
                                                                variant='body2'
                                                                component='a'
                                                                href={material.url}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                            >
                                                                {material.url}
                                                            </Typography>
                                                        }<br />
                                                        {material.Internal &&
                                                            <Typography
                                                                variant='body2'
                                                                component='a'
                                                                href={material.Internal}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                            >
                                                                {material.Internal}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={1} my='auto'>
                                                        <Button
                                                            variant='contained'
                                                            sx={{fontSize: '10px', padding: '3px 10px'}}
                                                            onClick={() => addMaterial(material)}
                                                        >
                                                            add
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <ModalButtons
                        handleClose={handleMaterialClose}
                        submit={handleMaterialClose}
                    />
                </Box>
            </Modal>
            <Modal
                open={descriptionOpen}
                onClose={() => setDescriptionOpen(false)}
            >
                <Box
                    sx={{...style, maxWidth: '500px', p: 3, maxHeight: '90vh', overflowY: 'auto'}}
                >
                    <Typography variant='h5'>
                        EDIT DESCRIPTION
                    </Typography>
                    <Typography sx={{mb: 3, mt: 1}}>{activeMaterial?.Name}</Typography>
                    <TextField
                        fullWidth
                        label='Material Description'
                        multiline
                        minRows={5}
                        onChange={(e) => setActiveMaterial({
                            ...activeMaterial,
                            Description: e.target.value
                        })}
                        sx={{mb: 3}}
                        value={activeMaterial?.Description}
                    />
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            sx={{fontSize: '10px'}}
                            onClick={() => setActiveMaterial(null)}
                            color='error'
                            variant='contained'
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            color='success'
                            variant='contained'
                            onClick={save}
                        >
                            apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Box sx={{ border: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} aria-label="estimate view tabs">
                    <Tab label="Pricing" {...a11yProps(0)} sx={{fontSize: '12px'}} />
                    <Tab label="Descriptions" {...a11yProps(1)} sx={{fontSize: '12px'}} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <DataGridPro
                    onRowOrderChange={(params) => updateOrder(params)}
                    //rowReordering={true}
                    columns={materialColumns}
                    rows={workarea.materials}
                    autoHeight
                    autoPageSize
                    density='compact'
                    getRowId={(row) => row.tempID || row.ID}
                    onCellEditStop={(params, e) => updateQuantity(e, params.row, workarea, index)}
                    hideFooter
                    disableSelectionOnClick
                />
                <Button
                    sx={{fontSize: '12px', mt: 2}}
                    onClick={() => handleMaterialOpen(workarea)}
                    startIcon={<Add /> }
                >
                    add project item
                </Button>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DataGridPro
                    columns={descriptionColumns}
                    rows={workarea.materials}
                    autoHeight
                    autoPageSize
                    density='compact'
                    getRowId={(row) => row.tempID || row.ID}
                    onCellEditStop={(params, e) => updateQuantity(e, params.row, workarea, index)}
                    hideFooter
                    disableSelectionOnClick
                />
            </TabPanel>
        </>
    )
}

export default ChangeOrderWorkarea;