import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Button, MenuItem, Paper, Select, TextField, Typography, FormControl, InputLabel } from "@mui/material";
import ButtonBox from "../../components/buttonBox/buttonBox";

function Media(props) {
    const { id } = useParams();
    const [formValues, setFormValues] = useState({
        clientInterview: true,
        subInterview: true,
        description: '',
        contactName: ''
    });
    const [error, setError] = useState(false);

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getById?containerId=projects&id=${id}`)
            .then(res => {
                console.log(res.data[0])
                const project = res.data[0];
                //setProject(res.data[0]);
                setFormValues({
                    ...formValues,
                    value: project.bidTotal,
                    type: project.division || '',
                    projectName: project.name
                })
            })
    }, []);
    
    function handleChange(e) {
        setError(false)
        setFormValues({
            ...formValues,
            [e.target.id]: e.target.value
        })
    }

    function submit() {
        let { value, type, description, contactName } = formValues;

        if(value.length === 0 || type.length === 0 || description.length === 0 || contactName.length === 0) {
            setError('This field is required');
        }

        else {
            formValues.submitted = new Date().getTime();
            formValues.submittedBy = localStorage.getItem('user');
            formValues.location = localStorage.getItem('location')
            axios.post(`https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/save?containerId=requests`, formValues).then(res => {
                console.log(res)
                if(res.statusText === 'OK') {
                    window.close()
                }
                else {
                    alert('Oops! There was an error. Please try again.')
                }
            })
        }
    }

    return(
        <Box sx={{width: '100%', bgcolor: '#0b56a4'}}>
            <Box sx={{p: 5, width: '90%', maxWidth: '600px', margin: '0 auto'}}>
                <Paper sx={{p: 3}}>
                    <Typography sx={{mb: 1}} variant='body1'>Media Request</Typography>
                    <Typography variant='h5' sx={{mb: 3}}>{formValues.projectName}</Typography>
                    <TextField
                        multiline
                        minRows={4}
                        value={formValues.description}
                        fullWidth
                        label='Project Description'
                        id='description'
                        sx={{mb: 3}}
                        onChange={handleChange}
                        error={error && formValues.description.length === 0}
                        helperText={error}
                    />
                    <TextField
                        fullWidth
                        value={formValues.type || ''}
                        id='type'
                        onChange={handleChange}
                        label='Project Type'
                        sx={{mb: 3}}
                        size='small'
                        error={error && formValues.type.length === 0}
                        helperText={error}
                    />
                    <TextField
                        sx={{mb: 3}}
                        fullWidth
                        label='Project Value'
                        id='value'
                        value={formValues.value || ''}
                        onChange={handleChange}
                        size='small'
                        error={error && formValues.value.length === 0}
                        helperText={error}
                    />

                    <FormControl
                        fullWidth
                        size='small'
                        sx={{mb:3}}
                    >
                        <InputLabel>Is your customer willing to let us interview them?</InputLabel>
                    
                        <Select
                            value={formValues.clientInterview}
                            onChange={(e) => setFormValues({
                                ...formValues,
                                clientInterview: e.target.value
                            })}
                            label='Is your customer willing to let us interview them?'
                            size='small'
                            fullWidth
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        size='small'
                        sx={{mb: 3}}
                    >
                        <InputLabel>Is your Sub willing to let us interview them?</InputLabel>
                    
                        <Select
                            value={formValues.subInterview}
                            onChange={(e) => setFormValues({
                                ...formValues,
                                subInterview: e.target.value
                            })}
                            label='Is your Sub willing to let us interview them?'
                            size='small'
                            fullWidth
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{mb: 3}}
                        value={formValues.contactName || ''}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                        label='Contact Name'
                        helperText='Who will be our contact at your Transblue office for this project?'
                        error={error && formValues.contactName.length === 0}
                        id='contactName'
                        //helperText={error}
                    />

                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px'}}
                            onClick={submit}
                            variant='contained'
                            color='success'
                        >
                            submit request
                        </Button>
                    </ButtonBox>
                </Paper>
            </Box>
        </Box>
    )
}

export default Media;