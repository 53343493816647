import axios from 'axios';
import qs from 'qs';

async function checkAdobeLogin() {
    let az_sign_token = localStorage.getItem('az_sign_token');

    // open adobe sign oauth in new window if user has not logged in yet
    if(!az_sign_token) {
        
    }

    else {
        let api_url = localStorage.getItem('api_url');

        // get adobe sign refresh token from local storage
        let refresh_token = localStorage.getItem('az_refresh_token');
        
        // configure body of axios post request to refresh token endpoint
        let data = qs.stringify({
            client_id: process.env.REACT_APP_AZ_CLIENT_ID,
            client_secret: 'JFwVzJUy8I8h1OWQqDKv0HsD3sLjwAmr',
            grant_type: 'refresh_token',
            refresh_token: refresh_token
        });

        var config = {
            method: 'post',
            url: `${api_url}oauth/v2/refresh`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
        };

        // use refresh token to get new access token
        let token = await axios(config)
        .then(function (response) {
            let access_token = response.data.access_token;
            localStorage.setItem('az_sign_token', access_token);
            return access_token
        })

        return token
    }
}

export default checkAdobeLogin;