import React, { useContext, useEffect, useState, useCallback } from "react";
import { ProjectContext } from "./openProject";
import AddCircle from "@mui/icons-material/AddCircle";
import ModalButtons from "../../components/modalButtons/modalButtons";
import GridRow from "../../components/gridRow/gridRow";
import style from "../../styles/modal";
import data from "../../utils/data";
import axios from "axios";
import checkAdobeLogin from "../../utils/checkAdobeLogin";
import { Buffer } from 'buffer';
import dataUri from "./logoDataUri";
import { Button, ButtonGroup, Modal, Typography, Box, Grid, TextField, Autocomplete, InputAdornment, IconButton, Tooltip, List, ListItem, ListItemText, CircularProgress } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Check, Delete, Edit, Notes, AttachMoney, Add, PostAdd, Save, RemoveRedEye, Send } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import CKEditor from "ckeditor4-react";
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import ChangeOrderWorkarea from "./changeOrderWorkarea";

const { costcodes } = data;

// connect to azure blob storage container for documents
const { BlobServiceClient } = require('@azure/storage-blob');
const blobSasUrl = 'https://tbconnectstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-01-24T01:54:35Z&st=2023-01-23T17:54:35Z&spr=https&sig=LPkdkk9v3%2Bt8P01xUPfYVHFirO7A6wUWVTdiKK1N%2F4Y%3D';
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = 'documents';
const containerClient = blobServiceClient.getContainerClient(containerName);

const SectionHeader = (props) => {
    const { children } = props;
    return(
        <Box sx={{width: '100%', bgcolor: '#f8f8f8', padding: '15px', display: 'flex'}}>
            <Grid container>
                <Grid item xs={1}>
                    {children[0]}
                </Grid>
                <Grid item xs={10}>
                    <Typography >    
                        {children[1]}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    {children[2]}
                </Grid>
            </Grid>
        </Box>  
    )
}

function CustomDetailPanelToggle(props) {
    const { value: isExpanded } = props;
  
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMore
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
};


function NewChangeOrder() {
    let options = { style: 'currency', currency: 'USD' };
    const projectContext = useContext(ProjectContext);
    const { project, location, materialsArr } = projectContext;
    console.log(location);
    const { id } = project;
    const [changeOrder, setChangeOrder] = useState(project.changeOrder 
        ?   {
                ...project.changeOrder,
                headerTerms: project.changeOrder.headerTerms || [],
                footerTerms: project.changeOrder.footerTerms || []
            } 
        :   {
            workareas: [],
            headerTerms: [{
                name: 'HEADER',
                body: location.headerTerms
            }] || [],
            footerTerms: [{
                name: 'FOOTER',
                body: location.footerTerms
            }] || []
    });

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [reorderOpen, setReorderOpen] = useState(false);
    const [editWorkareaTitleOn, setEditWorkareaTitleOn] = useState(false);
    const [proposalOpen, setProposalOpen] = useState(false);

    const [workarea, setWorkarea] = useState({
        name: '',
        date: '',
        materials: [],
        costcode: '41100 Asphalt / Parking Lot Construction',
        price: 0
    })

    const [open, setOpen] = useState(false);
    const [html, setHtml] = useState({});
    const [proposalHtml, setProposalHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [otherTerms, setOtherTerms] = useState([
        ...location.otherTerms || [],
        {
            name: 'HEADER',
            body: location.headerTerms
        },
        {
            name: 'FOOTER',
            body: location.footerTerms
        }
    ]);

    const [headerTermsOpen, setHeaderTermsOpen] = useState(false);
    const [footerTermsOpen, setFooterTermsOpen] = useState(false);

    const [sendProgress, setSendProgress] = useState('Generating HTML document');
    const [sendError, setSendError] = useState('');
    const [progressOpen, setProgressOpen] = useState(false);

    const [previewWidth, setPreviewWidth] = useState('100%');
    const [hideHeaders, setHideHeaders] = useState({
        salesperson: false,
        clientInfo: false,
        customerName: false
    });
    const [hidePrices, setHidePrices] = useState({
        workareaPrice: false,
        itemDescription: false,
        itemPrice: false,
        taxes: false,
        grandTotal: false
    });

        // add event listener to listen for when the new adobe sign tab opens, gets access token using OAuth 2.0, and stores access and refresh token in local storage
    // if local storage event is triggered, call send proposal function to send proposal using adobe sign access token
    useEffect(() => {
        window.addEventListener('storage', async (event) => {
            if(event.key === 'az_sign_token') {
                console.log('az token');
                //let est = getSelectedEstimate();
                sendProposal(event.newValue);
            }
        });

        // window.addEventListener('storage', () => {
        //     console.log('storage changed');
        //     let est = getSelectedEstimate();
        //     console.log(est);
        // })
    }, []);

    async function loginUser() {
        // check if there is an adobe sign token already in local storage
        let az_sign_token = localStorage.getItem('az_sign_token');
        console.log(az_sign_token);


        // if there is, use checkAdobeLogin function to use refresh token from local storage to get new access token
        if(az_sign_token) {
            az_sign_token = await checkAdobeLogin();
            console.log(az_sign_token);
            sendProposal(az_sign_token);
        }

        else {
            window.open(`https://secure.na4.adobesign.com/public/oauth/v2?redirect_uri=https://transblueconnect.com/auth&response_type=code&client_id=${process.env.REACT_APP_AZ_CLIENT_ID}&scope=user_login:self+agreement_read:self+agreement_write:self+agreement_send:self+library_read:self`)
        }
    };

    function createRequest() {
        let year = new Date().getFullYear();
        let html = generatePreview();
        html += `<table style="width: 100%; border: 0px;  font-family: 'Roboto'; font-size: .75rem">
            <tr>Dated this <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /> day of <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" />, <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px; width: 80px" value="${year}" />.</tr>
            <tr>
                <td style="width: 50%; padding-top: 30px">
                    ${location.llc}, <i>dba</i><br />
                    ${location.name === 'Transblue Monroe' ? 'Transblue' : location.name}<br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    By: <br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    Signature:
                    
                </td>
                <td style="width: 50%; padding-top: 30px">
                    <br />Client<br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    By: <br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    Signature:
                    
                </td>
            </tr>
        </table>`

        setPreviewWidth('1240px');  
        setProposalHtml(html);
        setProposalOpen(true);
    };

    function generatePreview(est) {
        let estimate = changeOrder;

        const bidTotal = estimate.workareas.reduce((accum, item) => accum + Number(item.price), 0);

        let street_number = project.contact?.addressComps?.find(comp => comp.types && comp.types.includes('street_number'))?.short_name || '';
        let route = ` ${project.contact?.addressComps?.find(comp => comp.types && comp.types.includes('route'))?.short_name}` || ' ';
        let locality = project.contact?.addressComps?.find(comp => comp.types && comp.types.includes('locality'))?.short_name || '';
        let state = project.contact?.addressComps?.find(comp => comp.types && comp.types.includes('administrative_area_level_1'))?.short_name;
        let zipcode = project.contact?.addressComps?.find(comp => comp.types && comp.types.includes('postal_code'))?.short_name || '';

        let proposalTemplate = `
                <img src=${dataUri} />
                <table border="0" cellpadding="1" cellspacing="1" style="width:100%">
                    <tbody>
                        <tr>
                            <td style="width: 50%">
                                ${hideHeaders.customerName ? '' : `<strong>${project.contact.name.toUpperCase()}</strong><br />`}
                                ${project.contact.addressComps ? `<strong>${street_number.toUpperCase()}${route.toUpperCase()}</strong><br />
                                <strong>${locality.toUpperCase()}, ${state.toUpperCase()} ${zipcode.toUpperCase()}</strong>` : `<strong>${project.contact.address}</strong>`}
                            </td>
                            <td style="width: 50%">
                                ${hideHeaders.clientInfo ? '' : `<strong>Email: </strong>${project.contact.email}`}<br />
                                ${hideHeaders.clientInfo ? '' : `<strong>Phone: </strong>${project.contact.phone}`}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table border="0" cellpadding="1" cellspacing="1" style="width:100%">
                    <tbody>
                        <tr>
                            <td style="width: 50%">
                                <strong>Est ID: </strong>${project.id}<br />
                                <strong>Date: </strong>${new Date(project.date).toLocaleDateString()}
                            </td>

                            <td style="width: 50%">
                                ${hideHeaders.salesperson ? '' : `<p><strong>Sales: </strong>${project.salesperson.name}<br />`}
                                <strong>${project.name}</strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <p>&nbsp;</p>
        `;
        let tempProposal = proposalTemplate;

        // add header terms to proposal html
        estimate.headerTerms.forEach(term => {
            tempProposal += term.body;
        })

        tempProposal += '<br /><br /><br />'

        tempProposal += '<div style="display: none">[llc]</div>';

        tempProposal += `<div class="pagebreak"> </div>`;
            estimate.workareas.forEach(workarea => {
                // get materials for selected workarea
                let materials = workarea.materials.filter(material => material.Name !== "TOTALS");

                let rows = ``;

                // generate table rows for each material
                materials.forEach(material => {
                    rows += `
                        <tr>
                            <td style="width: 10%; padding: 5px">${material.upTo ? `Up To ${material.qty}` : material.qty} ${material.Units}</td>
                            <td style="padding: 5px">${material.Name}</td>
                            ${hidePrices.itemDescription ? '' : `<td style="padding: 5px">${material.Description}</td>`}
                            ${hidePrices.itemPrice ? '' : `<td style="width: 15%; text-align: right; padding: 5px"> ${new Intl.NumberFormat('en-US', options).format(material.totalCost)}</td>`}
                        </tr>
                    `
                });

                // create table
                let table = `
                    <div>
                    <table border="1" cellpadding="1" cellspacing="1" style="width:100%; border-collapse: collapse; font-size: 12px;">
                        <tbody>
                            ${rows}
                        </tbody>
                    </table>
                    </div>
                `;

                let estimateHtml = `
                    ${html[workarea.name] || ''}
                    ${workarea.text || ''}
                    <div>
                        <table border="0" style="width: 100%; border-collapse: collapse;">
                            <tbody>
                                <tr style="background-color: #b2b2b2;">
                                    <td style="width: 70%; padding: 10px">${workarea.name}</td>
                                    ${hidePrices.workareaPrice ? '' : `<td style="width: 30%; text-align: right; padding: 10px"><b>${new Intl.NumberFormat('en-US', options).format(workarea.price)}</b></td>`}
                                </tr>
                            </tbody>
                        </table>
                    ${table}
                    </div>
                `;

                tempProposal += estimateHtml += '<p>&nbsp;</p> <hr />';
            });

            // add price of proposal to html
            if(!hidePrices.grandTotal) {
                tempProposal += `<div style="width: 100%; text-align: right; font-size: 16px"><b>Estimate Total: ${new Intl.NumberFormat('en-US', options).format(bidTotal)}</b></div>`;
            }



        tempProposal += '<div style="display: none">[llc]</div>';
        // close out the div class='page'

        tempProposal += `<div class="pagebreak"> </div>`;


        // add footer terms to proposal html
        estimate.footerTerms.forEach(term => {
            tempProposal += term.body;
        })

        setProposalHtml(tempProposal);

        return tempProposal
    };

    const Export2Word = useCallback(async (element, filename = project.name) => {
        var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title>
            <style>
                p, table, li, div, tr {
                    font-family: 'Arial';
                    font-size: 0.75rem;
                    line-height: 1.5em
                }
                body {
                    padding: 50px 10px;
                    line-height: 1.5;
                    font-weight: 300;
                    font-family: 'Arial';
                    line-height: 1.5em
                }
                img {
                    width: 200px
                }
                @media print {
                    table {
                        page-break-inside: avoid;
                    }
                    p {
                        page-break-inside: avoid;
                    }
                    div {
                        page-break-inside: avoid;
                    }
                }
            </style></head><body>`;
        var postHtml = "</body></html>";

        let body = generatePreview();
            body += `<br /><br /><table style="width: 100%; border: 0px; font-family: 'Arial'; font-size: .75rem">
            <tr>
                <td style="width: 50%; padding-top: 30px;">
                    ${location.llc}, <i>dba</i><br />
                    ${location.name === 'Transblue Monroe' ? 'Transblue' : location.name}<br /><br />
                    <div style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" /><br />
                    By: <br /><br />
                    <div style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" /><br />
                    Signature:
                </td>
                <td style="width: 50%; padding-top: 10px">
                    <br />Client<br /><br /><br />
                    <div style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" /><br />
                    By: <br /><br />
                    <div style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" /><br />
                    Signature:
                </td>
            </tr>
        </table>`

        if(location.name !== 'Transblue Monroe') {
            body += `<small style="font-size: 0.5rem; font-family: 'Roboto'; margin-top: 30px">${location.llc}, <i>dba</i> ${location.name} is an independently owned and operated franchisee of Transblue Franchise Company, LLC.</small>`;
        }

        var html = preHtml + body + postHtml;
    
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        
        // Specify file name
        filename = filename?filename+'.doc':'document.doc';
        
        // Create download link element
        var downloadLink = document.createElement("a");
    
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
        
        document.body.removeChild(downloadLink);
    }, []);  
    

    async function newDownload() {
        setProgressOpen(true);
        setSendProgress('Generating PDF using Adobe PDF services');
        let year = new Date().getFullYear();  
        let html = generatePreview();
            html += `<br /><br /><table style="width: 100%; border: 0px; font-family: 'Roboto'; font-size: .75rem">
            <tr>Dated this  <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /> day of <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" />, <input style="width: 80px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="${year}" />.</tr>
            <tr>
                <td style="width: 50%; padding-top: 30px;">
                    ${location.llc}, <i>dba</i><br />
                    ${location.name === 'Transblue Monroe' ? 'Transblue' : location.name}<br /><br />
                    <input style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    By: <br /><br />
                    <input style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    Signature:
                    
                </td>
                <td style="width: 50%; padding-top: 10px">
                    <br />Client<br /><br /><br />
                    <input style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    By: <br /><br />
                    <input style="width: 280px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="" /><br />
                    Signature:
                </td>
            </tr>
        </table>`

        if(location.name !== 'Transblue Monroe') {
            html += `<small style="font-size: 0.5rem; font-family: 'Roboto'; margin-top: 30px">${location.llc}, <i>dba</i> ${location.name} is an independently owned and operated franchisee of Transblue Franchise Company, LLC.</small>`;
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/createfile`, {
            html: html,
            llc: location.llc,
            name: location.name
        }).then(res => {
            console.log(res)
            //const blob = new Blob([res.data.buff.data], {type: "application/pdf"})

            let buff = Buffer.from(res.data.buff);
             // create file object
            let file = new File([buff], 'test.pdf', {type: 'application/pdf'});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            var fileName = `${project.name || 'proposal'}.pdf`
            link.download = fileName;
            link.click();

            setProgressOpen(false);
            setProposalOpen(false);

            //console.log(window.URL.createObjectURL(blob))
        }).catch(err => {
            setSendError('Error generating PDF using Adobe PDF services')
        })
    };


    function editWorkareaTitle(e, workarea) {
        let arr = changeOrder.workareas.map(item => {
            if(workarea.date === item.date) {
                return {
                    ...workarea,
                    name: e.target.value
                }
            }
            return workarea
        });

        setChangeOrder({
            ...changeOrder,
            workareas: arr
        })
    };

    function deleteWorkarea(workarea) {
        let arr = changeOrder.workareas.filter(item => item.name !== workarea.name);
        setChangeOrder({
            ...changeOrder,
            workareas: arr
        });
    };


    function reorderHeaderTerms(params) {
        const { oldIndex, targetIndex } = params;

        return new Promise((resolve) => {
            setTimeout(() => {
                const rowsClone = [...changeOrder.headerTerms];
                const row = rowsClone.splice(oldIndex, 1)[0];
                rowsClone.splice(targetIndex, 0, row);
                setChangeOrder({
                    ...changeOrder,
                    headerTerms: rowsClone
                })
                resolve(rowsClone);
            }, Math.random() * 500 + 100); // simulate network latency
        });
    };

    function deleteHeaderTerms(term) {
        let arr = changeOrder.headerTerms.filter(item => term !== item);
        setChangeOrder({
            ...changeOrder,
            headerTerms: arr
        })
    }

    function reorderFooterTerms(params) {
        const { oldIndex, targetIndex } = params;

        return new Promise((resolve) => {
            setTimeout(() => {
                const rowsClone = [...changeOrder.footerTerms];
                const row = rowsClone.splice(oldIndex, 1)[0];
                rowsClone.splice(targetIndex, 0, row);
                setChangeOrder({
                    ...changeOrder,
                    footerTerms: rowsClone
                });
                resolve(rowsClone);
            }, Math.random() * 500 + 100); // simulate network latency
        });
    }

    function deleteFooterTerms(term) {
        let arr = changeOrder.footerTerms.filter(item => term !== item);
        setChangeOrder({
            ...changeOrder,
            footerTerms: arr
        })
    }

    function save() {
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            changeOrder: changeOrder
        }).then(res => {
            // setChangeOrder(obj)
            alert('Change order saved!')
        })
    }

    async function sendProposal(az_sign_token) {
        let est = changeOrder;
        console.log(est);
        setProgressOpen(true);

        let bidTotal = est.workareas?.reduce((accum, item) => accum + item.price, 0);
        console.log(bidTotal);

        let api_url = localStorage.getItem('api_url');

        // if(!api_url) {
        //     let arr = await db.auth.toArray();
        //     api_url = arr[0].api_url;
        // }

        setLoading(true);  
        let year = new Date().getFullYear();   
        let html = generatePreview(est);
            html += `<table style="width: 100%; border: 0px; font-family: 'Roboto'; font-size: .75rem">
            <tr>Dated this {{*day_es_:signer1}} day of {{*month_es_:signer1}}, <input style="border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="${year}" />.</tr>
            <tr>
                <td style="width: 50%; padding-top: 30px;">
                    ${location.llc}, <i>dba</i><br />
                    ${location.name === 'Transblue Monroe' ? 'Transblue' : location.name}<br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="{{*N_es_:signer2:fullname}}" /><br />
                    By: <br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="{{Sig_es_:signer2:signature}}" /><br />
                    Signature:
                </td>
                <td style="width: 50%; padding-top: 30px">
                    <br />Client<br /><br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="{{*N_es_:signer1:fullname}}" /><br />
                    By: <br /><br />
                    <input style="width: 300px; margin-bottom: 10px; border-top: 0px; border-left: 0px; border-right: 0px; padding: 5px;" value="{{Sig_es_:signer1:signature}}" /><br />
                    Signature:
                </td>
            </tr>
        </table>`

        if(location.name !== 'Transblue Monroe') {
            html += `<small style="font-size: 0.5rem; font-family: 'Roboto'; margin-top: 30px">${location.llc}, <i>dba</i> ${location.name} is an independently owned and operated franchisee of Transblue Franchise Company, LLC.</small>`
        }

        setPreviewWidth('1240px');  
        //setProposalHtml(html);
        setProposalOpen(true);

        setSendProgress('Generating PDF using Adobe PDF services');

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/createfile`, {
            html: html,
            llc: location.llc,
            name: location.name
        }).then(res => {
            console.log(res)
            let fileName = `${new Date().getTime()}.pdf`;

            let url = {
                url: `https://tbconnectstorage.blob.core.windows.net/documents/${fileName}`,
                name: fileName
            };

            let documents = project.documents;
            let arr = documents[1].url || [];
            documents[1] = {
                ...documents[1],
                url: [url, ...arr]
            };

            // create empty form-data
            const form = new FormData();
            let buff = Buffer.from(res.data.buff);

            // save pdf of archived estimate into database
            const blockBlobClient = containerClient.getBlockBlobClient(fileName);
            blockBlobClient.uploadBrowserData(buff);

            // create file object
            let file = new File([buff], 'test.pdf', {type: "application/pdf"});

            //append file to form
            form.append('File', file);

            setSendProgress('Sending PDF to client');

            fetch( `https://my-tb-cors.herokuapp.com/${api_url}api/rest/v5/transientDocuments`, {
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                    'Access-Token': az_sign_token,
                    //'Content-Type': 'multipart/form-data',
                }
            }).then(
                response => response.text()
            ).then(text => {
                console.log(text)
                let transientId = JSON.parse(text).transientDocumentId;

                let userEmail = localStorage.getItem('email');

                setSendProgress('Sending PDF to client');

                axios.post(`https://my-tb-cors.herokuapp.com/${api_url}api/rest/v5/agreements`,{
                    "documentCreationInfo": {
                        "fileInfos": [{
                            "transientDocumentId": transientId
                        }],
                        "name": `${project.name} Proposal`,
                        "recipientSetInfos": [
                            {
                                "recipientSetRole": "SIGNER",
                                "recipientSetMemberInfos": [{
                                    "email": project.contact.email
                                }]
                            },
                            {
                                "recipientSetMemberInfos": [{
                                    "email": userEmail
                                }],
                                "recipientSetRole": "SIGNER"
                            }
                        ],
                        "signatureType": "ESIGN",
                        "signatureFlow": "SENDER_SIGNATURE_NOT_REQUIRED",
                    }
                }, {
                    headers: {
                        'Access-Token': az_sign_token,
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    setSendProgress('Saving PDF to database');

                    let agreementId = res.data.agreementId;
                    axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=projects&id=${project.id}`, {
                        agreementId: agreementId,
                        documents: documents,
                        //bidTotal: bidTotal
                    }).catch(err => {
                        alert('Error saving PDF to database')
                    }).then(res => {
                        setLoading(false);
                        setProposalOpen(false);
                        setSendProgress('PDF sent to client!')
                        setSendError('');
                        setProgressOpen(false);
                    })
                }).catch(err => {
                    setSendError('Error sending PDF to client');
                })
            }).catch(err => {
                setSendError('Error sending PDF to client');
            })
        }).catch(err => {
            setSendError('Error generating PDF using Adobe PDF services')
        })
    }

    const columns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
              <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
        {
            field: 'name', 
            headerName: 'Name', 
            flex: 1
        },
        {
            field: 'delete', 
            headerName: ' ',
            renderCell: (params) => {
                return (
                    <Tooltip title='Delete terms'>
                        <IconButton
                            sx={{fontSize: '14px'}}
                            color='error'
                            onClick={() => {
                                deleteHeaderTerms(params.row)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )
            }
        }
    ];

    const footerColumns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
              <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
        {
            field: 'name', 
            headerName: 'Name', 
            flex: 1
        },
        {
            field: 'delete', 
            headerName: ' ',
            renderCell: (params) => {
                return (
                    <Tooltip title='Delete terms'>
                        <IconButton
                            sx={{fontSize: '14px'}}
                            color='error'
                            onClick={() => {
                                deleteFooterTerms(params.row)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )
            }
        }
    ];

    function addWorkarea() {
        workarea.id = uuidv4();
        workarea.date = new Date().getTime();

        setChangeOrder({
            ...changeOrder,
            workareas: [...changeOrder.workareas, workarea]
        })

        setWorkarea({
            name: '',
            date: '',
            materials: [],
            costcode: '41100 Asphalt / Parking Lot Construction',
            price: 0
        })
        setOpen(false);
    };

    
    function saveNewMaterialOrder(newMaterialOrder) {
        let workareaArr = changeOrder.workareas.map(area => {
            if(area.date === workarea.date) {
                return {
                    ...workarea,
                    materials: newMaterialOrder
                }
            }
            return area
        })

        let obj = {
            ...changeOrder,
            workareas: workareaArr
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=projects&id=${project.id}`, {
            changeOrder: obj
        }).then(res => {
            setChangeOrder(obj)
        })
    };

    return (
        <>
            <Modal
                open={progressOpen}
                onClose={() => setProgressOpen(false)}
            >   
                <Box sx={{...style, maxWidth: '400px', p: 3, textAlign: 'center'}}>
                    {!sendError && <Typography variant='h5' sx={{mb: 2}}>{sendProgress}</Typography>}
                    {!sendError && <CircularProgress />}
                    {sendError && <Typography sx={{color: 'red'}}>{sendError}</Typography>}
                </Box>
            </Modal>
            <Modal
                open={proposalOpen}
                onClose={() => setProposalOpen(false)}
            >
                <Box sx={{...style, maxWidth: '1240px', maxHeight: '90vh', overflowY: 'scroll'}}>
                    <div id='proposal' style={{padding: '80px 70px', width: previewWidth, maxWidth: '1240px'}} dangerouslySetInnerHTML={{__html: proposalHtml}} />
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', pb: 5}}>
                        <Button
                            onClick={() => setProposalOpen(false)}
                            variant='contained'
                            sx={{fontSize: '12px'}}
                            color='error'
                        >
                            cancel
                        </Button>
                        <Button
                            onClick={newDownload}
                            variant='contained'
                            sx={{fontSize: '12px', ml: 1}}
                        >
                            download
                        </Button>
                        <Box sx={{position: 'relative' }}>
                            <Button
                                onClick={loginUser}
                                variant='contained'
                                sx={{fontSize: '12px', bgcolor: '#ed6a22', ml: 1}}
                                disabled={loading || changeOrder === {}}
                            >
                                send proposal
                            </Button>
                            {loading &&
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: '#ed6a22',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            }
                        </Box>
                    </Box>
                </Box>

            </Modal>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={{...style, maxWidth: '800px'}}>
                    <Typography variant='h6' sx={{bgcolor: '#f5f5f5', p: 2}}>
                        <AddCircle /> ADD WORK AREA
                    </Typography>
                    <Box sx={{bgcolor: 'white'}}>
                        <Grid container p={3}>
                            <GridRow>
                                <>Work Area Name</>
                                <TextField
                                    fullWidth
                                    size='small'
                                    value={workarea.name}
                                    onChange={(e) => setWorkarea(workarea => ({
                                        ...workarea,
                                        name: e.target.value
                                    }))}
                                />
                            </GridRow>
                            <GridRow>
                                <>Cost Code</>
                                <Autocomplete
                                    fullWidth
                                    options={costcodes}
                                    value={workarea.costcode}
                                    onChange={(e, newValue) => setWorkarea({...workarea, costcode: newValue})}
                                    renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                                />
                            </GridRow>
                        </Grid>

                        <ModalButtons
                            handleClose={() => setOpen(false)}
                            submit={addWorkarea}
                        />
                    </Box>
                </Box>
            </Modal>
            <Modal
                onClose={() => setHeaderTermsOpen(false)}
                open={headerTermsOpen}
            >
                <Box sx={{...style, maxWidth: '500px', p:5, height: '90%', maxHeight: '700px', overflowY: 'auto'}}>
                    <Typography>Select Terms &amp; Conditions:</Typography>
                    <List dense>
                        {otherTerms.length > 0 &&
                            otherTerms.map((term, index) => {
                                return(
                                    <ListItem
                                        key={`${term.name}-${index}`}
                                        secondaryAction={
                                            <IconButton 
                                                edge='end' 
                                                aria-label='add'
                                                onClick={() => {
                                                    let arr = [...changeOrder.headerTerms, term];
                                                    
                                                    setChangeOrder({
                                                        ...changeOrder,
                                                        headerTerms: arr
                                                    })
                                                    setHeaderTermsOpen(false);
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={term.name}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Box>
            </Modal>
            <Modal
                onClose={() => setFooterTermsOpen(false)}
                open={footerTermsOpen}
            >
                <Box sx={{...style, maxWidth: '500px', p:5, height: '90%', maxHeight: '700px', overflowY: 'auto'}}>
                    <Typography>Select Terms &amp; Conditions:</Typography>
                    <List dense>
                        {otherTerms.length > 0 &&
                            otherTerms.map((term, index) => {
                                return(
                                    <ListItem
                                        key={`${term.name}-${index}`}
                                        secondaryAction={
                                            <IconButton 
                                                edge='end' 
                                                aria-label='add'
                                                onClick={() => {
                                                    let arr = [...changeOrder.footerTerms, term];

                                                    console.log(arr);
                                                    
                                                    setChangeOrder({
                                                        ...changeOrder,
                                                        footerTerms: arr
                                                    })
                                                    setFooterTermsOpen(false);
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={term.name}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Box>
            </Modal>

            <Box sx={{width: '100%', justifyContent: 'flex-end', display: 'flex', mb: 3}}>
                <ButtonGroup variant='text' aria-label='proposal actions button group'>
                    <Tooltip title='Download this change order as a word document'>
                        <Button
                            sx={{fontSize: '12px', mr: 1}}
                            disabled={Object.keys(changeOrder).length === 0}
                            onClick={Export2Word}
                            startIcon={<PostAdd />}
                        >
                            word doc
                        </Button>
                    </Tooltip> 
                    <Button
                        sx={{fontSize: '12px', mr: 1}}
                        disabled={Object.keys(changeOrder).length === 0}
                        onClick={save}
                        color='success'
                        startIcon={<Save />}
                    >
                        save
                    </Button>
                    <Button
                        sx={{fontSize: '12px', mr: 1}}
                        disabled={Object.keys(changeOrder).length === 0}
                        onClick={createRequest}
                        startIcon={<RemoveRedEye />}
                    >
                        preview
                    </Button> 
                    <Button
                        sx={{fontSize: '12px', mr: 1}}
                        disabled={Object.keys(changeOrder).length === 0}
                        onClick={createRequest}
                        startIcon={<Send />}
                    >
                        send proposal
                    </Button>
                </ButtonGroup> 
            </Box>

            <Box sx={{mb: 3}}>
                {/* <Alert severity='info' sx={{mb: 2}}>Updates: Press the <ExpandMore sx={{fontSize: '16px', verticalAlign: 'middle'}} /> button to view and edit body of terms and conditions. Terms will save automatically. Press the <DragIndicator sx={{fontSize: '14px', verticalAlign: 'middle'}} /> icon to reorder terms</Alert> */}
                <SectionHeader>
                    <Notes sx={{mr: 2}} />
                    <>HEADER TERMS &amp; CONDITIONS:</>
                </SectionHeader>
                <Box sx={{ border: 1, borderColor: 'divider' }}>
                    <Button
                        startIcon={<Add />}
                        sx={{my: 1, fontSize: '12px'}}
                        onClick={() => setHeaderTermsOpen(true)}
                    >
                        add terms
                    </Button>
                </Box>
                <DataGridPro
                    rowReordering
                    onRowOrderChange={(params) => reorderHeaderTerms(params)}
                    autoHeight
                    autoPageSize
                    columns={columns}
                    rows={changeOrder.headerTerms || []}
                    getRowId={(row) => row.name}
                    density='compact'
                    disableSelectionOnClick
                    getDetailPanelContent={({ row }) => {
                        return (
                            <>
                                <CKEditor
                                    config={{
                                        height: '300px',
                                        extraPlugins: 'colorbutton'
                                    }}
                                    data={row.body}
                                    onChange={(event) => {
                                        // loop through components terms
                                        let tempTerms = changeOrder.headerTerms?.map(term => {
                                            // find terms that user is changing by name
                                            // update value of terms
                                            if(term.name === row.name) {
                                                return(
                                                    {
                                                        ...term,
                                                        body: event.editor.getData()
                                                    }
                                                )
                                            }
                                            return term
                                        })

                                        setChangeOrder({
                                            ...changeOrder,
                                            headerTerms: tempTerms
                                        })
                                    }}
                                />
                            </>
                        )
                    }}
                    getDetailPanelHeight={() => 'auto'} 
                />
            </Box>
            <Box sx={{mb: 3}}>
                {/* <Alert severity='info' sx={{mb: 2}}>Updates: Press the <ExpandMore sx={{fontSize: '16px', verticalAlign: 'middle'}} /> button to view and edit body of terms and conditions. Terms will save automatically. Press the <DragIndicator sx={{fontSize: '14px', verticalAlign: 'middle'}} /> icon to reorder terms</Alert> */}
                <SectionHeader>
                    <Notes sx={{mr: 2}} />
                    <>FOOTER TERMS &amp; CONDITIONS:</>
                </SectionHeader>
                <Box sx={{ border: 1, borderColor: 'divider' }}>
                    <Button
                        startIcon={<Add />}
                        sx={{my: 1, fontSize: '12px'}}
                        onClick={() => setFooterTermsOpen(true)}
                    >
                        add terms
                    </Button>
                </Box>
                <DataGridPro
                    rowReordering
                    onRowOrderChange={(params) => reorderFooterTerms(params)}
                    autoHeight
                    autoPageSize
                    columns={footerColumns}
                    rows={changeOrder.footerTerms || []}
                    getRowId={(row) => row.name}
                    density='compact'
                    disableSelectionOnClick
                    getDetailPanelContent={({ row }) => {
                        return (
                            <>
                                <CKEditor
                                    config={{
                                        height: '300px',
                                        extraPlugins: 'colorbutton'
                                    }}
                                    data={row.body}
                                    onChange={(event) => {
                                        // loop through components terms
                                        let tempTerms = changeOrder.footerTerms?.map(term => {
                                            // find terms that user is changing by name
                                            // update value of terms
                                            if(term.name === row.name) {
                                                return(
                                                    {
                                                        ...term,
                                                        body: event.editor.getData()
                                                    }
                                                )
                                            }
                                            return term
                                        })

                                        setChangeOrder({
                                            ...changeOrder,
                                            footerTerms: tempTerms
                                        })
                                    }}
                                />
                            </>
                        )
                    }}
                    getDetailPanelHeight={() => 'auto'} 
                />
            </Box>

            <SectionHeader>
                <AttachMoney sx={{mr: 2}} />
                <>PRICING:</>
            </SectionHeader>
            {changeOrder.workareas.map(workarea => {
                return (
                    <Box sx={{mb: 2, width: '100%'}} key={workarea.name}>
                        <Grid container p={1} sx={{ bgcolor: '#f0f0f0', border: '1px solid #ddd'}}>
                            <Grid item xs={5} my='auto'>
                                <Box sx={{width: '100%', display: 'flex'}}>
                                    {editWorkareaTitleOn
                                        ?   <TextField
                                                value={workarea.name}
                                                size='small'
                                                onChange={(e) => editWorkareaTitle(e, workarea)}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: 'white'
                                                    }
                                                }}
                                                sx={{bgcolor: 'white'}}                
                                            />
                                        :   <Typography variant='body2' sx={{mt: 1, pl: 1, mr: 2}}>
                                                {workarea.name} 
                                            </Typography> 
                                    }
                                    <IconButton
                                        onClick={() => setEditWorkareaTitleOn(editWorkareaTitleOn => !editWorkareaTitleOn)}
                                        sx={{verticalAlign: 'middle'}}
                                    >
                                        {!editWorkareaTitleOn
                                            ?   <Tooltip title='Edit work area title'>
                                                    <Edit
                                                        sx={{fontSize: '20px', verticalAlign: 'middle'}}
                                                    />
                                                </Tooltip>
                                            :   <Tooltip title='Save work area title'>
                                                    <Check
                                                        color='success'
                                                        onClick={save}
                                                        sx={{fontSize: '20px', verticalAlign: 'middle'}}
                                                    />
                                                </Tooltip>
                                        }
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={4} my='auto'>
                                <Typography variant="body2">
                                    {workarea.costcode}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} my='auto'>
                                
                            </Grid>
                            <Grid item xs={1} my='auto'>
                                <Typography variant="body2">
                                    {new Intl.NumberFormat('en-US', options).format(workarea.materials?.filter(estMaterial => estMaterial.Name !== 'TOTALS').reduce((accum, item) => accum + item.totalCost, 0))}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}> 
                                <Tooltip title='Delete workarea'>
                                    <IconButton
                                        onClick={() => deleteWorkarea(workarea)}
                                    >
                                        <Delete color='error' sx={{fontSize: '20px'}} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                                                    
                        <ChangeOrderWorkarea
                            //saveEstimate={saveEstimate}
                            materialsArr={materialsArr}
                            workarea={{
                                ...workarea
                            }} 
                            options={options}
                            newMargin={location.margin || 40}
                            //index={index}
                            id={project.id}
                            //estimate={estimate}
                            saveNewMaterialOrder={saveNewMaterialOrder}
                            // localBid={{
                            //     ...localBid
                            // }}
                            //activeEstimate={activeEstimate}
                            //setLocalBid={setLocalBid}
                            setChangeOrder={setChangeOrder}
                            changeOrder={changeOrder}
                            project={project}
                            saveEstimate={save}
                        />
                    </Box>
                )
            })}

            <Grid container>
                <Grid item xs={6}>
                    <Typography variant='h6'>Total: {new Intl.NumberFormat('en-US', options).format(changeOrder.workareas.reduce((accum, item) => accum + item.price, 0))}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            sx={{fontSize: '10px', ml: 1}}
                            variant='contained'
                            onClick={() => setOpen(true)}
                        >
                            add workarea
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </>
    )

}

export default NewChangeOrder;