import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./openProject";
import { ImageList, ImageListItem, IconButton, ImageListItemBar, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";

function Images() {
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    let logEntries = project.logEntries || [];
    const [images, setImages] = useState([]);

    useEffect(() => {
        if(logEntries && logEntries.length > 0) {
            let arr = [];

            logEntries.forEach(entry => {
                if(entry.images) {
                    //console.log(entry.images)
                    arr = [...entry.images, ...arr]
                }
            })

            //console.log(arr)
            setImages(arr);
        }
    }, [])

    function removeImage(index) {
        let arr = images;
        arr.splice(index, 1);
        console.log(arr)
        setImages([...arr]);
    }

    return(
       images.length > 0 
            ?    <ImageList sx={{width: '100%'}} cols={4} rowHeight={164}>
                    {images.map((image, index) => (
                        <ImageListItem
                            key={image.name}
                        >
                            <img 
                                src={image.src || image} 
                                srcSet={image.src || image}
                                loading='lazy'
                            />
                            {/* <ImageListItemBar
                                sx={{
                                    background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white' }}
                                        onClick={() => removeImage(index, 'prev images')}
                                        >
                                        <Clear />
                                    </IconButton>
                                }
                                actionPosition="right"
                            /> */}
                        </ImageListItem>
                    ))}
                </ImageList>
            :   <Typography 
                    variant='h6'
                >
                    Oops! There are no project images uploaded yet
                </Typography>
    )
}

export default Images;