import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from './openProject';
import { CircularProgress, Paper, Box, Typography, Grid, Divider } from '@mui/material';

function ChecklistProgress() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [variables, setVariables] = useState({});

    let totalNum = 0;
    if(project.checklist) {
        totalNum = project.checklist.length * 5
    }

    useEffect(() => {
        console.log('project updated')
    }, [project])

    useEffect(() => {
        getPercent()
    }, [project])

    function getPercent() {
        let total = 0;

        if(project.checklist) {
            project.checklist.forEach(checklist => {
                let num = Object.values(checklist).filter(value => value === true).length;
                if(num) {
                    total += num
                };
            });
        }

        let percent = ((total / totalNum) * 100);

        if(totalNum === 0 && total === 0) {
            setVariables({
                color: '#1976d2',
                progress: 0,
                completed: 0,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }
    
        else if(percent < 50) {
            setVariables({
                color: '#d32f2f',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }

        else if(percent >= 50 && percent < 80) {
            setVariables({
                color: '#ed6c02',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }

        else {
            setVariables({
                color: '#2e7d32',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.',
                winning: true
            });
        }
    }

    return(
        <Paper sx={{width: '100%', textAlign: 'center', height: '100%', borderRadius: '0px', pb: 2}} elevation={0}>
            <Typography variant='body2' sx={{textAlign: 'left', p: 3, color: '#767676', letterSpacing: '.03em'}}>DAILY CHECKLIST</Typography>
            <Box sx={{ position: 'relative', width: '100%', height: '100%'}}>
                {Object.keys(variables).length > 0 &&
                    <>
                        <CircularProgress 
                            variant='determinate' 
                            value={variables.progress === 0 ? 100 : variables.progress}
                            size={150}
                            sx={{
                                color: variables.progress === 0 ? '#f0f0f0' : variables.color
                            }}
                        />
                    </>
                }
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        sx={{fontWeight: 'bold'}}
                    >
                        {`${Math.round(variables.progress)}%`}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{mt: 3}} />

            <Typography variant='body2' sx={{px: 3, pt: 3}}>
                <b>{variables.completed} / {totalNum}</b> checklist tasks completed so far
            </Typography>
            {variables.winning &&
                <Typography component="div" variant='body2'>
                    You are <Box display="inline" sx={{fontWeight:'bold', color:'green'}}> WINNING</Box> this project!! Keep it up!
                </Typography>
            }
        </Paper>
    )
}

export default ChecklistProgress;