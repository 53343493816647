import * as msal from "@azure/msal-browser";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";

function LoginLogic() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        if(window.innerWidth < 600) {
            window.location = 'https://mprojects.transblueconnect.com'
        }
    }, [])

    const msalConfig = {
        auth: {
            //clientId: '43ee8b1b-312a-4204-9598-138cb9e47163'
            clientId: process.env.REACT_APP_MSAL_CLIENTID
        },
    };
    
    const msalInstance = new msal.PublicClientApplication(msalConfig);

    async function msalLogin() {
        try {
            // need to get users role and which location they're logging in from
            const loginResponse = await msalInstance.loginPopup({}); 
            let token = loginResponse.accessToken; 
            localStorage.setItem('account', JSON.stringify(loginResponse.account));
            localStorage.setItem('access token', loginResponse.accessToken);

            // microsoft graphql call to get user info and officeLocation
            return axios.get(`https://graph.microsoft.com/v1.0/users/${loginResponse.account.username}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                let user = res.data;
                let location;

                // get location of user
                if(!user.officeLocation || user.officeLocation === 'Operations' || user.officeLocation === 'HQ') {
                        location = 'Monroe'
                }
                else {
                    location = user.officeLocation;
                }

                localStorage.setItem('location', location);

                // check if user is in contacts database
                return axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getbyid?containerId=contacts&id=${user.id}`)
                    .then(res => {
                        // create employee contact if not
                        if(res.data === 'No items found') {
                            // get list of franchises from database
                            axios.get('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=franchises')
                                .then(res => {
                                    // get location that user belongs to
                                    let locationObj = res.data.filter(loc => loc.territory === location)[0];
                                    localStorage.setItem('locationObj', JSON.stringify(locationObj));

                                    if(user.mail.toLowerCase() === locationObj.admin) {
                                        localStorage.setItem('franOwner', true)
                                    }

                                    axios.post('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/save?containerId=contacts', {
                                        name: user.displayName,
                                        id: user.id,
                                        email: user.mail.toLowerCase(),
                                        location: location,
                                        costguideId: locationObj.costguideId,
                                        locationId: locationObj.id,
                                        type: 'Employee',
                                        date: new Date().getTime(),
                                        active: true,
                                        franchiseSupport: false,
                                        // msaId: locationObj.msaId
                                    })

                                    localStorage.setItem('costguideId', locationObj.costguideId);
                                    localStorage.setItem('email', user.mail.toLowerCase());
                                    localStorage.setItem('user', user.displayName);
                                    localStorage.setItem('userId', user.id);
                                    localStorage.setItem('locationId', locationObj.id);
                                    //localStorage.setItem('msaId', locationObj.msaId);
                                    localStorage.setItem('franSupport', false);
                                })

                                return {
                                    data: 'new user',
                                    id: user.id
                                }
                        }

                        // otherwise update local storage with location information
                        else {
                            console.log(user)
                            let contact = res.data[0];

                            // check if users email matches admin email of their location or if user is franchise support
                            axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getbyid?containerId=franchises&id=${contact.locationId}`)
                                .then(res => {
                                    // if franchise support or location admin, set franchise owner to true
                                    if(contact.email.toLowerCase() === res.data[0].admin || contact.franchiseSupport) {
                                        localStorage.setItem('franOwner', true)
                                    }
                                })

                            localStorage.setItem('costguideId', contact.costguideId);
                            localStorage.setItem('email', contact.email);
                            localStorage.setItem('user', contact.name);
                            localStorage.setItem('userId', contact.id)
                            localStorage.setItem('locationId', contact.locationId);
                            localStorage.setItem('franSupport', contact.franchiseSupport);
                            //localStorage.setItem('msaId', contact.msaId);

                            return {
                                data: ''
                            }
                        }
                    })
            })
            
        } catch (err) {
            console.log(err)
            // handle error
            return {
                data: 'failed login'
            }
        }
    }

    async function login() {
        let loginResponse = await msalLogin();

        console.log(loginResponse)

        if(loginResponse.data === 'failed login') {
            alert('Incorrect login, please try again')
        }

        else {
            setOpen(true);
            if(loginResponse.data === 'new user') {
                setRedirectUrl(`/settings/${loginResponse.id}`)
            }
            else {
                setRedirectUrl('/projects');
            }

            localStorage.setItem('lastLoginTime', new Date().getTime())
        }
    }

    function redirect() {
        navigate(redirectUrl)
    }

    return {
        login,
        redirect,
        open
    }

}

export default LoginLogic;