import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./openProject";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { Check, Clear } from "@mui/icons-material";
import './style.css';
import {Typography} from '@mui/material';

let newList = {
    date: new Date().toLocaleDateString(),
    onSite: false,
    client: false,
    sub: false,
    pictures: false,
    dailyLog: false,
}

function CheckList() {
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const [checklist, setChecklist] = useState(project.checklist || []);
    const [loading, setLoading] = useState(true);
    const currentDate = new Date().toLocaleDateString();
    const [displayAlert, setDisplayAlert] = useState(false);

    const Cell = (props) => {
        const { date, params, index } = props;
        
    
        if(date === currentDate) {
            return <Checkbox checked={checklist[0][params.row.col]} onChange={() => save(params.row.col)} />
        }
    
        else {
            let text = params.row.values[index]
                ? <Check sx={{color: 'green'}} />
                : <Clear sx={{color: 'red'}} />
            return text
        }
    }

    function updateChecklist(list) {
        // update list in database
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            checklist: [...list]
        }).catch(err => {
            alert('Oops! There was an error updating the daily checklist. Please try again')
        }).then(res => {
            setChecklist([...list]);

            // why is this not updating checklist progress component?
            setProject({
                ...project,
                checklist: [...list]
            })
            //alert('A new checklist has been added for today!')
        })
    }

    useEffect(() => {
        let list = [];

        let start = new Date(project.salesToOps.projectDetails.startDate);
        start.setHours(0);
        let end = new Date(project.salesToOps.projectDetails.endDate);
        end.setHours(23);

        // create new empty checklist for current date if last checklist is not current
        if(checklist.length > 0 && checklist[0].date !== currentDate && new Date().getTime() < end.getTime()) {
            list = [newList, ...checklist];
            updateChecklist(list);
        }

        // create checklist if project has started and there is no checklist yet
        else if(checklist.length === 0 && new Date().getTime() >= start.getTime() && new Date().getTime() <= end.getTime()) {
            updateChecklist([newList])
        }

        else if(new Date().getTime() < start.getTime()) {
            setDisplayAlert(true);
        }

        // set loading to false
        setLoading(false);
    }, [])

    function save(field) {
        // update list locally
        let list = [...checklist];
        list[0] = {
            ...list[0],
            [field]: !list[0][field]
        }
        setChecklist(list);

        setProject({
            ...project,
            checklist: list
        })

        updateChecklist(list);
    }

    const cols = [{
        field: 'title',
        headerName: 'Task',
        flex: 1
    }, ...checklist.map((list, index) => {
        console.log(list)

        return {
            field: list.date, 
            headerName: list.date,
            flex: .5,
            renderCell: (params) => <Cell params={params} date={list.date} index={index} />
        }
    })]

    const newRows = [
        {
            title: '30 Mins On Site',
            col: 'onSite',
            values: checklist.map(list => list.onSite),
            id: '123456789101112'
        },
        {
            title: 'Talked to Client',
            col: 'client',
            values: checklist.map(list => list.client),
            id: '223456789101112'
        },
        {
            title: 'Talked to Sub',
            col: 'sub',
            values: checklist.map(list => list.sub),
            id: '323456789101112'
        },
        {
            title: 'Took Pictures',
            col: 'pictures',
            values: checklist.map(list => list.pictures),
            id: '423456789101112'
        },
        {
            title: 'Daily Log',
            col: 'dailyLog',
            values: checklist.map(list => list.dailyLog),
            id: '523456789101112'
        },
    ]

    return(
        <>
            {!displayAlert && 
                <DataGridPro
                    sx={{bgcolor: 'white', marginTop: '20px'}}
                    columns={cols}
                    rows={newRows}
                    loading={loading}
                    disableSelectionOnClick
                    density='compact'
                    autoHeight
                    autoPageSize
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnMenu
                />
            }
            {displayAlert &&
                <Typography variant='body2'>
                    Oops! Your project hasn't started yet, your checklist will be available on {new Date(project.salesToOps.projectDetails.startDate).toLocaleDateString()}
                </Typography>
            }
        </>
    )
}

export default CheckList;