import { useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { CalendarMonth, Dashboard, Description, NoteAdd, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function PageLayoutLogic() {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    // const franchiseSupport = localStorage.getItem('franSupport');
    // const franOwner = localStorage.getItem('franOwner');

    useEffect(() => {
        if(!userId) {
            navigate('/')
        }
    }, []);

    useEffect(() => {
        if(window.innerWidth < 600) {
            window.location = 'https://mprojects.transblueconnect.com'
        }
    }, [])
 
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    let navLinks = [
        {
            text: 'Dashboard',
            href: '/dashboard',
            icon: <Dashboard />
        },
        {
            text: 'Projects',
            href: '/projects',
            icon: <Description />
        },{
            text: 'Calendar',
            href: '/calendar',
            icon: <CalendarMonth />
        },{
            text: 'Templates',
            href: '/templates',
            icon: <NoteAdd />
        },
        // {
        //     text: 'Subcontractors',
        //     href: '/subcontractors',
        //     icon: <Person />
        // }
        // {
        //     text: 'Reports',
        //     href: '/reports',
        //     icon: <ShowChartIcon />
        // }
    ]

    function logout() {
        localStorage.removeItem('msa');
        localStorage.removeItem('access token');
        localStorage.removeItem('user');
        localStorage.removeItem('email');
        navigate('/')
    }

    return {
        DrawerHeader,
        navLinks,
        logout
    }
}

export default PageLayoutLogic;